import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Backdrop } from './Modal.styled';
import { motion } from 'framer-motion';

const modalRoot = document.querySelector('#modal-root');

const Modal = ({ children, toggle }) => {
  useEffect(() => {
    const onEscPush = e => {
      if (e.code === 'Escape') {
        toggle();
      }
    };

    window.addEventListener('keydown', onEscPush);
    document.querySelector('html').style.overflowY = 'hidden';
    document.querySelector('body').style.overflowY = 'hidden';

    return () => {
      window.removeEventListener('keydown', onEscPush);
      document.querySelector('html').style.overflowY = 'auto';
      document.querySelector('body').style.overflowY = 'auto';
    };
  }, [toggle]);

  const handleBackdropClick = e => {
    if (e.target === e.currentTarget) {
      toggle();
    }
  };

  return createPortal(
    <Backdrop
      onClick={handleBackdropClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.25 }}
    >
      <motion.div
        initial={{ scale: 0.6 }}
        animate={{ scale: 1 }}
        exit={{ scale: 1.3 }}
        transition={{ duration: 0.25 }}
      >
        {children}
      </motion.div>
    </Backdrop>,
    modalRoot
  );
};

export default Modal;
