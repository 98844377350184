const hashes = state => state.hashesMerchant.hashes;
const total_pages = state => state.hashesMerchant.total_pages;
const isLoading = state => state.hashesMerchant.isLoading;
const error = state => state.hashesMerchant.error;

const select = {
  hashes,
  total_pages,
  isLoading,
  error,
};

export default select;
