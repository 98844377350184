import styled from '@emotion/styled';

export const StyledWrp = styled.div`
  width: 569px;
  padding: 20px 34px;

  background: var(--background-merchant-payout-form-color);
  border-radius: 18px;
`;

export const StyledTopWrp = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const StyledCrossBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
`;

export const StyledPaymentInfoWrp = styled.div`
  margin-bottom: 20px;
  padding: 20px;

  background: var(--background-merchant-payout-from-area-color);
  border-radius: 18px;
`;

export const StyledLogoWrp = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledValueWrp = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 28px;
`;

export const StyledCopyBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
`;

export const StyledTextWrp = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};
`;

export const StyledAddressInfoWrp = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 30px 20px;

  background: var(--background-merchant-payout-from-area-color);
  border-radius: 18px;
`;

export const StyledText = styled.p`
  margin-right: ${({ mg_r }) => (mg_r ? `${mg_r}px` : '')};
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};

  font-size: ${({ fz }) => (fz ? `${fz}px` : '16px')};
  font-weight: ${({ fw }) => (fw ? fw : 500)};
  line-height: 120%;

  color: ${({ c }) => {
    if (c === 'secondary') {
      return 'var(--text-secondary-color)';
    }

    if (c === 'green') {
      return '#63DA6F';
    }

    return 'var(--text-primary-color)';
  }};
`;

export const StyledSpan = styled.span`
  font-family: Roboto, sans-serif;
`;
