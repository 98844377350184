import { useSelector } from 'react-redux';
import select from '../../redux/marketplace/admin/selectors';

const useAdmin = () => {
  const total_user_count = useSelector(select.total_user_count);
  const user_count_per_time = useSelector(select.user_count_per_time);
  const total_deposit = useSelector(select.total_deposit);
  const withdraw_amount_per_time = useSelector(select.withdraw_amount_per_time);
  const order_count_per_time = useSelector(select.order_count_per_time);
  const order_amount_per_time = useSelector(select.order_amount_per_time);
  const deal_count_per_time = useSelector(select.deal_count_per_time);
  const deal_amount_per_time = useSelector(select.deal_amount_per_time);
  const operators = useSelector(select.operators);
  const operators_pages = useSelector(select.operators_pages);
  const exchangers = useSelector(select.exchangers);
  const exchangers_pages = useSelector(select.exchangers_pages);
  const transactions = useSelector(select.transactions);
  const transactions_pages = useSelector(select.transactions_pages);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    total_user_count,
    user_count_per_time,
    total_deposit,
    withdraw_amount_per_time,
    order_count_per_time,
    order_amount_per_time,
    deal_count_per_time,
    deal_amount_per_time,
    operators,
    operators_pages,
    exchangers,
    exchangers_pages,
    transactions,
    transactions_pages,
    isLoading,
    error,
  };
};

export default useAdmin;
