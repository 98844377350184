import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const fetchRequests = createAsyncThunk(
  'operatorMarketplace/fetchRequests',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/operators/requests');

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = { fetchRequests };

export default API;
