import { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useProfileMerchant } from 'hooks/merchant';
import { ColorRing } from 'react-loader-spinner';
import { IoIosInformationCircle } from 'react-icons/io';
// import { MdCancel } from 'react-icons/md';
import { default as API_TRANSACTIONS_MERCHANT } from '../../../redux/merchant/transactions/operations';
import ACTIONS from '../../../redux/merchant/transactions/actions';
import { GiReceiveMoney } from 'react-icons/gi';
import { copyToClipboard, msToTime } from 'utils';
import { Timer } from 'components/merchant';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledItem,
  StyledWrp,
  StyledAddressWrp,
  StyledCopyBtn,
  StyledGroupWrp,
  StyledBtn,
  StyledInfoBtn,
  StyledDropdownWrp,
  StyledText,
  StyledSpan,
} from './ProfileTransactionListItem.styled';

const ProfileTransactionListItem = forwardRef(({ data }, ref) => {
  const {
    id,
    to_currency,
    from_currency,
    amount,
    amount_in_to_currency,
    trader_address,
    // status,
    was_paid,
    is_finished,
    created_at,
    payment_deadline,
  } = data;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = e => {
    setIsDropdownOpen(currentState => !currentState);
  };

  const createdAtLocalTime = new Date(created_at + 'Z');

  const formattedCreatedAtLocalTime = format(
    createdAtLocalTime,
    'dd.LL.yyyy HH:mm'
  );

  const finishedLocalTime = new Date(payment_deadline + 'Z');

  const formattedFinishedLocalTime = format(
    finishedLocalTime,
    'dd.LL.yyyy HH:mm'
  );

  const finishedLocalTimeMs = format(finishedLocalTime, 'T');

  let remainedTimeMs = finishedLocalTimeMs - Date.now();

  remainedTimeMs = remainedTimeMs <= 0 ? null : remainedTimeMs;

  let remainedTime = msToTime(remainedTimeMs);

  const { role } = useProfileMerchant();

  const handleConfirmButtonClick = id => {
    dispatch(API_TRANSACTIONS_MERCHANT.confirmTransaction(id));
  };

  const handleTimerOver = id => {
    dispatch(ACTIONS.timerFinish(id));
  };

  return (
    <>
      <StyledItem ref={ref}>
        <StyledText mw_m="60">
          <StyledSpan>{id}</StyledSpan>
        </StyledText>

        <StyledAddressWrp d="hidden">
          <StyledText mg_r="4">
            <StyledSpan>{trader_address.slice(0, 26)}...</StyledSpan>
          </StyledText>

          <StyledCopyBtn
            type="buttons"
            onClick={() => copyToClipboard(trader_address)}
          >
            <Svg w_m="16" h_m="16">
              <use href={`${sprite}#copy`}></use>
            </Svg>

            <p className="hidden">copy</p>
          </StyledCopyBtn>
        </StyledAddressWrp>

        <StyledGroupWrp>
          <StyledText tr="uppercase">
            {from_currency} <StyledSpan fz="10">{amount}</StyledSpan>
          </StyledText>

          <Svg w_m="16" h_m="16">
            <use href={`${sprite}#arrow`}></use>
          </Svg>

          <StyledText tr="uppercase">
            {to_currency}{' '}
            <StyledSpan fz="10">{amount_in_to_currency.toFixed(5)}</StyledSpan>
          </StyledText>
        </StyledGroupWrp>

        <StyledInfoBtn type="button" onClick={handleDropdownToggle}>
          <IoIosInformationCircle size={'30'} />
        </StyledInfoBtn>

        <StyledWrp>
          {!was_paid && (
            <div>
              <GiReceiveMoney size={'30'} color="red" />

              {remainedTimeMs && (
                <Timer
                  style={{
                    fontSize: '14px',
                    color: 'var(--text-primary-color)',
                  }}
                  minutes={remainedTime.mins}
                  seconds={remainedTime.secs}
                  handleTimerOver={() => {
                    handleTimerOver(id);
                  }}
                />
              )}
            </div>
          )}

          {role === 'trader' && was_paid && !is_finished && (
            <StyledBtn onClick={() => handleConfirmButtonClick(id)}>
              {t('transactionConfirmBtn')}
            </StyledBtn>
          )}

          {role === 'user' && was_paid && !is_finished && (
            <ColorRing
              height="30"
              width="30"
              ariaLabel="color-ring-loading"
              wrapperClass="color-ring-wrapper"
              colors={['orange', 'orange', 'orange', 'orange', 'orange']}
            />
          )}

          {was_paid && is_finished && (
            <Svg w_m="18" h_m="18">
              <use href={`${sprite}#completed`}></use>
            </Svg>
          )}

          {/* {<MdCancel size={'30'} color="red" />} */}
        </StyledWrp>

        {isDropdownOpen && (
          <StyledDropdownWrp onClick={handleDropdownToggle}>
            <StyledText>
              Created time:{' '}
              <StyledSpan>{formattedCreatedAtLocalTime}</StyledSpan>
            </StyledText>

            {is_finished && (
              <StyledText>
                Finished time:{' '}
                <StyledSpan>{formattedFinishedLocalTime}</StyledSpan>
              </StyledText>
            )}
          </StyledDropdownWrp>
        )}
      </StyledItem>
    </>
  );
});

export default motion(ProfileTransactionListItem);
