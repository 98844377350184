import styled from '@emotion/styled';
import { Field, Form } from 'formik';
import { mq } from 'utils';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 26px 18px;

  background: var(--background-form-color);
  border-radius: 21px;

  ${mq.tablet} {
    width: 514px;
    padding: 26px 28px;
  }
`;

export const StyledTopWrp = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const StyledTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;

  color: var(--text-primary-color);
`;

export const StyledCrossBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  background: transparent;
  border: none;
`;

export const StyledFieldWrp = styled.div`
  position: relative;

  margin-bottom: 20px;
`;

export const StyledField = styled(Field)`
  width: 100%;
  padding: 20px 20px 20px 60px;

  font-family: Roboto, sans-serif;
  font-weight: 600;

  color: var(--text-primary-color);
  background: var(--background-input-color);
  border: 1px solid transparent;
  border-radius: 99px;
  outline: none;

  transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  ${mq.tablet} {
    padding: 20px 20px 20px 64px;
  }

  &:focus-within {
    border-color: var(--text-primary-color);
  }

  &::placeholder {
    font-family: Raleway, sans-serif;
    font-weight: 600;

    color: var(--text-secondary-color);
  }
`;

export const ShowHidePasswordBtn = styled.button`
  position: absolute;

  width: 24px;
  height: 24px;

  background: transparent;
  border: none;

  top: 30%;

  right: 5%;
`;

export const ShowHideIconStyles = {
  width: '24px',
  height: '24px',

  color: 'var(--svg-secondary-color)',
};

export const StyledSubmitBtn = styled.button`
  padding: 16px 0;

  font-size: 16px;
  font-weight: 600;

  color: var(--button-primary-text-color);
  background: var(--button-primary-color);
  border: none;
  border-radius: 99px;

  ${mq.tablet} {
    padding: 18px 0;
  }
`;
