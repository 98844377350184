import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const StyledNav = styled.nav`
  margin-bottom: 50px;
`;

export const StyledList = styled.ul`
  display: flex;
`;

export const StyledItem = styled.li`
  padding: 10px 0;

  &:not(:last-child) {
    margin-right: 60px;
  }
`;

export const StyledLink = styled(NavLink)`
  padding: 10px 0;

  font-size: 16px;
  font-weight: 600;
  line-height: 120%;

  color: var(--text-primary-color);
  border: none;
  border-radius: 99px;

  &.active {
    padding: 10px 40px;

    color: var(--button-primary-text-color);
    background: var(--button-primary-color);
  }
`;
