const role = state => state.userMerchant.role;
const isLoadingRole = state => state.userMerchant.isLoadingRole;
const username = state => state.userMerchant.username;
const balance = state => state.userMerchant.balance;
const statistics = state => state.userMerchant.statistics;
const is_trader_online = state => state.userMerchant.is_trader_online;
const wallet_addresses = state => state.userMerchant.wallet_addresses;
const isLoading = state => state.userMerchant.isLoading;
const error = state => state.userMerchant.error;

const select = {
  role,
  isLoadingRole,
  username,
  balance,
  statistics,
  is_trader_online,
  wallet_addresses,
  isLoading,
  error,
};

export default select;
