import { useProfileMerchant } from 'hooks/merchant';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledFormTopWrp,
  StyledCrossBtn,
  StyledList,
  StyledItem,
  StyledIconWrp,
  StyledSpan,
  StyledText,
} from './ProfileVolumeForm.styled';

const ProfileVolumeForm = ({ toggle }) => {
  const { statistics } = useProfileMerchant();

  return (
    <StyledWrp>
      <StyledFormTopWrp mg_b="20">
        <StyledText fz="20" fw="600">
          Turnover
        </StyledText>

        <StyledCrossBtn type="button" onClick={toggle}>
          <Svg w_m="18" h_m="18" c="secondary">
            <use href={`${sprite}#cross`}></use>
          </Svg>

          <p className="hidden">cross</p>
        </StyledCrossBtn>
      </StyledFormTopWrp>

      <StyledList>
        {statistics?.turnover?.map((el, index) => (
          <StyledItem key={index}>
            <StyledIconWrp>
              <Svg w_m="20" h_m="20" mg_r_m="8">
                <use href={`${sprite}#network-${el.name}-logo-coin`}></use>
              </Svg>

              <StyledText tr="uppercase">{el.name}</StyledText>
            </StyledIconWrp>

            <StyledText>
              <StyledSpan>{el.value}</StyledSpan>
            </StyledText>
          </StyledItem>
        ))}
      </StyledList>
    </StyledWrp>
  );
};

export default ProfileVolumeForm;
