import { useDispatch } from 'react-redux';
import { default as API_ADMIN_MERCHANT } from '../../../redux/merchant/admin/operations';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledSelectField,
  StyledList,
  StyledItem,
  StyledText,
} from './AdminTimeRangeSelector.styled';

const AdminTimeRangeSelector = ({
  toggle,
  type,
  timeRange,
  setSelectedUserCountTimeRange,
  setSelectedTransactionCountTimeRange,
  setSelectedTransactionTurnoverTimeRange,
  setSelectedTransactionCurrencyCountTimeRange,
}) => {
  const dispatch = useDispatch();

  const handleTimeRangeSet = el => {
    if (type === 'user_count') {
      setSelectedUserCountTimeRange(el);
      dispatch(API_ADMIN_MERCHANT.fetchUserCount(el.value));
      toggle();
    }

    if (type === 'transaction_count') {
      setSelectedTransactionCountTimeRange(el);
      dispatch(API_ADMIN_MERCHANT.fetchTransactionCount(el.value));
      toggle();
    }

    if (type === 'transaction_turnover') {
      setSelectedTransactionTurnoverTimeRange(el);
      dispatch(API_ADMIN_MERCHANT.fetchTransactionTurnover(el.value));
      toggle();
    }

    if (type === 'transaction_currency_count') {
      setSelectedTransactionCurrencyCountTimeRange(el);
      dispatch(API_ADMIN_MERCHANT.fetchTransactionCurrencyCount(el.value));
      toggle();
    }
  };

  return (
    <StyledWrp>
      <StyledSelectField>
        <StyledText>Выбрать период</StyledText>

        <Svg
          w_m="24"
          h_m="24"
          c="primary"
          style={{ transform: 'rotate(180deg)' }}
        >
          <use href={`${sprite}#marketplace-tick`}></use>
        </Svg>
      </StyledSelectField>

      <StyledList>
        {timeRange.map(el => (
          <StyledItem key={el.id} onClick={() => handleTimeRangeSet(el)}>
            <StyledText>{el.name}</StyledText>
          </StyledItem>
        ))}
      </StyledList>
    </StyledWrp>
  );
};

export default AdminTimeRangeSelector;
