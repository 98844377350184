import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { useUser } from 'hooks/merchant';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledTitle,
  StyledList,
  StyledItem,
  StyledNavLink,
  StyledSelect,
  StyledTickBtn,
  StyledDropdownList,
  StyledDropdownItem,
  StyledSubNavLink,
} from './ProfileNav.styled';

const ProfileNav = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const { trader } = useUser();
  const { t } = useTranslation();

  const handleDropdownToggle = () => {
    if (!isDropdownOpen) {
      navigate('/exchanger/profile/usdt_wallets');
    }

    setIsDropdownOpen(currentState => !currentState);
  };

  useEffect(() => {
    if (
      pathname.includes('/exchanger/profile/bank_cards') ||
      pathname.includes('/exchanger/profile/usdt_wallet')
    ) {
      setIsDropdownOpen(true);
    }
  }, [pathname]);

  return (
    <StyledWrp>
      <StyledTitle>{t('profileNav.title')}</StyledTitle>

      <nav>
        <StyledList>
          <StyledItem>
            <StyledNavLink to="account">
              <Svg w_m="15.5" h_m="20.5" mg_r_m="8" mg_r_t="12" c="secondary">
                <use href={`${sprite}#login`}></use>
              </Svg>
              {t('profileNav.account')}
            </StyledNavLink>
          </StyledItem>

          <>
            <StyledItem>
              <StyledSelect
                onClick={handleDropdownToggle}
                style={{
                  background:
                    pathname.includes('usdt_wallets') ||
                    pathname.includes('bank_cards')
                      ? 'var(--background-profile-nav-active-color)'
                      : 'transparent',
                }}
              >
                <Svg w_m="21.5" h_m="21.5" mg_r_m="8" mg_r_t="12" c="secondary">
                  <use href={`${sprite}#wallet`}></use>
                </Svg>
                {t('profileNav.wallets')}

                <StyledTickBtn
                  type="button"
                  style={{
                    transform: isDropdownOpen
                      ? 'rotate(-180deg)'
                      : 'rotate(0deg)',
                    transition: 'transform 250ms ',
                  }}
                >
                  <Svg w_m="10" h_m="10" c="secondary">
                    <use href={`${sprite}#tick`}></use>
                  </Svg>
                  <p className="hidden">tick</p>
                </StyledTickBtn>
              </StyledSelect>
            </StyledItem>

            {isDropdownOpen && (
              <StyledDropdownList>
                <StyledDropdownItem>
                  <StyledSubNavLink to="usdt_wallets">
                    {t('profileNav.usdtWallets')}
                  </StyledSubNavLink>
                </StyledDropdownItem>

                {/* {trader && (
                  <StyledDropdownItem>
                    <StyledSubNavLink to="bank_cards">
                      {t('profileNav.cardsAndLimits')}
                    </StyledSubNavLink>
                  </StyledDropdownItem>
                )} */}
              </StyledDropdownList>
            )}
          </>

          {/* <StyledItem>
            <StyledNavLink to="notifications">
              <Svg w_m="21.5" h_m="20.5" mg_r_m="8" mg_r_t="12" c="secondary">
                <use href={`${sprite}#notification`}></use>
              </Svg>
              {t('profileNav.notifications')}
            </StyledNavLink>
          </StyledItem> */}

          <StyledItem>
            <StyledNavLink to="transactions">
              <Svg w_m="21.5" h_m="20.5" mg_r_m="8" mg_r_t="12" c="secondary">
                <use href={`${sprite}#transaction`}></use>
              </Svg>
              {t('profileNav.transactions')}
            </StyledNavLink>
          </StyledItem>

          <StyledItem>
            <StyledNavLink to="hashes">
              <Svg w_m="21.5" h_m="20.5" mg_r_m="8" mg_r_t="12" c="secondary">
                <use href={`${sprite}#percentage`}></use>
              </Svg>
              Hashes
            </StyledNavLink>
          </StyledItem>

          <StyledItem>
            <StyledNavLink to="withdraws">
              <Svg w_m="21.5" h_m="20.5" mg_r_m="8" mg_r_t="12" c="secondary">
                <use href={`${sprite}#dollar`}></use>
              </Svg>
              Withdraws
            </StyledNavLink>
          </StyledItem>
        </StyledList>
      </nav>
    </StyledWrp>
  );
};

export default ProfileNav;
