import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { Svg, Modal, AppForm } from 'components/common';
import { RegisterForm, LoginForm } from 'components/common/AuthForms';
import sprite from 'assets/images/sprite.svg';
import {
  StyledList,
  StyledItem,
  StyledActiveBtn,
  StyledBtn,
  StyledText,
} from './Auth.styled';

const Auth = () => {
  const { t } = useTranslation();
  const [isProductFormOpen, setIsProductFormOpen] = useState(false);
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);
  const [type, setType] = useState('');

  const handleToggleProductForm = () => {
    setIsProductFormOpen(currentState => !currentState);
  };

  const handleToggleRegisterForm = () => {
    setIsRegisterFormOpen(currentState => !currentState);
  };

  const handleToggleLoginForm = () => {
    setIsLoginFormOpen(currentState => !currentState);
  };

  return (
    <>
      <StyledList>
        <StyledItem>
          <StyledActiveBtn
            type="button"
            onClick={() => {
              setType('signup');
              handleToggleProductForm();
            }}
          >
            <Svg w_m="21.5" h_m="20.5" mg_r_t="6.25">
              <use href={`${sprite}#header-auth-signup`}></use>
            </Svg>

            <StyledText>{t('header.auth.signup')}</StyledText>
          </StyledActiveBtn>
        </StyledItem>

        <StyledItem>
          <StyledBtn
            type="button"
            onClick={() => {
              setType('signin');
              handleToggleProductForm();
            }}
          >
            <Svg w_m="19.5" h_m="19.5" mg_r_t="7.25" c="primary">
              <use href={`${sprite}#header-auth-signin`}></use>
            </Svg>

            <StyledText>{t('header.auth.signin')}</StyledText>
          </StyledBtn>
        </StyledItem>
      </StyledList>

      <AnimatePresence initial={false} onExitComplete={() => null}>
        {isProductFormOpen && (
          <Modal toggle={handleToggleProductForm}>
            <AppForm
              type={type}
              toggle={handleToggleProductForm}
              handleToggleLoginForm={handleToggleLoginForm}
              handleToggleRegisterForm={handleToggleRegisterForm}
            />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} onExitComplete={() => null}>
        {isLoginFormOpen && (
          <Modal toggle={handleToggleLoginForm}>
            <LoginForm toggle={handleToggleLoginForm} />
          </Modal>
        )}

        {isRegisterFormOpen && (
          <Modal toggle={handleToggleRegisterForm}>
            <RegisterForm toggle={handleToggleRegisterForm} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default Auth;
