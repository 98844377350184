import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { AdminTraderDeleteModal } from 'components/merchant/AdminTraderForms';
import { Svg, Modal } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledText,
  StyledSpan,
  StyledWrp,
  StyledUsernameWrp,
  StyledIconWrp,
  StyledBtnGroup,
  StyledBtn,
} from './AdminTraderListItem.styled';

const AdminTraderListItem = ({ data }) => {
  const { username, trader_id, is_online } = data;

  const [isTraderDeleteModalOpen, setIsTraderDeleteModalOpen] = useState(false);

  const handleTraderDeleteModalToggle = () => {
    setIsTraderDeleteModalOpen(currentState => !currentState);
  };

  return (
    <>
      <StyledWrp>
        <StyledUsernameWrp>
          <StyledIconWrp>
            <Svg w_m="21.5" h_m="21.5" c="secondary">
              <use href={`${sprite}#login`}></use>
            </Svg>
          </StyledIconWrp>

          <StyledText fz="16" m_w="150">
            {username}
          </StyledText>
        </StyledUsernameWrp>

        <StyledText fz="16" m_w="100">
          {trader_id}
        </StyledText>

        {is_online ? (
          <StyledText fz="16" m_w="150">
            online
          </StyledText>
        ) : (
          <StyledText fz="16" m_w="150">
            offline
          </StyledText>
        )}

        <StyledBtnGroup>
          <StyledBtn
            type="button"
            c="red"
            onClick={handleTraderDeleteModalToggle}
          >
            Удалить
          </StyledBtn>
        </StyledBtnGroup>
      </StyledWrp>

      <AnimatePresence onExitComplete={() => null} initial={false}>
        {isTraderDeleteModalOpen && (
          <Modal toggle={handleTraderDeleteModalToggle}>
            <AdminTraderDeleteModal
              toggle={handleTraderDeleteModalToggle}
              trader_id={trader_id}
            />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default AdminTraderListItem;
