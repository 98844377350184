import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const adminSlice = createSlice({
  name: 'adminSlice',

  initialState: {
    total_user_count: null,
    user_count_per_time: null,
    total_deposit: null,
    withdraw_amount_per_time: null,
    order_count_per_time: null,
    order_amount_per_time: null,
    deal_count_per_time: null,
    deal_amount_per_time: null,
    operators: [],
    operators_pages: null,
    exchangers: [],
    exchangers_pages: null,
    transactions: [],
    transactions_pages: null,
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.total_user_count = null;
      state.user_count_per_time = null;
      state.total_deposit = null;
      state.withdraw_amount_per_time = null;
      state.order_count_per_time = null;
      state.order_amount_per_time = null;
      state.deal_count_per_time = null;
      state.deal_amount_per_time = null;
      state.operators = [];
      state.operators_pages = null;
      state.exchangers = [];
      state.exchangers_pages = null;
      state.transactions = [];
      state.transactions_pages = null;
    });

    builder.addCase(API.fetchTotalUsers.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchTotalUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.total_user_count = action.payload;
    });

    builder.addCase(API.fetchTotalUsers.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchUsersCountPerTime.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchUsersCountPerTime.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user_count_per_time = action.payload;
    });

    builder.addCase(API.fetchUsersCountPerTime.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchDepositsAmountPerTime.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(
      API.fetchDepositsAmountPerTime.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.total_deposit = action.payload;
      }
    );

    builder.addCase(API.fetchDepositsAmountPerTime.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchWithdrawalsAmountPerTime.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(
      API.fetchWithdrawalsAmountPerTime.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.withdraw_amount_per_time = action.payload;
      }
    );

    builder.addCase(API.fetchWithdrawalsAmountPerTime.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchOrderCountAndAmoutPerTime.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(
      API.fetchOrderCountAndAmoutPerTime.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.order_count_per_time = action.payload.order_count;
        state.order_amount_per_time = action.payload.total_amount;
      }
    );

    builder.addCase(API.fetchOrderCountAndAmoutPerTime.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchDealCountAndAmoutPerTime.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(
      API.fetchDealCountAndAmoutPerTime.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.deal_count_per_time = action.payload.deal_count;
        state.deal_amount_per_time = action.payload.finished_amount;
      }
    );

    builder.addCase(API.fetchDealCountAndAmoutPerTime.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchOperators.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchOperators.fulfilled, (state, action) => {
      state.isLoading = false;
      state.operators = action.payload.operators;
      state.operators_pages = action.payload.total_pages;
    });

    builder.addCase(API.fetchOperators.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.createOperator.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.createOperator.fulfilled, (state, action) => {
      state.isLoading = false;
      state.operators.push(action.payload);
    });

    builder.addCase(API.createOperator.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.removeOperator.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.removeOperator.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.operators.findIndex(el => el.id === action.payload);

      state.operators.splice(index, 1);
    });

    builder.addCase(API.removeOperator.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchExchangers.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchExchangers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exchangers = action.payload.exchanges;
      state.exchangers_pages = action.payload.total_pages;
    });

    builder.addCase(API.fetchExchangers.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.createExchanger.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.createExchanger.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exchangers.push(action.payload);
    });

    builder.addCase(API.createExchanger.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.removeExchanger.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.removeExchanger.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.exchangers.findIndex(el => el.id === action.payload);

      state.exchangers.splice(index, 1);
    });

    builder.addCase(API.removeExchanger.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchTransactions.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactions = action.payload.transactions;
      state.transactions_pages = action.payload.total_pages;
    });

    builder.addCase(API.fetchTransactions.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const adminMarketplaceReducer = adminSlice.reducer;

export default adminMarketplaceReducer;
