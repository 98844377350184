const transactions = state => state.transactionsMerchant.transactions;
const total_pages = state => state.transactionsMerchant.total_pages;
const transaction = state => state.transactionsMerchant.transaction;
const isLoading = state => state.transactionsMerchant.isLoading;
const error = state => state.transactionsMerchant.error;

const select = {
  transactions,
  total_pages,
  transaction,
  isLoading,
  error,
};

export default select;
