import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { default as API_TRANSACTIONS_MERCHANT } from '../../../redux/merchant/transactions/operations';
import { Svg, Modal } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledForm,
  StyledFormElementWrp,
  StyledTopWrp,
  StyledContentWrp,
  StyledContentTopWrp,
  StyledSwitchBtn,
  StyledIconWrp,
  StyledTickBtn,
  StyledFieldWrp,
  StyledField,
  StyledExchangeBtnWrp,
  StyledExchangeBtn,
  StyledText,
  StyledSelectWrp,
  StyledSelectListWrp,
  StyledSelectList,
  StyledSelectItem,
} from './CreateTransactionCreateForm.styled';
import { AnimatePresence } from 'framer-motion';

//если в поле отдаете выбран тезер в любой сети то в получаете отображать только эфир биткоин бнб и матик

const network_list = [
  // {
  //   id: 0,
  //   name: 'bitcoin',
  //   token: 'btc',
  //   value: '',
  // },
  {
    id: 1,
    name: 'ethereum',
    token: 'eth',
    value: 'eth',
  },
  {
    id: 2,
    name: 'ethereum',
    token: 'usdt',
    value: 'usdt_erc',
  },
  {
    id: 3,
    name: 'arbitrum',
    token: 'usdt',
    value: 'usdt_arb',
  },
  {
    id: 4,
    name: 'optimism',
    token: 'usdt',
    value: 'usdt_op',
  },
  {
    id: 5,
    name: 'polygon',
    token: 'matic',
    value: 'matic_polygon',
  },
  {
    id: 6,
    name: 'polygon',
    token: 'usdt',
    value: 'usdt_polygon',
  },
  {
    id: 7,
    name: 'bsc',
    token: 'bnb',
    value: 'bnb_bsc',
  },
  {
    id: 8,
    name: 'bsc',
    token: 'usdt',
    value: 'usdt_bsc',
  },
  {
    id: 9,
    name: 'tron',
    token: 'usdt',
    value: 'usdt_trc',
  },
  {
    id: 10,
    name: 'tron',
    token: 'tron',
    value: 'tron',
  },
];

const CreateTransactionCreateForm = ({ handlePaymentModalToggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState('');
  const [isSwitchBtnClicked, setIsSwitchBtnClicked] = useState(false);

  const handleModalToggle = () => {
    if (isModalOpen) {
      setType('');
    }

    setIsModalOpen(currentState => !currentState);
  };

  return (
    <Formik
      initialValues={{
        from: network_list[0],
        to: network_list[5],
        amount: '',
        address: '',
      }}
      onSubmit={({ from, to, amount, address }, { resetForm }) => {
        const data = {
          from_currency: from.value,
          to_currency: to.value,
          amount,
          destination_address: address,
          payment_duration: '15m',
        };

        dispatch(API_TRANSACTIONS_MERCHANT.createTransaction(data))
          .then(data => {
            if (data?.error) {
              throw new Error();
            }

            handlePaymentModalToggle();
          })
          .catch(error => {
            toast.error('We have no trader for your transaction')
          });

        resetForm();
      }}
    >
      {({ handleChange, values, setFieldValue }) => {
        const handleSelectCurrency = data => {
          if (type === 'from') {
            if (values.to.id === data.id) {
              return;
            }
            setFieldValue('from', data);
          }

          if (type === 'to') {
            if (values.from.id === data.id) {
              return;
            }
            setFieldValue('to', data);
          }

          handleModalToggle();
        };

        const handleSwitchBtnToggle = () => {
          setIsSwitchBtnClicked(currentState => !currentState);
          setFieldValue('from', values.to);
          setFieldValue('to', values.from);
        };

        return (
          <>
            <StyledForm>
              <StyledFormElementWrp mg_b_m="20">
                <StyledTopWrp>
                  <StyledText fz="21" fw="500">
                    {t('exchangerGiving')}
                  </StyledText>
                </StyledTopWrp>

                <StyledContentWrp>
                  <StyledContentTopWrp
                    onClick={() => {
                      setType('from');
                      handleModalToggle();
                    }}
                  >
                    <StyledIconWrp>
                      <Svg w_m="40" h_m="40">
                        <use
                          href={`${sprite}#network-${values.from.name}-logo-coin`}
                        ></use>
                      </Svg>
                    </StyledIconWrp>

                    <div>
                      <StyledText mg_b="4" fz="21" tr="capitalize">
                        {values.from.name}
                      </StyledText>
                      <StyledText fz="12" c="secondary" tr="uppercase">
                        {values.from.token}
                      </StyledText>
                    </div>

                    <StyledTickBtn
                      type="button"
                      style={{
                        transform:
                          isModalOpen && type === 'from'
                            ? 'rotate(180deg)'
                            : '',
                      }}
                    >
                      <Svg w_m="16" h_m="16" c="secondary">
                        <use href={`${sprite}#tick`}></use>
                      </Svg>

                      <p className="hidden">tick</p>
                    </StyledTickBtn>
                  </StyledContentTopWrp>

                  <StyledFieldWrp>
                    <StyledField
                      type="number"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange('amount')}
                      placeholder="Amount"
                    />
                  </StyledFieldWrp>
                </StyledContentWrp>
              </StyledFormElementWrp>

              <StyledSwitchBtn
                onClick={handleSwitchBtnToggle}
                style={{
                  transform: isSwitchBtnClicked ? 'rotateY(180deg)' : '',
                }}
              >
                <Svg w_m="16" h_m="17.5" c="#ffffff">
                  <use href={`${sprite}#switch`}></use>
                </Svg>

                <p className="hidden">Switch</p>
              </StyledSwitchBtn>

              <StyledFormElementWrp>
                <StyledTopWrp>
                  <StyledText fz="21" fw="500">
                    {t('exchangerReceive')}
                  </StyledText>
                </StyledTopWrp>

                <StyledContentWrp>
                  <StyledContentTopWrp
                    onClick={() => {
                      setType('to');
                      handleModalToggle();
                    }}
                  >
                    <StyledIconWrp>
                      <Svg w_m="40" h_m="40">
                        <use
                          href={`${sprite}#network-${values.to.name}-logo-coin`}
                        ></use>
                      </Svg>
                    </StyledIconWrp>

                    <div>
                      <StyledText mg_b="4" fz="21" tr="capitalize">
                        {values.to.name}
                      </StyledText>
                      <StyledText fz="12" c="secondary" tr="uppercase">
                        {values.to.token}
                      </StyledText>
                    </div>

                    <StyledTickBtn
                      type="button"
                      style={{
                        transform:
                          isModalOpen && type === 'to' ? 'rotate(180deg)' : '',
                      }}
                    >
                      <Svg w_m="16" h_m="16" c="secondary">
                        <use href={`${sprite}#tick`}></use>
                      </Svg>

                      <p className="hidden">tick</p>
                    </StyledTickBtn>
                  </StyledContentTopWrp>

                  <StyledFieldWrp>
                    <StyledField
                      type="text"
                      name="address"
                      value={values.address}
                      onChange={handleChange('address')}
                      placeholder="Your Address"
                    />
                  </StyledFieldWrp>

                  <StyledExchangeBtnWrp>
                    <StyledExchangeBtn type="submit">
                      {t('exchangerButton')}
                    </StyledExchangeBtn>
                  </StyledExchangeBtnWrp>
                </StyledContentWrp>
              </StyledFormElementWrp>
            </StyledForm>

            <AnimatePresence onExitComplete={() => null} initial={false}>
              {isModalOpen && (
                <Modal toggle={handleModalToggle}>
                  <StyledSelectWrp>
                    <StyledText mg_b="8" c="secondary">
                      Choose Network
                    </StyledText>

                    <StyledSelectListWrp>
                      <StyledSelectList>
                        {network_list
                          .filter(el => {
                            if (
                              values.from.value.includes('usdt') &&
                              type === 'to'
                            ) {
                              return !el.value.includes('usdt');
                            }

                            if (
                              values.to.value.includes('usdt') &&
                              type === 'from'
                            ) {
                              return !el.value.includes('usdt');
                            }

                            return el;
                          })
                          .map(el => {
                            return (
                              <StyledSelectItem
                                key={el.id}
                                onClick={() => handleSelectCurrency(el)}
                              >
                                <Svg w_m="28" h_m="28" mg_r_m="8">
                                  <use
                                    href={`${sprite}#network-${el.name}-logo-coin`}
                                  ></use>
                                </Svg>

                                <div>
                                  <StyledText mg_b="4" fz="18" tr="uppercase">
                                    {el.token}
                                  </StyledText>
                                  <StyledText
                                    fz="12"
                                    c="secondary"
                                    tr="capitalize"
                                  >
                                    {el.name}
                                  </StyledText>
                                </div>
                              </StyledSelectItem>
                            );
                          })}
                      </StyledSelectList>
                    </StyledSelectListWrp>
                  </StyledSelectWrp>
                </Modal>
              )}
            </AnimatePresence>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateTransactionCreateForm;
