import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import swapReducer from './slice';

const persistConfig = {
  key: 'swap',
  storage,
  whitelist: ['swap', 'account'],
};

const persistedSwapReducer = persistReducer(persistConfig, swapReducer);

export default persistedSwapReducer;
