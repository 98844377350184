import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)`
  width: 60px;
  height: 60px;

  font-weight: 700;
  font-size: 18px;

  color: var(--text-primary-color);
`;
