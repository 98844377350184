import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Home = lazy(() => import('pages/swap/Home'));

const useSwapRouter = () => {
  return (
    <>
      <Route path="swap" element={<Home />} />
    </>
  );
};

export default useSwapRouter;
