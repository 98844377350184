import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { Svg, Modal } from 'components/common';
import ProfileVolumeForm from 'components/merchant/ProfileVolumeForm';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledIconWrp,
  StyledBtn,
  StyledText,
} from './ProfileVolume.styled';

const ProfileVolume = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(currentState => !currentState);
  };

  return (
    <>
      <StyledWrp>
        <StyledIconWrp>
          <Svg
            w_m="28"
            h_m="28"
            w_t="34"
            h_t="34"
            c="secondary"
            mg_r_m="12"
            mg_r_t="12"
          >
            <use href={`${sprite}#dollar`}></use>
          </Svg>
        </StyledIconWrp>

        <div>
          <StyledBtn type="button" onClick={handleModalToggle}>
            ...
          </StyledBtn>

          <StyledText>{t('profileVolume')}</StyledText>
        </div>
      </StyledWrp>

      <AnimatePresence onExitComplete={() => null} initial={false}>
        {isModalOpen && (
          <Modal toggle={handleModalToggle}>
            <ProfileVolumeForm toggle={handleModalToggle} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default ProfileVolume;
