import { configureStore } from '@reduxjs/toolkit';
import persistedAppReducer from './app/reduxPersistor';
import persistedMerchantReducer from './merchant/reduxPersistor';
import userMerchantReducer from './merchant/user/slice';
import persistedMerchantTransactionsReducers from './merchant/transactions/reduxPersistor';
import hashesMerchantReducer from './merchant/hashes/slice';
import withdrawsMerchantReducer from './merchant/withdraws/slice';
import adminMerchantReducer from './merchant/admin/slice';
import persistedSwapReducer from './swap/reduxPersistor';
import persistedMarketplaceReducer from './marketplace/reduxPersistor';
import profileMarketplaceReducer from './marketplace/profile/slice';
import ordersMarketplaceReducer from './marketplace/orders/slice';
import bankMarketplaceReducer from './marketplace/bank/slice';
import publicProfileMarketplaceReducer from './marketplace/publicProfile/slice';
import exchangerAdminMarketplaceReducer from './marketplace/exchangerAdmin/slice';
import operatorMarketplaceReducer from './marketplace/operator/slice';
import adminMarketplaceReducer from './marketplace/admin/slice';

import {
  persistStore,
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
} from 'redux-persist';

const store = configureStore({
  reducer: {
    app: persistedAppReducer,
    authMerchant: persistedMerchantReducer,
    userMerchant: userMerchantReducer,
    transactionsMerchant: persistedMerchantTransactionsReducers,
    hashesMerchant: hashesMerchantReducer,
    withdrawsMerchant: withdrawsMerchantReducer,
    adminMerchant: adminMerchantReducer,
    swap: persistedSwapReducer,
    authMarketplace: persistedMarketplaceReducer,
    ordersMarketplace: ordersMarketplaceReducer,
    profileMarketplace: profileMarketplaceReducer,
    bankMarketplace: bankMarketplaceReducer,
    publicProfileMarketplace: publicProfileMarketplaceReducer,
    adminMarketplace: adminMarketplaceReducer,
    operatorMarketplace: operatorMarketplaceReducer,
    exchangerAdminMarketplace: exchangerAdminMarketplaceReducer,
  },

  middleware: getDefaultMiddleware =>
    // getDefaultMiddleware({
    //   serializableCheck: {
    //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    //   },
    // }),
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

const redux = { store, persistor };

export default redux;
