import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TbPlugConnected, TbPlugConnectedX } from 'react-icons/tb';
import API from '../../../redux/merchant/user/operations';
import { useProfileMerchant } from 'hooks/merchant';
import {
  StyledWrp,
  StyledContentWrp,
  StyledSpan,
} from './ProfileSwitcher.styled';

const ProfileSwitcher = () => {
  const dispatch = useDispatch();
  const { is_trader_online } = useProfileMerchant();
  const [isSelected, setIsSelected] = useState();

  const handleButtonClick = () => {
    isSelected
      ? dispatch(API.changeTraderStatusToOffline())
      : dispatch(API.changeTraderStatusToOnline());
  };

  useEffect(() => {
    if (is_trader_online === null) {
      return;
    }

    setIsSelected(is_trader_online);
  }, [is_trader_online]);

  return (
    <StyledWrp onClick={handleButtonClick}>
      <StyledContentWrp>
        <TbPlugConnected style={{ zIndex: 2 }} size={24} />

        <TbPlugConnectedX style={{ zIndex: 2 }} size={24} />
      </StyledContentWrp>
      <StyledSpan
        style={{ left: isSelected ? '6%' : '49%', transition: 'all 250ms' }}
      ></StyledSpan>
    </StyledWrp>
  );
};

export default ProfileSwitcher;
