const user_count = state => state.adminMerchant.user_count;
const transaction_count = state => state.adminMerchant.transaction_count;
const transaction_turnover = state => state.adminMerchant.transaction_turnover;
const transaction_currency_count = state =>
  state.adminMerchant.transaction_currency_count;
const traders = state => state.adminMerchant.traders;
const traders_pages = state => state.adminMerchant.traders_pages;
const isLoading = state => state.adminMerchant.isLoading;
const error = state => state.adminMerchant.error;

const select = {
  user_count,
  transaction_count,
  transaction_turnover,
  transaction_currency_count,
  traders,
  traders_pages,
  isLoading,
  error,
};

export default select;
