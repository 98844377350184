import { StyledList, StyledItem } from './Pagination.styled';

const Pagination = ({ data, handlePageSet, page }) => {
  return (
    <StyledList>
      {data.map(el => (
        <StyledItem
          onClick={() => handlePageSet(el)}
          key={el}
          style={{
            borderColor: el === page ? 'var(--border-pagination-color)' : '',
          }}
        >
          {el}
        </StyledItem>
      ))}
    </StyledList>
  );
};

export default Pagination;
