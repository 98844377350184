import styled from '@emotion/styled';

export const StyledText = styled.p`
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};
  margin-right: ${({ mg_r }) => (mg_r ? `${mg_r}px` : '')};

  font-size: ${({ fz }) => (fz ? `${fz}px` : '16px')};
  font-weight: ${({ fw }) => (fw ? fw : 500)};
  line-height: 120%;

  color: ${({ c }) => {
    if (c === 'green') {
      return 'var(--accent-marketplace-green-color)';
    }

    if (c === 'red') {
      return 'var(--accent-marketplace-red-color)';
    }

    if (c === 'secondary') {
      return 'var(--text-secondary-color)';
    }

    return 'var(--text-primary-color)';
  }};
`;

export const StyledSpan = styled.span`
  font-family: Roboto, sans-serif;
  font-weight: ${({ fw }) => (fw ? fw : 500)};
`;

export const StyledWrp = styled.div`
  padding: 50px 20px;
  margin-bottom: 80px;

  background: var(--background-marketplace-user-card-color);
  border-radius: 18px;
`;

export const StyledTypeListWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const StyledTypeList = styled.ul`
  display: flex;
  align-items: center;
`;

export const StyledTypeItem = styled.li`
  padding: ${({ type }) => (type ? '10px 40px' : '')};
  background: ${({ type }) =>
    type ? 'var(--background-marketplace-user-type-color)' : ''};
  box-shadow: ${({ type }) =>
    type ? '0px 10px 6px 0px rgba(0, 0, 0, 0.25)' : ''};
  border-radius: 99px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const StyledAdminStatisticList = styled.ul`
  display: flex;
`;

export const StyledAdminStatisticItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 284px;
  height: 160px;
  padding: 18px 0;

  text-align: center;

  border: 1px solid var(--border-marketplace-user-statistic-color);
  border-radius: 18px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const StyledAdminStatisticBtn = styled.button`
  font-size: ${({ fz }) => (fz ? `${fz}px` : '16px')};
  line-height: 120%;

  color: ${({ c }) =>
    c ? 'var(--text-primary-color)' : 'var(--accent-marketplace-green-color)'};
  background: transparent;
  border: none;
`;
