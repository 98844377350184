import styled from '@emotion/styled';

export const StyledText = styled.p`
  min-width: ${({ m_w }) => (m_w ? `${m_w}px` : '')};

  font-size: ${({ fz }) => (fz ? `${fz}px` : '14px')};
  font-weight: ${({ fw }) => (fw ? fw : 400)};
  line-height: 120%;

  color: var(--text-primary-color);
`;

export const StyledSpan = styled.span`
  font-family: Roboto, sans-serif;
`;

export const StyledWrp = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 40px;

  background: var(--background-secondary-color);
  border-radius: 18px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const StyledUsernameWrp = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIconWrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;

  background: var(--background-primary-color);
  border-radius: 50%;
`;

export const StyledBtnGroup = styled.div`
  display: flex;
`;

export const StyledBtn = styled.button`
  margin-right: ${({ mg_r }) => (mg_r ? `${mg_r}px` : '')};

  font-size: 16px;
  font-weight: 600;
  line-height: 120%;

  color: ${({ c }) => {
    if (c === 'red') {
      return 'var(--accent-marketplace-red-color)';
    }

    return '';
  }};
  background: transparent;
  border: none;
`;
