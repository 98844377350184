import { useSelector } from 'react-redux';
import select from '../../redux/merchant/admin/selectors';

const useAdmin = () => {
  const user_count = useSelector(select.user_count);
  const transaction_count = useSelector(select.transaction_count);
  const transaction_turnover = useSelector(select.transaction_turnover);
  const transaction_currency_count = useSelector(
    select.transaction_currency_count
  );
  const traders = useSelector(select.traders);
  const traders_pages = useSelector(select.traders_pages);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    user_count,
    transaction_count,
    transaction_turnover,
    transaction_currency_count,
    traders,
    traders_pages,
    isLoading,
    error,
  };
};

export default useAdmin;
