const withdraws = state => state.withdrawsMerchant.withdraws;
const total_pages = state => state.withdrawsMerchant.total_pages;
const isLoading = state => state.withdrawsMerchant.isLoading;
const error = state => state.withdrawsMerchant.error;

const select = {
  withdraws,
  total_pages,
  isLoading,
  error,
};

export default select;
