import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth/slice';

const persistConfig = {
  key: 'merchant',
  storage,
  whitelist: ['token'],
};

const persistedMerchantReducer = persistReducer(persistConfig, authReducer);

export default persistedMerchantReducer;
