import { AnimatePresence } from 'framer-motion';
import { Modal } from 'components/common';
import CreateTransactionPayoutForm from 'components/merchant/CreateTransactionPayoutForm';

import { StyledBtn } from './CreateTransactionActiveTransaction.styled';
import { useState } from 'react';
import { useTransactionsMerchant } from 'hooks/merchant';

const CreateTransactionActiveTransaction = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { transaction } = useTransactionsMerchant();

  const handleModalToggle = () => {
    setIsModalOpen(currentState => !currentState);
  };

  return (
    <>
      {transaction?.id && (
        <>
          <StyledBtn type="button" onClick={handleModalToggle}>
            Active transaction
          </StyledBtn>

          <AnimatePresence onExitComplete={() => null} initial={false}>
            {isModalOpen && (
              <Modal toggle={handleModalToggle}>
                <CreateTransactionPayoutForm toggle={handleModalToggle} />
              </Modal>
            )}
          </AnimatePresence>
        </>
      )}
    </>
  );
};

export default CreateTransactionActiveTransaction;
