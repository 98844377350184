import { useSelector } from 'react-redux';
import select from '../../redux/merchant/withdraws/selectors';

const useWithdraws = () => {
  const withdraws = useSelector(select.withdraws);
  const total_pages = useSelector(select.total_pages);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    withdraws,
    total_pages,
    isLoading,
    error,
  };
};

export default useWithdraws;
