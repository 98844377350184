import ProfileHashListItem from 'components/merchant/ProfileHashListItem';
import { StyledList } from './ProfileHashList.styled';

const hashItemVariants = {
  hidden: {
    x: 110,
    opacity: 0,
  },
  visible: custom => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.1, duration: 0.25 },
  }),
};

const ProfileHashList = ({ data }) => {
  return (
    <StyledList>
      {data.map((el, index) => (
        <ProfileHashListItem
          key={el.transaction_id}
          data={el}
          initial="hidden"
          animate="visible"
          variants={hashItemVariants}
          custom={index}
        />
      ))}
    </StyledList>
  );
};

export default ProfileHashList;
