import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const bankSlice = createSlice({
  name: 'bankMarketplace',

  initialState: {
    banks: [],
    bank_cards: [],
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.banks = [];
      state.bank_cards = [];
    });

    builder.addCase(API.fetchBanks.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchBanks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.banks = action.payload;
    });

    builder.addCase(API.fetchBanks.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchBankCards.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchBankCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bank_cards = action.payload;
    });

    builder.addCase(API.fetchBankCards.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.addBankCard.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.addBankCard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bank_cards.push(action.payload);
    });

    builder.addCase(API.addBankCard.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.replaceBankCard.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.replaceBankCard.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.bank_cards.findIndex(
        el => el.id === action.payload.id
      );

      state.bank_cards.splice(index, 1, action.payload);
    });

    builder.addCase(API.replaceBankCard.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.removeBankCard.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.removeBankCard.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.bank_cards.findIndex(
        el => el.card_id === action.payload
      );

      state.bank_cards.splice(index, 1);
    });

    builder.addCase(API.removeBankCard.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const bankMarketplaceReducer = bankSlice.reducer;

export default bankMarketplaceReducer;
