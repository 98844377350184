import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from 'i18n';
import { toast } from 'react-toastify';

const fetchTransactions = createAsyncThunk(
  'transactionsMerchant/fetchTransactions',
  async (_, { rejectWithValue }) => {
    const searchParams = new URLSearchParams(document.location.search);
    const page = searchParams.get('page');
    const per_page = searchParams.get('per_page');

    try {
      const response = await axios.get(
        `/crypto?page=${page}&per_page=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTransactionCsvFile = createAsyncThunk(
  'transactionsMerchant/fetchTransactionCsvFile',
  async (fileName, { rejectWithValue }) => {
    try {
      toast.info(i18n.t('notificationTransactionDownloadCsv.info'));

      const response = await axios.get('/crypto/archive', {
        responseType: 'blob',
      });

      const href = window.URL.createObjectURL(response.data);

      const anchorElement = document.createElement('a');

      anchorElement.href = href;
      anchorElement.download = fileName;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    } catch (error) {
      toast.error(i18n.t('notificationTransactionDownloadCsv.error'));

      return rejectWithValue(error.message);
    }
  }
);

const createTransaction = createAsyncThunk(
  'transactionsMerchant/createTransaction',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/crypto', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const confirmTransaction = createAsyncThunk(
  'transactionsMerchant/confirmTransaction',
  async (id, { rejectWithValue }) => {
    try {
      await axios.patch('/crypto/finish', {
        transaction_id: id,
      });

      toast.success(i18n.t('notificationTransactionConfirm'));

      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = {
  fetchTransactions,
  fetchTransactionCsvFile,
  createTransaction,
  confirmTransaction,
};

export default API;
