import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Home = lazy(() => import('pages/common/Home'));
const Rules = lazy(() => import('pages/common/Rules'));
const Contacts = lazy(() => import('pages/common/Contacts'));

const useCommonRouter = () => {
  return (
    <>
      <Route index element={<Home />} />
      <Route path="rules" element={<Rules />} />
      <Route path="contacts" element={<Contacts />} />
    </>
  );
};

export default useCommonRouter;
