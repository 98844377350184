import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useAuthMarketplace, useProfileMarketplace } from 'hooks/marketplace';

const Home = lazy(() => import('pages/marketplace/Home'));
const PublicProfile = lazy(() => import('pages/marketplace/PublicProfile'));
const PublicProfileFollowers = lazy(() =>
  import('pages/marketplace/PublicProfileFollowers')
);
const PublicProfileFeedback = lazy(() =>
  import('pages/marketplace/PublicProfileFeedback')
);
const PublicProfileFeedbackAll = lazy(() =>
  import('pages/marketplace/PublicProfileFeedbackAll')
);
const PublicProfileFeedbackPositive = lazy(() =>
  import('pages/marketplace/PublicProfileFeedbackPositive')
);
const PublicProfileFeedbackNegative = lazy(() =>
  import('pages/marketplace/PublicProfileFeedbackNegative')
);
const PublicProfileComment = lazy(() =>
  import('pages/marketplace/PublicProfileComment')
);
const P2P = lazy(() => import('pages/marketplace/P2P'));
const Deal = lazy(() => import('pages/marketplace/Deal'));
const PostAd = lazy(() => import('pages/marketplace/PostAd'));
const UserOrders = lazy(() => import('pages/marketplace/UserOrders'));
const UserDeals = lazy(() => import('pages/marketplace/UserDeals'));
const UserCenter = lazy(() => import('pages/marketplace/UserCenter'));
const UserCenterPaymentMethods = lazy(() =>
  import('pages/marketplace/UserCenterPaymentMethods')
);
const UserCenterFeedback = lazy(() =>
  import('pages/marketplace/UserCenterFeedback')
);
const UserCenterFeedbackAll = lazy(() =>
  import('pages/marketplace/UserCenterFeedbackAll')
);
const UserCenterFeedbackPositive = lazy(() =>
  import('pages/marketplace/UserCenterFeedbackPositive')
);
const UserCenterFeedbackNegative = lazy(() =>
  import('pages/marketplace/UserCenterFeedbackNegative')
);
const UserCenterCounterparty = lazy(() =>
  import('pages/marketplace/UserCenterCounterparty')
);
const UserCenterFollows = lazy(() =>
  import('pages/marketplace/UserCenterFollows')
);
const UserCenterFollowsFollowing = lazy(() =>
  import('pages/marketplace/UserCenterFollowsFollowing')
);
const UserCenterFollowsFollowers = lazy(() =>
  import('pages/marketplace/UserCenterFollowsFollowers')
);

const UserCenterUSDTWallets = lazy(() =>
  import('pages/marketplace/UserCenterUSDTWallets')
);

// const UserCenterRestrictionsRemovalCenter = lazy(() =>
//   import('pages/marketplace/UserCenterRestrictionsRemovalCenter')
// );

const UserCenterSuspendedFunction = lazy(() =>
  import('pages/marketplace/UserCenterSuspendedFunction')
);
const UserCenterFrozenAssets = lazy(() =>
  import('pages/marketplace/UserCenterFrozenAssets')
);
const UserCenterNotificationSettings = lazy(() =>
  import('pages/marketplace/UserCenterNotificationSettings')
);
const UserCenterTransactions = lazy(() =>
  import('pages/marketplace/UserCenterTransactions')
);

const ExchangerAdminCenter = lazy(() =>
  import('pages/marketplace/ExchangerAdminCenter')
);
const ExchangerAdminCenterDeals = lazy(() =>
  import('pages/marketplace/ExchangerAdminCenterDeals')
);
const ExchangerAdminCenterTraders = lazy(() =>
  import('pages/marketplace/ExchangerAdminCenterTraders')
);
const OperatorCenter = lazy(() => import('pages/marketplace/OperatorCenter'));
const OperatorCenterRequests = lazy(() =>
  import('pages/marketplace/OperatorCenterRequests')
);
const AdminCenter = lazy(() => import('pages/marketplace/AdminCenter'));
const AdminCenterOperators = lazy(() =>
  import('pages/marketplace/AdminCenterOperators')
);
const AdminCenterBusinessAccounts = lazy(() =>
  import('pages/marketplace/AdminCenterBusinessAccounts')
);
const AdminCenterTransactions = lazy(() =>
  import('pages/marketplace/AdminCenterTransactions')
);

const useMarketplaceRouter = () => {
  const { isLoggedInMarketplace } = useAuthMarketplace();
  const { role, isLoadingRole } = useProfileMarketplace();

  return (
    <Route path="marketplace" element={<Home />}>
      <>
        {!isLoadingRole &&
          (role === 'user' || role === 'viewer' || role === 'trader') && (
            <>
              <Route path="p2p" element={<P2P />} />
              <Route path="user/:id" element={<PublicProfile />}>
                <Route path="followers" element={<PublicProfileFollowers />} />
                <Route path="feedback" element={<PublicProfileFeedback />}>
                  <Route path="all" element={<PublicProfileFeedbackAll />} />
                  <Route
                    path="positive"
                    element={<PublicProfileFeedbackPositive />}
                  />
                  <Route
                    path="negative"
                    element={<PublicProfileFeedbackNegative />}
                  />
                </Route>
                <Route path="comment" element={<PublicProfileComment />} />
              </Route>
              {isLoggedInMarketplace && (
                <>
                  <Route path="deal/:id" element={<Deal />} />
                  <Route path="post_ad" element={<PostAd />} />
                  <Route path="user_deals" element={<UserDeals />} />
                  <Route path="user_orders" element={<UserOrders />} />
                  <Route path="user_center" element={<UserCenter />}>
                    {role === 'trader' && (
                      <Route
                        path="transactions"
                        element={<UserCenterTransactions />}
                      />
                    )}
                    <Route
                      path="payment_methods"
                      element={<UserCenterPaymentMethods />}
                    />
                    <Route path="feedback" element={<UserCenterFeedback />}>
                      <Route path="all" element={<UserCenterFeedbackAll />} />
                      <Route
                        path="positive"
                        element={<UserCenterFeedbackPositive />}
                      />
                      <Route
                        path="negative"
                        element={<UserCenterFeedbackNegative />}
                      />
                    </Route>

                    <Route
                      path="counterparty"
                      element={<UserCenterCounterparty />}
                    />
                    <Route path="follows" element={<UserCenterFollows />}>
                      <Route
                        path="following"
                        element={<UserCenterFollowsFollowing />}
                      />
                      <Route
                        path="followers"
                        element={<UserCenterFollowsFollowers />}
                      />
                    </Route>

                    <Route
                      path="usdt_wallets"
                      element={<UserCenterUSDTWallets />}
                    >
                      <Route
                        path="suspended_function"
                        element={<UserCenterSuspendedFunction />}
                      />

                      <Route
                        path="frozen_assets"
                        element={<UserCenterFrozenAssets />}
                      />
                    </Route>

                    <Route
                      path="notification_settings"
                      element={<UserCenterNotificationSettings />}
                    />
                  </Route>
                </>
              )}
            </>
          )}

        {isLoggedInMarketplace &&
          !isLoadingRole &&
          role === 'exchanger_admin' && (
            <Route path="admin_center" element={<ExchangerAdminCenter />}>
              <Route path="deals" element={<ExchangerAdminCenterDeals />} />
              <Route path="traders" element={<ExchangerAdminCenterTraders />} />
            </Route>
          )}

        {isLoggedInMarketplace && !isLoadingRole && role === 'operator' && (
          <>
            <Route path="operator_center" element={<OperatorCenter />}>
              <Route path="requests" element={<OperatorCenterRequests />} />
            </Route>

            <Route path="deal/:id" element={<Deal />} />

            <Route path="user/:id" element={<PublicProfile />}>
              <Route path="followers" element={<PublicProfileFollowers />} />
              <Route path="feedback" element={<PublicProfileFeedback />}>
                <Route path="all" element={<PublicProfileFeedbackAll />} />
                <Route
                  path="positive"
                  element={<PublicProfileFeedbackPositive />}
                />
                <Route
                  path="negative"
                  element={<PublicProfileFeedbackNegative />}
                />
              </Route>
              <Route path="comment" element={<PublicProfileComment />} />
            </Route>
          </>
        )}

        {isLoggedInMarketplace && !isLoadingRole && role === 'admin' && (
          <Route path="admin_center" element={<AdminCenter />}>
            <Route path="operators" element={<AdminCenterOperators />} />
            <Route
              path="business_accounts"
              element={<AdminCenterBusinessAccounts />}
            />
            <Route path="transactions" element={<AdminCenterTransactions />} />
          </Route>
        )}
      </>
    </Route>
  );
};

export default useMarketplaceRouter;
