import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const exchangerAdminSlice = createSlice({
  name: 'exchangerAdminMarketplace',

  initialState: {
    statistic: {},
    deals: [],
    traders: [],
    rate: null,
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.statistic = {};
      state.deals = [];
      state.traders = [];
    });

    builder.addCase(API.fetchStatistic.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchStatistic.fulfilled, (state, action) => {
      state.isLoading = false;
      state.statistic = action.payload;
    });

    builder.addCase(API.fetchStatistic.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchDeals.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchDeals.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deals = action.payload;
    });

    builder.addCase(API.fetchDeals.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchTraders.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchTraders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.traders = action.payload;
    });

    builder.addCase(API.fetchTraders.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.addTrader.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.addTrader.fulfilled, (state, action) => {
      state.isLoading = false;
      state.traders.push(action.payload);
    });

    builder.addCase(API.addTrader.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.removeTrader.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.removeTrader.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.traders.findIndex(el => el.id === action.payload);

      state.traders.splice(index, 1);
    });

    builder.addCase(API.removeTrader.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchExchangerRate.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchExchangerRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rate = action.payload;
    });

    builder.addCase(API.fetchExchangerRate.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.createExchangerRate.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.createExchangerRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rate = action.payload;
    });

    builder.addCase(API.createExchangerRate.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.changeExchangerRate.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.changeExchangerRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rate = action.payload;
    });

    builder.addCase(API.changeExchangerRate.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const exchangerAdminMarketplaceReducer = exchangerAdminSlice.reducer;

export default exchangerAdminMarketplaceReducer;
