import { useSelector } from 'react-redux';
import select from '../../redux/merchant/auth/selectors';

const useAuth = () => {
  const isLoggedInMerchant = useSelector(select.isLoggedIn);
  const isLoadingMerchant = useSelector(select.isLoading);
  const errorMerchant = useSelector(select.error);

  return {
    isLoggedInMerchant,
    isLoadingMerchant,
    errorMerchant,
  };
};

export default useAuth;
