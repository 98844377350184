import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledList = styled.ul`
  margin-bottom: 30px;

  ${mq.tablet} {
    margin-bottom: 45px;
  }

  ${mq.desktop} {
    margin-bottom: 23px;
  }
`;
