import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from 'axios';

const fetchOrders = createAsyncThunk(
  'ordersMarketplace/fetchOrders',
  async (_, { rejectWithValue }) => {
    const searchParams = new URLSearchParams(document.location.search);
    const type = searchParams.get('type');
    const page = searchParams.get('page');
    const per_page = searchParams.get('per_page');

    try {
      const response = await axios.get(
        `/orders?type=${type}&page=${page}&per_page=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const createOrder = createAsyncThunk(
  'ordersMarketplace/createOrder',
  async (data, { rejectWithValue }) => {
    try {
      await axios.post('/orders', data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const startOrder = createAsyncThunk(
  'ordersMarketplace/startOrder',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/orders/start', data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDealInfoSeller = createAsyncThunk(
  'ordersMarketplace/fetchDealInfoSeller',
  async (deal_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/orders/deal_info_seller/${deal_id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDealInfoBuyer = createAsyncThunk(
  'ordersMarketplace/fetchDealInfoBuyer',
  async (deal_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/orders/deal_info_buyer/${deal_id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDealInfoOperator = createAsyncThunk(
  'ordersMarketplace/fetchDealInfoOperator',
  async (deal_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/orders/deal_info_operator/${deal_id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const payOrder = createAsyncThunk(
  'ordersMarketplace/payOrder',
  async (data, { rejectWithValue }) => {
    try {
      await axios.patch('/orders/pay', data);

      toast.success(`Deal paid, now wait for seller's confirmation`);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const finishOrder = createAsyncThunk(
  'ordersMarketplace/finishOrder',
  async (data, { rejectWithValue }) => {
    try {
      await axios.post('/orders/finish', data);

      toast.success(`Deal confirmed`);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const requestOperator = createAsyncThunk(
  'ordersMarketplace/requestOperator',
  async (deal_id, { rejectWithValue }) => {
    try {
      const data = { deal_id };

      await axios.post('/orders/invite_operator', data);

      toast.success(`Request has been sent, wait for operator`);
    } catch (error) {
      toast.error(`You have already requested an operator`);

      return rejectWithValue(error.message);
    }
  }
);

const finishDeal = createAsyncThunk(
  'ordersMarketplace/finishDeal',
  async (deal_id, { rejectWithValue }) => {
    try {
      const data = { deal_id };

      await axios.post('/operators/finish', data);

      return deal_id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = {
  fetchOrders,
  createOrder,
  startOrder,
  fetchDealInfoSeller,
  fetchDealInfoBuyer,
  fetchDealInfoOperator,
  payOrder,
  finishOrder,
  requestOperator,
  finishDeal,
};

export default API;
