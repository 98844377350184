import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledFormWrp,
  StyledFormTopWrp,
  StyledCrossBtn,
  StyledTitleWrp,
  StyledTitle,
  StyledText,
} from './AdminTraderForms.styled';

const AdminTraderSuccessModal = ({ toggle }) => {
  return (
    <StyledFormWrp>
      <StyledFormTopWrp mg_b="20">
        <StyledCrossBtn type="button" onClick={toggle}>
          <Svg w_m="18" h_m="18" c="secondary">
            <use href={`${sprite}#cross`}></use>
          </Svg>

          <p className="hidden">cross</p>
        </StyledCrossBtn>
      </StyledFormTopWrp>

      <StyledTitleWrp>
        <Svg w_m="20" h_m="20" mg_r_m="8">
          <use href={`${sprite}#marketplace-success-checkmark`}></use>
        </Svg>

        <StyledTitle>Ваше приглашение отправлено</StyledTitle>
      </StyledTitleWrp>

      <StyledText>
        Мы будем рады приветствовать новых трейдеров на нашей платформе.
        Спасибо, что помогаете нам развивать наше сообщество!
      </StyledText>
    </StyledFormWrp>
  );
};

export default AdminTraderSuccessModal;
