import { copyToClipboard } from 'utils';
import useApp from 'hooks/app/useApp';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledArea,
  StyledCopyBtn,
  StyledText,
  StyledSpan,
} from './ProfileUSDTWalletListItem.styled';

const ProfileUSDTWalletListItem = ({ data }) => {
  const { name, value } = data;
  const { screenWidth } = useApp();

  return (
    <StyledWrp>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Svg w_m="24" h_m="24" mg_r_m="4">
          <use href={`${sprite}#${name}`}></use>
        </Svg>
        <StyledText tr="uppercase">{name}</StyledText>
      </div>

      <StyledArea>
        {screenWidth > 1400 && (
          <StyledText>
            <StyledSpan>{value.slice(0, 35)}...</StyledSpan>
          </StyledText>
        )}
        {screenWidth < 1400 && (
          <StyledText>
            <StyledSpan>{value.slice(0, 20)}...</StyledSpan>
          </StyledText>
        )}


        <StyledCopyBtn type="button" onClick={() => copyToClipboard(value)}>
          <Svg w_m="28" h_m="24">
            <use href={`${sprite}#copy`}></use>
          </Svg>
          <p className="hidden">copy</p>
        </StyledCopyBtn>
      </StyledArea>
    </StyledWrp>
  );
};

export default ProfileUSDTWalletListItem;
