import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const profileSlice = createSlice({
  name: 'profileMarketplace',

  initialState: {
    role: 'viewer',
    id: null,
    username: null,
    balance: null,
    statistic: {},
    deals_info: {},
    orders: [],
    orders_pages: null,
    deals: [],
    deals_pages: null,
    trader_deals: [],
    trader_deals_pages: null,
    contragents: [],
    comments: [],
    followers: [],
    following: [],
    usdt_wallet_addresses: [],
    isLoadingRole: false,
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.role = 'viewer';
      state.id = null;
      state.username = null;
      state.balance = null;
      state.statistic = {};
      state.deals_info = {};
      state.orders = [];
      state.deals = [];
      state.contragents = [];
      state.comments = [];
      state.followers = [];
      state.following = [];
      state.usdt_wallet_addresses = [];
    });

    builder.addCase(API.fetchRole.pending, state => {
      state.isLoadingRole = true;
      state.error = null;
    });

    builder.addCase(API.fetchRole.fulfilled, (state, action) => {
      state.isLoadingRole = false;
      state.role = action.payload;
    });

    builder.addCase(API.fetchRole.rejected, state => {
      state.isLoadingRole = false;
      state.error = true;
    });

    builder.addCase(API.fetchUserId.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchUserId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.id = action.payload;
    });

    builder.addCase(API.fetchUserId.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchUsername.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchUsername.fulfilled, (state, action) => {
      state.isLoading = false;
      state.username = action.payload;
    });

    builder.addCase(API.fetchUsername.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchBalance.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      state.balance = action.payload;
    });

    builder.addCase(API.fetchBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.widthdrawBalance.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.widthdrawBalance.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(API.widthdrawBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.withdrawBalanceWeb3.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.withdrawBalanceWeb3.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(API.withdrawBalanceWeb3.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchStatistic.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchStatistic.fulfilled, (state, action) => {
      state.isLoading = false;
      state.statistic = action.payload;
    });

    builder.addCase(API.fetchStatistic.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchDealsInfo.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchDealsInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deals_info = action.payload;
    });

    builder.addCase(API.fetchDealsInfo.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchOrders.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload.orders;
      state.orders_pages = action.payload.total_pages;
    });

    builder.addCase(API.fetchOrders.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.setOrderToOffline.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.setOrderToOffline.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.orders.findIndex(el => el.id === action.payload);

      state.orders.splice(index, 1, {
        ...state.orders[index],
        is_online: false,
      });
    });

    builder.addCase(API.setOrderToOffline.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.setOrderToOnline.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.setOrderToOnline.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.orders.findIndex(el => el.id === action.payload);

      state.orders.splice(index, 1, {
        ...state.orders[index],
        is_online: true,
      });
    });

    builder.addCase(API.setOrderToOnline.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.cancelOrder.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.cancelOrder.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.orders.findIndex(el => el.id === action.payload);

      state.orders.splice(index, 1);
    });

    builder.addCase(API.cancelOrder.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.updateOrderLimits.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.updateOrderLimits.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.orders.findIndex(
        el => el.id === action.payload.order_id
      );

      state.orders.splice(index, 1, {
        ...state.orders[index],
        order_low_limit: action.payload.new_low_limit,
        order_high_limit: action.payload.new_high_limit,
      });
    });

    builder.addCase(API.updateOrderLimits.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchDeals.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchDeals.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deals = action.payload.deals;
      state.deals_pages = action.payload.total_pages;
    });

    builder.addCase(API.fetchDeals.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchContragents.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchContragents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.contragents = action.payload;
    });

    builder.addCase(API.fetchContragents.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchComments.pending, state => {
      state.isLoading = true;
      state.comments = [];
      state.error = null;
    });

    builder.addCase(API.fetchComments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.comments = action.payload;
    });

    builder.addCase(API.fetchComments.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchFollowers.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchFollowers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.followers = action.payload;
    });

    builder.addCase(API.fetchFollowers.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchFollowing.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchFollowing.fulfilled, (state, action) => {
      state.isLoading = false;
      state.following = action.payload;
    });

    builder.addCase(API.fetchFollowing.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.followUser.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.followUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.following.push(action.payload);
    });

    builder.addCase(API.followUser.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.unFollowUser.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.unFollowUser.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.following.findIndex(el => el.id === action.payload);

      state.following.splice(index, 1);
    });

    builder.addCase(API.unFollowUser.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchWalletAddresses.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchWalletAddresses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.usdt_wallet_addresses = action.payload;
    });

    builder.addCase(API.fetchWalletAddresses.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchTraderDeals.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchTraderDeals.fulfilled, (state, action) => {
      state.isLoading = false;
      state.trader_deals = action.payload.deals;
      // state.trader_deals_pages = action.payload
    });

    builder.addCase(API.fetchTraderDeals.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const profileMarketplaceReducer = profileSlice.reducer;

export default profileMarketplaceReducer;
