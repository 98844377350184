import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const fetchRole = createAsyncThunk(
  'profileMarketplace/fetchRole',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/profile/role');

      return response.data.role;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchUserId = createAsyncThunk(
  'profileMarketplace/fetchUserId',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/profile/user_id');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchUsername = createAsyncThunk(
  'profileMarketplace/fetchUsername',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/profile/username');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchBalance = createAsyncThunk(
  'profileMarketplace/fetchTotalBalance',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/finance/total_balance');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const widthdrawBalance = createAsyncThunk(
  'profileMarketplace/widthdraw',
  async (data, { rejectWithValue }) => {
    try {
      await axios.post('/finance/withdraw', data);

      toast.success('withdrawal request has been sent');
    } catch (error) {
      toast.error('You have entered incorrect address');

      return rejectWithValue(error.message);
    }
  }
);

const withdrawBalanceWeb3 = createAsyncThunk(
  'profileMarketplace/withdrawBalanceWeb3',
  async (data, { rejectWithValue }) => {
    try {
      await axios.post('/erc_api/withdraw', data);

      toast.success('withdrawal request has been sent');
    } catch (error) {
      toast.error('You have entered incorrect address');

      return rejectWithValue(error.message);
    }
  }
);

const fetchStatistic = createAsyncThunk(
  'profileMarketplace/fetchStatistic',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/profile');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDealsInfo = createAsyncThunk(
  'profileMarketplace/fetchDealsInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/profile/order_info');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchOrders = createAsyncThunk(
  'profileMarketplace/fetchOrders',
  async (_, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(document.location.search);
      const page = searchParams.get('page');
      const per_page = searchParams.get('page_size');
      const response = await axios.get(
        `/profile/orders?page=${page}&page_size=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const setOrderToOffline = createAsyncThunk(
  'profileMarketplace/setOrderToOffline',
  async (order_id, { rejectWithValue }) => {
    try {
      const data = { order_id };

      await axios.patch('/orders/set_order_offline', data);

      return order_id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const setOrderToOnline = createAsyncThunk(
  'profileMarketplace/setOrderToOnline',
  async (order_id, { rejectWithValue }) => {
    try {
      const data = { order_id };

      await axios.patch('/orders/set_order_online', data);

      return order_id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const cancelOrder = createAsyncThunk(
  'profileMarketplace/cancelOrder',
  async (order_id, { rejectWithValue }) => {
    try {
      const data = { order_id };

      await axios.patch('/orders/cancell', data);

      toast.success('Order has been closed');

      return order_id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateOrderLimits = createAsyncThunk(
  'profileMarketplace/updateOrderLimits',
  async (data, { rejectWithValue }) => {
    try {
      await axios.put('/orders/update_order_limits', data);

      toast.success('Order limits have been updated');

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDeals = createAsyncThunk(
  'profileMarketplace/fetchDeals',
  async (_, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(document.location.search);
      const page = searchParams.get('page');
      const per_page = searchParams.get('page_size');

      const response = await axios.get(
        `/orders/deals?page=${page}&page_size=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchContragents = createAsyncThunk(
  'profileMarketplace/fetchContragents',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/contragents');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchComments = createAsyncThunk(
  'profileMarketplace/fetchComments',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/comments');

      return response.data.comments.comments;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchFollowers = createAsyncThunk(
  'profileMarketplace/fetchFollowers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/followers/followers');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchFollowing = createAsyncThunk(
  'profileMarketplace/fetchFollowing',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/followers/following');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const followUser = createAsyncThunk(
  'profileMarketplace/followUser',
  async (id, { rejectWithValue }) => {
    try {
      const data = { followed_id: id };

      const response = await axios.post('/followers/follow', data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const unFollowUser = createAsyncThunk(
  'profileMarketplace/unFollowUser',
  async (id, { rejectWithValue }) => {
    try {
      const data = { followed_id: id };

      await axios.delete('/followers/unfollow', { data });

      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchWalletAddresses = createAsyncThunk(
  'profileMarketplace/fetchWalletAddresses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/finance');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTraderDeals = createAsyncThunk(
  'profileMarketplace/fetchTraderDeals',
  async (_, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(document.location.search);
      const page = searchParams.get('page');
      const per_page = searchParams.get('page_size');
      const response = await axios.get(
        `/profile/trader/deals?page=${page}&page_size=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = {
  fetchRole,
  fetchUserId,
  fetchUsername,
  fetchBalance,
  widthdrawBalance,
  withdrawBalanceWeb3,
  fetchStatistic,
  fetchDealsInfo,
  fetchOrders,
  setOrderToOffline,
  setOrderToOnline,
  cancelOrder,
  updateOrderLimits,
  fetchDeals,
  fetchContragents,
  fetchComments,
  fetchFollowers,
  fetchFollowing,
  followUser,
  unFollowUser,
  fetchWalletAddresses,
  fetchTraderDeals,
};

export default API;
