import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledItem = styled.li`
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;

  background: var(--background-nt-list-item-color);
  border: 1px solid var(--border-nt-color);
  border-radius: 17px;

  ${mq.tablet} {
    width: 720px;
    padding: 13px 16px 13px 28px;
    border-radius: 21px;
  }

  ${mq.desktop} {
    width: 100%;
    min-height: 67px;
  }

  &:not(:last-child) {
    margin-bottom: 7px;

    ${mq.tablet} {
      margin-bottom: 14px;
    }
  }
`;

export const StyledWrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCopyBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
`;

export const StyledText = styled.p`
  display: ${({ t }) => {
    if (t === 'hidden') {
      return 'none';
    }

    return 'inline';
  }};
  min-width: ${({ mw_m }) => (mw_m ? `${mw_m}px` : '')};
  margin-right: ${({ mg_r }) => (mg_r ? `${mg_r}px` : '')};

  font-weight: 500;
  line-height: 150%;
  text-transform: ${({ tr }) => (tr ? tr : '')};

  color: var(--text-primary-color);

  ${mq.tablet} {
    display: block;
    min-width: ${({ mw_t }) => `${mw_t}px`};
  }

  ${mq.desktop} {
    min-width: ${({ mw_d }) => `${mw_d}px`};
  }
`;

export const StyledSpan = styled.span`
  font-family: Roboto, sans-serif;
  font-size: ${({ fz }) => (fz ? `${fz}px` : ' ')};
`;
