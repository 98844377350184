import { useTranslation } from 'react-i18next';
import useApp from 'hooks/app/useApp';
import { useProfileMerchant } from 'hooks/merchant';
import { useProfileMarketplace } from 'hooks/marketplace';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledIconWrp,
  StyledText,
  StyledValue,
  StyledNavLink,
} from './Balance.styled';

const Balance = () => {
  const { t } = useTranslation();
  const { product } = useApp();
  const { role: roleMerchant } = useProfileMerchant();
  const { balance: balanceMarketplace, role: roleMarketplace } =
    useProfileMarketplace();

  const getBalance = () => {
    if (
      product === 'marketplace' &&
      (roleMarketplace === 'user' || roleMarketplace === 'trader')
    ) {
      return balanceMarketplace;
    }

    if (
      product === 'marketplace' &&
      (roleMarketplace === 'exchanger_admin' ||
        roleMarketplace === 'operator' ||
        roleMarketplace === 'admin')
    ) {
      return 0;
    }
  };

  const balance = getBalance();

  return (
    <StyledWrp>
      <StyledIconWrp>
        <Svg w_m="21.5" h_m="21.5" c="secondary">
          <use href={`${sprite}#dollar`}></use>
        </Svg>
      </StyledIconWrp>

      <div>
        <StyledText>{t('header.balance')}</StyledText>

        {product === 'exchanger' &&
          (roleMerchant === 'user' || roleMerchant === 'trader') && (
            <StyledNavLink to="/exchanger/profile">view</StyledNavLink>
          )}

        {product === 'exchanger' && roleMerchant === 'admin' && (
          <StyledValue>$0</StyledValue>
        )}

        {product === 'marketplace' && <StyledValue>${balance}</StyledValue>}
      </div>
    </StyledWrp>
  );
};

export default Balance;
