import { useSelector } from 'react-redux';
import select from '../../redux/marketplace/operator/selectors';

const useOperator = () => {
  const requests = useSelector(select.requests);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return { requests, isLoading, error };
};

export default useOperator;
