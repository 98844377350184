import { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { AnimatePresence } from 'framer-motion';
import { useProfileMerchant } from 'hooks/merchant';
import { Svg } from 'components/common';
// import {  Modal } from 'components/common';
// import ProfileBecomeTraiderForm from 'components/merchant/ProfileBecomeTraiderForm';
// import ProfileTelegramForm from 'components/merchant/ProfileTelegramForm';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledIconWrp,
  StyledTextPrimary,
  // StyledBtn,
} from './ProfileUsername.styled';

const ProfileUsername = () => {
  // const [isModalBecomeTraiderOpen, setIsModalBecomeTraiderOpen] =
  useState(false);
  // const [isModalTelegramOpen, setIsTelegramOpen] = useState(false);

  // const { t } = useTranslation();
  const { username } = useProfileMerchant();

  // const handleModalBecomeTraiderToggle = () => {
  //   setIsModalBecomeTraiderOpen(currentState => !currentState);
  // };

  // const handleModalTelegramToggle = () => {
  //   setIsTelegramOpen(currentState => !currentState);
  // };

  return (
    <>
      <StyledWrp>
        <StyledIconWrp>
          <Svg w_m="28" h_m="28" w_t="34" h_t="34" c="secondary">
            <use href={`${sprite}#login`}></use>
          </Svg>
        </StyledIconWrp>

        <StyledTextPrimary>{username}</StyledTextPrimary>
        {/* {!trader ? (
              <StyledBtn type="button" onClick={handleModalBecomeTraiderToggle}>
                {t('profileBecomeTraider')}
              </StyledBtn>
            ) : (
              <StyledBtn type="button">{t('profileTraider')}</StyledBtn>
            )}

            <StyledBtn type="button" onClick={handleModalTelegramToggle}>
              {t('profileTelegramBot')}
            </StyledBtn> */}
      </StyledWrp>

      {/* <AnimatePresence initial={false} onExitComplete={() => null}>
        {isModalBecomeTraiderOpen && (
          <Modal toggle={handleModalBecomeTraiderToggle}>
            <ProfileBecomeTraiderForm toggle={handleModalBecomeTraiderToggle} />
          </Modal>
        )}

        {isModalTelegramOpen && (
          <Modal toggle={handleModalTelegramToggle}>
            <ProfileTelegramForm toggle={handleModalTelegramToggle} />
          </Modal>
        )}
      </AnimatePresence> */}
    </>
  );
};

export default ProfileUsername;
