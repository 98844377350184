import { useSelector } from 'react-redux';
import select from '../../redux/marketplace/exchangerAdmin/selectors';

const useExchangerAdmin = () => {
  const statistic = useSelector(select.statistic);
  const deals = useSelector(select.deals);
  const traders = useSelector(select.traders);
  const rate = useSelector(select.rate);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return { statistic, deals, traders, rate, isLoading, error };
};

export default useExchangerAdmin;
