import { useSelector } from 'react-redux';
import select from '../../redux/marketplace/bank/selectors';

const useBank = () => {
  const banks = useSelector(select.banks);
  const bank_cards = useSelector(select.bank_cards);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    banks,
    bank_cards,
    isLoading,
    error,
  };
};

export default useBank;
