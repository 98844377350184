import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { default as API_AUTH_MERCHANT } from '../../../redux/merchant/auth/operations';
import { default as API_AUTH_MARKETPLACE } from '../../../redux/marketplace/auth/operations';
import useApp from 'hooks/app/useApp';
import { registerSchema } from 'schemas';
import { Svg, ErrorMessage } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledForm,
  StyledTopWrp,
  StyledTitle,
  StyledCrossBtn,
  StyledFieldWrp,
  StyledField,
  StyledSubmitBtn,
} from './AuthForms.styled';

const RegisterForm = ({ toggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { product } = useApp();

  return (
    <Formik
      validationSchema={registerSchema}
      initialValues={{
        login: '',
        email: '',
        password: '',
        passwordAgain: '',
      }}
      onSubmit={({ email, login, password, passwordAgain }, { resetForm }) => {
        if (!email || !login || !password || !passwordAgain) {
          toast.error(t('notificationFormEmptyFields'));
          return;
        }

        const userCreds = {
          username: login,
          email,
          password,
          is_active: true,
          is_superuser: false,
          is_verified: false,
        };

        if (product === 'exchanger') {
          dispatch(API_AUTH_MERCHANT.registerUser(userCreds));
        }

        if (product === 'marketplace') {
          dispatch(API_AUTH_MARKETPLACE.registerUser(userCreds));
        }

        resetForm();
        toggle();
      }}
    >
      {({ handleChange, values, errors }) => {
        return (
          <StyledForm>
            <StyledTopWrp>
              <StyledTitle>{t('registerForm.title')}</StyledTitle>
              <StyledCrossBtn type="button" onClick={toggle}>
                <Svg w_m="15.5" h_m="15.5" c="secondary">
                  <use href={`${sprite}#cross`}></use>
                </Svg>

                <p className="hidden">close</p>
              </StyledCrossBtn>
            </StyledTopWrp>

            <StyledFieldWrp>
              <StyledField
                name="login"
                type="text"
                value={values.login}
                placeholder={t('registerForm.login')}
                onChange={handleChange('login')}
              />

              <Svg p="a" t="30" l="8" w_m="20" h_m="20" c="secondary">
                <use href={`${sprite}#login`}></use>
              </Svg>
            </StyledFieldWrp>

            {errors.login && <ErrorMessage>{errors.login}</ErrorMessage>}

            <StyledFieldWrp>
              <StyledField
                name="email"
                type="text"
                value={values.email}
                placeholder={t('registerForm.email')}
                onChange={handleChange('email')}
              />

              <Svg p="a" t="30" l="8" w_m="20" h_m="20" c="secondary">
                <use href={`${sprite}#email`}></use>
              </Svg>
            </StyledFieldWrp>

            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}

            <StyledFieldWrp>
              <StyledField
                name="password"
                type="text"
                value={values.password}
                placeholder={t('registerForm.password')}
                autoComplete="off"
                onChange={handleChange('password')}
              />

              <Svg p="a" t="30" l="8" w_m="20" h_m="20" c="secondary">
                <use href={`${sprite}#password`}></use>
              </Svg>
            </StyledFieldWrp>

            {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}

            <StyledFieldWrp>
              <StyledField
                name="passwordAgain"
                type="text"
                value={values.passwordAgain}
                placeholder={t('registerForm.passwordAgain')}
                autoComplete="off"
                onChange={handleChange('passwordAgain')}
              />

              <Svg p="a" t="30" l="8" w_m="20" h_m="20" c="secondary">
                <use href={`${sprite}#password`}></use>
              </Svg>
            </StyledFieldWrp>

            {errors.passwordAgain && (
              <ErrorMessage>{errors.passwordAgain}</ErrorMessage>
            )}

            <StyledSubmitBtn type="submit">
              {t('registerForm.button')}
            </StyledSubmitBtn>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
