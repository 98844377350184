import styled from '@emotion/styled';

export const StyledList = styled.ul`
  display: flex;
  align-self: center;
  align-items: top;
`;

export const StyledItem = styled.li`
  padding: 10px 18px 8px;

  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 18px;
  user-select: none;
  cursor: pointer;

  color: var(--text-primary-color);
  border: 1px solid transparent;
  border-radius: 4px;

  :not(:last-child) {
    margin-right: 2px;
  }
`;
