import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const fetchStatistic = createAsyncThunk(
  'exchangerAdminMarketplace/fetchStatistic',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/admin_exchanger/stats');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDeals = createAsyncThunk(
  'exchangerAdminMarketplace/fetchDeals',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/admin_exchanger/intermediate_deals');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTraders = createAsyncThunk(
  'exchangerAdminMarketplace/fetchTraders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/admin_exchanger/traders');

      return response.data.traders;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const addTrader = createAsyncThunk(
  'exchangerAdminMarketplace/AddTrader',
  async (email, { rejectWithValue }) => {
    try {
      const data = {
        email,
      };

      const response = await axios.post('/admin_exchanger/trader', data);

      toast.success('Трейдер добавлен');

      return response.data.trader;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const removeTrader = createAsyncThunk(
  'exchangerAdminMarketplace/removeTrader',
  async (id, { rejectWithValue }) => {
    try {
      const data = { trader_id: id };

      await axios.delete('/admin_exchanger/trader', { data });

      toast.success('Трейдер удален');

      return id;
    } catch (error) {
      toast.error('Error');

      return rejectWithValue(error.message);
    }
  }
);

const fetchExchangerRate = createAsyncThunk(
  'exchangerAdminMarketplace/fetchExchangerRate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/admin_exchanger/rate/1');

      return response.data.rate;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const createExchangerRate = createAsyncThunk(
  'exchangerAdminMarketplace/createExchangerRate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/admin_exchanger/rate', data);

      return response.data.created_rate;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const changeExchangerRate = createAsyncThunk(
  'exchangerAdminMarketplace/changeExchangerRate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/admin_exchanger/rate', data);

      return response.data.updated_rate;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = {
  fetchStatistic,
  fetchDeals,
  fetchTraders,
  addTrader,
  removeTrader,
  fetchExchangerRate,
  createExchangerRate,
  changeExchangerRate,
};

export default API;
