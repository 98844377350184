import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledWrp = styled.div`
  display: flex;
  align-items: center;
  padding: 22px 20px 34px 17px;

  background: var(--background-profile-cards-color);
  border-radius: 31px;

  ${mq.tablet} {
    padding: 29px 0px 29px 40px;
  }

  ${mq.desktop} {
    min-width: 277px;
    height: 114px;
    ${'' /* padding: 27px 11px 8px 33px; */}
  }
`;

export const StyledIconWrp = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  margin-right: 20px;

  background: var(--button-primary-text-color);
  border-radius: 50%;
`;

export const StyledTextPrimary = styled.p`
  margin-bottom: 10px;

  font-size: 21px;
  font-weight: 600;

  color: var(--text-primary-color);
`;

export const StyledBtn = styled.button`
  display: block;
  margin-bottom: 6px;

  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;

  color: var(--text-secondary-color);
  background: transparent;
  border: none;
`;
