import { useSelector } from 'react-redux';
import select from '../../redux/merchant/transactions/selectors';

const useTransactions = () => {
  const transactions = useSelector(select.transactions);
  const total_pages = useSelector(select.total_pages);
  const transaction = useSelector(select.transaction);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    transactions,
    total_pages,
    transaction,
    isLoading,
    error,
  };
};

export default useTransactions;
