import { useDispatch } from 'react-redux';
import { Svg } from 'components/common';
import { default as API_ADMIN_MERCHANT } from '../../../redux/merchant/admin/operations';
import sprite from 'assets/images/sprite.svg';
import {
  StyledFormWrp,
  StyledFormTopWrp,
  StyledCrossBtn,
  StyledTitle,
  StyledText,
  StyledBtnGroup,
  StyledBtn,
} from './AdminTraderForms.styled';

const AdminTraderDeleteModal = ({ toggle, trader_id }) => {
  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(API_ADMIN_MERCHANT.removeTrader(trader_id));

    toggle();
  };

  return (
    <StyledFormWrp>
      <StyledFormTopWrp mg_b="20">
        <StyledCrossBtn type="button" onClick={toggle}>
          <Svg w_m="18" h_m="18" c="secondary">
            <use href={`${sprite}#cross`}></use>
          </Svg>

          <p className="hidden">cross</p>
        </StyledCrossBtn>
      </StyledFormTopWrp>

      <StyledTitle mg_b="20">
        Вы уверены, что хотите удалить трейдера?
      </StyledTitle>

      <StyledText mg_b="40">
        Удалив этого трейдера, вы навсегда удалите его информацию из своего
        аккаунта. Подтвердите, чтобы продолжить, или отмените, чтобы вернуться
        назад.
      </StyledText>

      <StyledBtnGroup>
        <StyledBtn type="button" onClick={toggle} c="secondary">
          Отмена
        </StyledBtn>

        <StyledBtn type="submit" onClick={handleSubmit} c="primary">
          Удалить
        </StyledBtn>
      </StyledBtnGroup>
    </StyledFormWrp>
  );
};

export default AdminTraderDeleteModal;
