import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledWrp = styled.div`
  display: flex;
  min-width: 175px;
  padding: 22px 10px 26px 22px;

  background: var(--background-profile-cards-color);
  border-radius: 31px;

  ${mq.tablet} {
    min-width: 230px;
    padding: 35px 20px 43px 40px;
  }

  ${mq.desktop} {
    min-width: 277px;
    height: 114px;
    padding: 22px 22px 28px 50px;
  }
`;

export const StyledIconWrp = styled.div`
  padding-top: 4px;
`;

export const StyledBtn = styled.button`
  margin-bottom: 10px;

  font-family: Roboto, sans-serif;
  font-size: 31px;
  font-weight: 600;

  color: var(--text-primary-color);
  background: transparent;
  border: none;
`;

export const StyledText = styled.p`
  font-size: 14px;
  font-weight: 600;

  color: var(--text-secondary-color);
`;
