import { persistReducer } from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import storage from 'redux-persist/lib/storage';
import transcationsReducer from './slice';

const persistConfig = {
  key: 'merchant-transaction',
  storage,
  whitelist: ['transaction'],

  transforms: [
    expireReducer('transaction', {
      persistedAtKey: 'transaction',
      expireSeconds: 3600,
      expiredState: {},
      autoExpire: true,
    }),
  ],
};

const persistedMerchantTransactionsReducers = persistReducer(
  persistConfig,

  transcationsReducer
);

export default persistedMerchantTransactionsReducers;
