import { useSelector } from 'react-redux';
import select from '../../redux/marketplace/publicProfile/selectors';

const useProfile = () => {
  const statistic = useSelector(select.statistic);
  const followers = useSelector(select.followers);
  const comments = useSelector(select.comments);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    statistic,
    followers,
    comments,
    isLoading,
    error,
  };
};

export default useProfile;
