import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from 'i18n';
import { toast } from 'react-toastify';

const fetchRole = createAsyncThunk(
  'userMerchant/fetchRole',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user_profile/role');
      return response.data.role;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchUsername = createAsyncThunk(
  'userMerchant/fetchUsername',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user_profile/username');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchBalance = createAsyncThunk(
  'userMerchant/fetchBalance',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/blockchain/balances');

      return response.data.balances;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchStatistics = createAsyncThunk(
  'userMerchant/fetchStatistics',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user_profile/statistics');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchWalletAddresses = createAsyncThunk(
  'userMerchant/fetchWalletAddresses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user_profile/address/');

      return response.data.wallet_addresses;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTraderOnlineOfflineStatus = createAsyncThunk(
  'userMerchant/fetchTraderOnlineOfflineStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/traders/status');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const changeTraderStatusToOnline = createAsyncThunk(
  'userMerchant/changeTraderStatusToOnline',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/traders/online');

      toast.success(i18n.t('notificationChangeTraderStatusToOnline.success'));

      return response.data;
    } catch (error) {
      toast.error(i18n.t('notificationChangeTraderStatusToOnline.error'));

      return rejectWithValue(error.message);
    }
  }
);

const changeTraderStatusToOffline = createAsyncThunk(
  'userMerchant/changeTraderStatusToOffline',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/traders/offline');

      toast.success(i18n.t('notificationChangeTraderStatusToOffline.success'));
      return response.data;
    } catch (error) {
      toast.error(i18n.t('notificationChangeTraderStatusToOffline.error'));

      return rejectWithValue(error.message);
    }
  }
);

const API = {
  fetchRole,
  fetchUsername,
  fetchBalance,
  fetchStatistics,
  fetchWalletAddresses,
  fetchTraderOnlineOfflineStatus,
  changeTraderStatusToOnline,
  changeTraderStatusToOffline,
};

export default API;
