import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { default as API_ADMIN_MERCHANT } from '../../../redux/merchant/admin/operations';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledForm,
  StyledFormTopWrp,
  StyledCrossBtn,
  StyledTitle,
  StyledText,
  StyledFieldWrp,
  StyledField,
  StyledBtnGroup,
  StyledBtn,
} from './AdminTraderForms.styled';

const AdminTraderAddForm = ({ toggle, handleSuccessModalToggle }) => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={({ email }, { resetForm }) => {
        const data = { email };

        dispatch(API_ADMIN_MERCHANT.createTrader(data))
          .then(data => {
            if (data?.error) {
              throw new Error();
            }

            handleSuccessModalToggle();
          })
          .catch(() => {
            toast.error(
              'Ошибка, проверь правильность ввода адреса электронной почты'
            );
          })
          .finally(() => {
            toggle();
          });

        resetForm();
      }}
    >
      {({ handleChange, values }) => {
        return (
          <StyledForm>
            <StyledFormTopWrp mg_b="20">
              <StyledCrossBtn type="button" onClick={toggle}>
                <Svg w_m="18" h_m="18" c="secondary">
                  <use href={`${sprite}#cross`}></use>
                </Svg>

                <p className="hidden">cross</p>
              </StyledCrossBtn>
            </StyledFormTopWrp>

            <StyledTitle mg_b="20">
              Расширяйте сеть и приглашайте трейдеров
            </StyledTitle>

            <StyledText mg_b="46">
              Начните создавать сообщество и повышать торговый опыт. Введите
              адрес электронной почты ниже, чтобы отправить приглашение. Нажмите
              "Пригласить", чтобы начать.
            </StyledText>

            <StyledFieldWrp>
              <StyledField
                type="text"
                onChange={handleChange('email')}
                value={values.email}
                placeholder="Введите адрес электронной почты"
              />

              <Svg p="a" t="34" l="2" w_m="20" h_m="20" c="secondary">
                <use href={`${sprite}#email`}></use>
              </Svg>
            </StyledFieldWrp>

            <StyledBtnGroup>
              <StyledBtn type="button" onClick={toggle} c="secondary">
                Отмена
              </StyledBtn>

              <StyledBtn type="submit" c="primary">
                Пригласить
              </StyledBtn>
            </StyledBtnGroup>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default AdminTraderAddForm;
