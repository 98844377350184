const total_user_count = state => state.adminMarketplace.total_user_count;
const user_count_per_time = state => state.adminMarketplace.user_count_per_time;
const total_deposit = state => state.adminMarketplace.total_deposit;
const withdraw_amount_per_time = state =>
  state.adminMarketplace.withdraw_amount_per_time;
const order_count_per_time = state =>
  state.adminMarketplace.order_count_per_time;
const order_amount_per_time = state =>
  state.adminMarketplace.order_amount_per_time;
const deal_count_per_time = state => state.adminMarketplace.deal_count_per_time;
const deal_amount_per_time = state =>
  state.adminMarketplace.deal_amount_per_time;
const operators = state => state.adminMarketplace.operators;
const operators_pages = state => state.adminMarketplace.operators_pages;
const exchangers = state => state.adminMarketplace.exchangers;
const exchangers_pages = state => state.adminMarketplace.exchangers_pages;
const transactions = state => state.adminMarketplace.transactions;
const transactions_pages = state => state.adminMarketplace.transactions_pages;
const isLoading = state => state.adminMarketplace.isLoading;
const error = state => state.adminMarketplace.error;

const select = {
  total_user_count,
  user_count_per_time,
  total_deposit,
  withdraw_amount_per_time,
  order_count_per_time,
  order_amount_per_time,
  deal_count_per_time,
  deal_amount_per_time,
  operators,
  operators_pages,
  exchangers,
  exchangers_pages,
  transactions,
  transactions_pages,
  isLoading,
  error,
};

export default select;
