import { StyledContainer } from './Container.styled';

const Container = ({ children, style }) => {
  return (
    <div style={style}>
      <StyledContainer>{children}</StyledContainer>
    </div>
  );
};

export default Container;
