import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const fetchStatistic = createAsyncThunk(
  'publicProfileMarketplace/fetchStatistic',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public_profile/profile/${id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchFollowers = createAsyncThunk(
  'publicProfileMarketplace/fetchFollowers',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/public_profile/public_followers/${id}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchComments = createAsyncThunk(
  'publicProfileMarketplace/fetchComments',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public_profile/public_comments/${id}`);

      return response.data.comments.comments;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const createComment = createAsyncThunk(
  'publicProfileMarketplace/createComment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/comments/comments`, data);

      return response.data.comment;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const blockWithdraw = createAsyncThunk(
  'publicProfileMarketplace/blockWithdraw',
  async (user_id, { rejectWithValue }) => {
    try {
      const data = { user_id };

      await axios.patch(`/operators/block_withdraw`, data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const unblockWithdraw = createAsyncThunk(
  'publicProfileMarketplace/unBlockWithdraw',
  async (user_id, { rejectWithValue }) => {
    try {
      const data = { user_id };

      await axios.patch(`/operators/unblock_withdraw`, data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = {
  fetchStatistic,
  fetchFollowers,
  fetchComments,
  createComment,
  blockWithdraw,
  unblockWithdraw,
};

export default API;
