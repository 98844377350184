import styled from '@emotion/styled';

export const StyledWrp = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 20px;

  background: var(--background-secondary-color);
  border-radius: 21px;

  cursor: pointer;
`;

export const StyledContentWrp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledSpan = styled.span`
  position: absolute;

  width: 50px;
  height: 30px;

  background: #ffffff;
  border-radius: 21px;
`;
