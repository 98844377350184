import ProfileUSDTWalletListItem from 'components/merchant/ProfileUSDTWalletListItem';
import { StyledList } from './ProfileUSDTWalletList.styled';

const ProfileUSDTWalletList = ({ data }) => {
  return (
    <StyledList>
      {data.map((el, index) => (
        <ProfileUSDTWalletListItem key={index} data={el} />
      ))}
    </StyledList>
  );
};

export default ProfileUSDTWalletList;
