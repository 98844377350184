import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledList = styled.ul`
  display: inline-flex;
  padding: 5px 19px 5px 5px;

  background: var(--background-secondary-color);
  border-radius: 99px;

  ${mq.tablet} {
    padding: 5px 34px 5px 5px;
  }
`;

export const StyledItem = styled.li`
  :first-of-type {
    margin-right: 11px;

    ${mq.tablet} {
      margin-right: 16.25px;
    }
  }
`;

export const StyledText = styled.span`
  @media screen and (max-width: 759px) {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    border: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  font-weight: 600;
`;

export const StyledActiveBtn = styled.button`
  display: flex;
  align-items: center;

  color: #0c0716;
  background: #ffffff;
  border: none;
  border-radius: 99px;

  padding: 14.75px;

  ${mq.tablet} {
    padding: 14.25px 25px 15.25px 21.25px;
  }
`;

export const StyledBtn = styled.button`
  display: flex;
  align-items: center;

  color: var(--text-primary-color);
  background: transparent;
  border: none;

  padding: 14.75px 0;

  ${mq.tablet} {
    padding: 14.25px 0 15.25px 0;
  }
`;
