import AdminTraderListItem from 'components/merchant/AdminTraderListItem';

const AdminTraderList = ({ data }) => {
  return (
    <ul>
      {data.map(el => (
        <AdminTraderListItem key={el.trader_id} data={el} />
      ))}
    </ul>
  );
};

export default AdminTraderList;
