import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const userSlice = createSlice({
  name: 'userMerchant',
  initialState: {
    role: null,
    isLoadingRole: false,
    username: null,
    balance: [],
    statistics: null,
    is_trader_online: null,
    wallet_addresses: [],
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.role = null;
      state.is_trader_online = null;
      state.username = null;
      state.balance = [];
      state.statistics = null;
      state.wallet_addresses = [];
    });

    builder.addCase(API.fetchRole.pending, state => {
      state.isLoadingRole = true;
      state.error = null;
    });

    builder.addCase(API.fetchRole.fulfilled, (state, action) => {
      state.isLoadingRole = false;
      state.role = action.payload;
    });

    builder.addCase(API.fetchRole.rejected, state => {
      state.isLoadingRole = false;
      state.error = true;
    });

    builder.addCase(API.fetchUsername.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchUsername.fulfilled, (state, action) => {
      state.isLoading = false;
      state.username = action.payload.username;
    });

    builder.addCase(API.fetchUsername.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchBalance.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      state.balance = action.payload;
    });

    builder.addCase(API.fetchBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchStatistics.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchStatistics.fulfilled, (state, action) => {
      state.isLoading = false;
      state.statistics = action.payload;
    });

    builder.addCase(API.fetchStatistics.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchWalletAddresses.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchWalletAddresses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.wallet_addresses = action.payload;
    });

    builder.addCase(API.fetchWalletAddresses.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchTraderOnlineOfflineStatus.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(
      API.fetchTraderOnlineOfflineStatus.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.is_trader_online = action.payload.is_online;
      }
    );

    builder.addCase(API.fetchTraderOnlineOfflineStatus.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.changeTraderStatusToOnline.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(
      API.changeTraderStatusToOnline.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.is_trader_online = true;
      }
    );

    builder.addCase(API.changeTraderStatusToOnline.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.changeTraderStatusToOffline.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(
      API.changeTraderStatusToOffline.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.is_trader_online = false;
      }
    );

    builder.addCase(API.changeTraderStatusToOffline.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const userReducer = userSlice.reducer;

export default userReducer;
