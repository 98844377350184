import { useSelector } from 'react-redux';
import select from '../../redux/marketplace/orders/selectors';

const useOrders = () => {
  const orders = useSelector(select.orders);
  const pages = useSelector(select.pages);
  const deal = useSelector(select.deal);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    orders,
    pages,
    deal,
    isLoading,
    error,
  };
};

export default useOrders;
