const formatCardNumber = data => {
  const cardNumber = data.split('');

  cardNumber.splice(4, 0, ' ');
  cardNumber.splice(9, 0, ' ');
  cardNumber.splice(14, 0, ' ');

  return cardNumber.join('');
};

export default formatCardNumber;
