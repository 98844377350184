import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const swapSlice = createSlice({
  name: 'swap',
  initialState: {
    account: null,
    networkChainId: null,
    network_list: [
      {
        id: 1,
        name: 'ethereum',
        token: 'eth',
        chainId: 101,
        balance: null,
      },
      {
        id: 2,
        name: 'ethereum',
        token: 'usdt',
        balance: null,
      },

      // {
      //   id: 3,
      //   name: 'polygon',
      //   // token: 'matic',
      //   chainId: 109,
      //   balance: null,
      // },
      {
        id: 4,
        name: 'arbitrum',
        token: 'eth',
        chainId: 110,
        balance: null,
      },
      {
        id: 5,
        name: 'arbitrum',
        token: 'usdt',
        balance: null,
      },
      // {
      //   id: 6,
      //   name: 'avalanche',
      //   // token: 'avax',
      //   chainId: 106,
      //   balance: null,
      // },
      {
        id: 7,
        name: 'bsc',
        token: 'bnb',
        chainId: 102,
        balance: null,
      },
      {
        id: 8,
        name: 'bsc',
        token: 'usdt',
        balance: null,
      },
      // {
      //   id: 9,
      //   name: 'mantle',
      //   // token: 'usdt',
      //   chainId: 181,
      //   balance: null,
      // },
      {
        id: 10,
        name: 'optimism',
        token: 'eth',
        chainId: 111,
        balance: null,
      },
      {
        id: 11,
        name: 'optimism',
        token: 'usdt',
        balance: null,
      },
    ],
    swap: {
      from: '',
      to: '',
      token: '',
      amount: '',
    },
    hash: null,
    isLoading: null,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.account = null;
      state.network_list = [
        {
          id: 1,
          name: 'ethereum',
          token: 'eth',
          chainId: 101,
          balance: null,
        },
        {
          id: 2,
          name: 'ethereum',
          token: 'usdt',
          chainId: 101,
          balance: null,
        },

        // {
        //   id: 3,
        //   name: 'polygon',
        //   // token: 'matic',
        //   chainId: 109,
        //   balance: null,
        // },
        {
          id: 4,
          name: 'arbitrum',
          token: 'eth',
          chainId: 110,
          balance: null,
        },
        {
          id: 5,
          name: 'arbitrum',
          token: 'usdt',
          chainId: 110,
          balance: null,
        },
        // {
        //   id: 6,
        //   name: 'avalanche',
        //   // token: 'avax',
        //   chainId: 106,
        //   balance: null,
        // },
        {
          id: 7,
          name: 'bsc',
          token: 'bnb',
          chainId: 102,
          balance: null,
        },
        {
          id: 8,
          name: 'bsc',
          token: 'usdt',
          chainId: 102,
          balance: null,
        },
        // {
        //   id: 9,
        //   name: 'mantle',
        //   // token: 'usdt',
        //   chainId: 181,
        //   balance: null,
        // },
        {
          id: 10,
          name: 'optimism',
          token: 'eth',
          chainId: 111,
          balance: null,
        },
        {
          id: 11,
          name: 'optimism',
          token: 'usdt',
          chainId: 111,
          balance: null,
        },
      ];
      state.swap = {
        from: '',
        to: '',
        token: '',
        amount: '',
      };
      state.hash = null;
    });

    builder.addCase(ACTIONS.setSwapFrom, (state, action) => {
      state.swap.from = action.payload;
    });

    builder.addCase(ACTIONS.setSwapTo, (state, action) => {
      state.swap.to = action.payload;
    });

    builder.addCase(ACTIONS.setSwapToken, (state, action) => {
      state.swap.token = action.payload;
    });

    builder.addCase(ACTIONS.setSwapAmount, (state, action) => {
      state.swap.amount = action.payload;
    });

    builder.addCase(API.onConnectMetaMask.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.onConnectMetaMask.fulfilled, (state, action) => {
      state.isLoading = false;
      state.account = action.payload.account;
      state.networkChainId = action.payload.networkChainId;
    });

    builder.addCase(API.onConnectMetaMask.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getEthereumMainnetBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(
      API.getEthereumMainnetBalance.fulfilled,
      (state, action) => {
        state.isLoading = false;
        const index = state.network_list.findIndex(el => el.id === 1);
        state.network_list[index] = {
          ...state.network_list[index],
          ...action.payload,
        };
      }
    );

    builder.addCase(API.getEthereumMainnetBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getEthereumUsdtBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.getEthereumUsdtBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      const index = state.network_list.findIndex(el => el.id === 2);
      state.network_list[index] = {
        ...state.network_list[index],
        ...action.payload,
      };
    });

    builder.addCase(API.getEthereumUsdtBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getPolygonMainnetBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.getPolygonMainnetBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      const index = state.network_list.findIndex(el => el.id === 3);
      state.network_list[index] = {
        ...state.network_list[index],
        ...action.payload,
      };
    });

    builder.addCase(API.getPolygonMainnetBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getOptimismMainnetBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(
      API.getOptimismMainnetBalance.fulfilled,
      (state, action) => {
        state.isLoading = false;
        const index = state.network_list.findIndex(el => el.id === 10);
        state.network_list[index] = {
          ...state.network_list[index],
          ...action.payload,
        };
      }
    );

    builder.addCase(API.getOptimismMainnetBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getOptimismUsdtBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.getOptimismUsdtBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      const index = state.network_list.findIndex(el => el.id === 11);
      state.network_list[index] = {
        ...state.network_list[index],
        ...action.payload,
      };
    });

    builder.addCase(API.getOptimismUsdtBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getArbitrumMainnetBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(
      API.getArbitrumMainnetBalance.fulfilled,
      (state, action) => {
        state.isLoading = false;
        const index = state.network_list.findIndex(el => el.id === 4);
        state.network_list[index] = {
          ...state.network_list[index],
          ...action.payload,
        };
      }
    );

    builder.addCase(API.getArbitrumMainnetBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getArbitrumUsdtBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.getArbitrumUsdtBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      const index = state.network_list.findIndex(el => el.id === 5);
      state.network_list[index] = {
        ...state.network_list[index],
        ...action.payload,
      };
    });

    builder.addCase(API.getArbitrumUsdtBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getAvalancheMainnetBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(
      API.getAvalancheMainnetBalance.fulfilled,
      (state, action) => {
        state.isLoading = false;
        const index = state.network_list.findIndex(el => el.id === 6);
        state.network_list[index] = {
          ...state.network_list[index],
          ...action.payload,
        };
      }
    );

    builder.addCase(API.getAvalancheMainnetBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getBscMainnetBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.getBscMainnetBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      const index = state.network_list.findIndex(el => el.id === 7);
      state.network_list[index] = {
        ...state.network_list[index],
        ...action.payload,
      };
    });

    builder.addCase(API.getBscMainnetBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getBscUsdtBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.getBscUsdtBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      const index = state.network_list.findIndex(el => el.id === 8);
      state.network_list[index] = {
        ...state.network_list[index],
        ...action.payload,
      };
    });

    builder.addCase(API.getBscUsdtBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.getMantleMainnetBalance.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.getMantleMainnetBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      const index = state.network_list.findIndex(el => el.id === 9);
      state.network_list[index] = {
        ...state.network_list[index],
        ...action.payload,
      };
    });

    builder.addCase(API.getMantleMainnetBalance.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.swapOptimismEth.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.swapOptimismEth.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hash = action.payload;
    });

    builder.addCase(API.swapOptimismEth.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.swapArbitrumEth.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.swapArbitrumEth.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hash = action.payload;
    });

    builder.addCase(API.swapArbitrumEth.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.swapEthereumEth.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.swapEthereumEth.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hash = action.payload;
    });

    builder.addCase(API.swapEthereumEth.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.swapArbitrumUsdt.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.swapArbitrumUsdt.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hash = action.payload;
    });

    builder.addCase(API.swapArbitrumUsdt.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.swapErcUsdt.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.swapErcUsdt.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hash = action.payload;
    });

    builder.addCase(API.swapErcUsdt.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.swapAvaxUsdt.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.swapAvaxUsdt.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hash = action.payload;
    });

    builder.addCase(API.swapAvaxUsdt.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.swapPolygonUsdt.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.swapPolygonUsdt.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hash = action.payload;
    });

    builder.addCase(API.swapPolygonUsdt.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.swapBscUsdt.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(API.swapBscUsdt.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hash = action.payload;
    });

    builder.addCase(API.swapBscUsdt.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const swapReducer = swapSlice.reducer;

export default swapReducer;
