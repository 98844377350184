import { useSelector } from 'react-redux';
import select from '../../redux/marketplace/auth/selectors';

const useAuth = () => {
  const isLoggedInMarketplace = useSelector(select.isLoggedIn);
  const isLoadingMarketplace = useSelector(select.isLoading);
  const errorMarketplace = useSelector(select.error);

  return {
    isLoggedInMarketplace,
    isLoadingMarketplace,
    errorMarketplace,
  };
};

export default useAuth;
