import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledWrp = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px;

  background: var(--background-profile-nav-color);
  border-radius: 21px;
  border: 1px solid var(--border-bank-card-color);

  :not(:last-child) {
    margin-bottom: 20px;
  }

  ${mq.desktop} {
    padding: 5px 5px 5px 28px;
  }
`;

export const StyledArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding: 4px;
  width: 220px;

  border-radius: 16px;
  background: var(--background-bank-card-area-color);

  ${mq.tablet} {
    padding: 20px;
    width: 260px;
  }

  ${mq.desktop} {
    width: 380px;
  }
`;

export const StyledCopyBtn = styled.button`
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
`;

export const StyledText = styled.p`
  margin-right: ${({ mg_r }) => (mg_r ? `${mg_r}px` : '')};

  text-transform: ${({ tr }) => (tr ? tr : '')};

  color: ${({ c }) => {
    if (c === 'secondary') {
      return 'var(--text-secondary-color)';
    }

    return 'var(--text-primary-color)';
  }};
`;

export const StyledSpan = styled.span`
  font-family: Roboto, sans-serif;
`;
