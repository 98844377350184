import { createAction } from '@reduxjs/toolkit';

const setDefault = createAction('swap, setDefault');

const setSwapFrom = createAction('swap/setSwapFrom');

const setSwapTo = createAction('swap/setSwapTo');

const setSwapToken = createAction('swap/setSwapToken');

const setSwapAmount = createAction('swap/setSwapAmount');

const ACTIONS = {
  setDefault,
  setSwapFrom,
  setSwapTo,
  setSwapToken,
  setSwapAmount,
};

export default ACTIONS;
