import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const fetchWithdraws = createAsyncThunk(
  'userMerchant/fetchWithdraws',
  async (_, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(document.location.search);
      const page = searchParams.get('page');
      const per_page = searchParams.get('per_page');

      const response = await axios.get(
        `/withdraw/history?page=${page}&per_page=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const createWithdraw = createAsyncThunk(
  'userMerchant/createWithdraw',
  async (data, { rejectWithValue }) => {
    try {
      await axios.post('/withdraw', data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = { fetchWithdraws, createWithdraw };

export default API;
