import styled from '@emotion/styled';
import { Form, Field } from 'formik';

export const StyledForm = styled(Form)`
  width: 544px;
  padding: 40px;

  text-align: center;

  background: var(--background-secondary-color);
  border-radius: 18px;
`;

export const StyledFormWrp = styled.div`
  width: 544px;
  padding: 40px;

  text-align: center;

  background: var(--background-secondary-color);
  border-radius: 18px;
`;

export const StyledFormTopWrp = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};
`;

export const StyledCrossBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
`;

export const StyledTitleWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
`;

export const StyledTitle = styled.h2`
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};

  font-size: 20px;
  font-weight: 500;
  line-height: 120%;

  color: var(--text-primary-color);
`;

export const StyledText = styled.p`
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};

  font-size: 16px;
  line-height: 150%;

  color: var(--text-primary-color);
`;

export const StyledFieldWrp = styled.div`
  position: relative;

  margin-bottom: 40px;
`;

export const StyledField = styled(Field)`
  width: 100%;
  padding: 16px 36px;

  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;

  color: var(--text-primary-color);
  background: transparent;
  border: 1px solid #7f808d;
  border-radius: 18px;
  outline: none;

  ::placeholder {
    font-family: Raleway, sans-serif;
    color: var(--text-secondary-color);
  }
`;

export const StyledBtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledBtn = styled.button`
  width: 224px;
  padding: 16px;

  font-size: 16px;
  font-weight: 600;
  line-height: 120%;

  color: ${({ c }) => {
    if (c === 'primary') {
      return 'var(--button-primary-text-color)';
    }

    if (c === 'secondary') {
      return 'var(--text-primary-color)';
    }
  }};
  background: ${({ c }) => {
    if (c === 'primary') {
      return 'var(--accent-marketplace-green-color)';
    }

    if (c === 'secondary') {
      return 'transparent';
    }
  }};
  border-radius: 99px;
  border: 1px solid var(--accent-marketplace-green-color);
`;
