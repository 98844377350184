import React from 'react';
import ReactDOM from 'react-dom/client';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import { BrowserRouter } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import redux from './redux/store';

import 'react-toastify/dist/ReactToastify.css';

import { App } from 'App';
import { ToastNotification } from 'components/common';
import './index.css';
import './i18n';

// disableReactDevTools();

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={redux.store}>
      <PersistGate loading={null} persistor={redux.persistor}>
        <App />
        <ToastNotification />
      </PersistGate>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);
