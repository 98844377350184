const statistic = state => state.exchangerAdminMarketplace.statistic;
const deals = state => state.exchangerAdminMarketplace.deals;
const traders = state => state.exchangerAdminMarketplace.traders;
const rate = state => state.exchangerAdminMarketplace.rate;
const isLoading = state => state.exchangerAdminMarketplace.isLoading;
const error = state => state.exchangerAdminMarketplace.error;

const select = {
  statistic,
  deals,
  traders,
  rate,
  isLoading,
  error,
};

export default select;
