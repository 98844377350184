import ProfileTransactionListItem from 'components/merchant/ProfileTransactionListItem';
import { StyledList } from './ProfileTransactionList.styled';

const transactionItemVariants = {
  hidden: {
    x: 110,
    opacity: 0,
  },
  visible: custom => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.1, duration: 0.25 },
  }),
};

const ProfileTransactionList = ({ data }) => {
  return (
    <StyledList>
      {data.map((el, index) => {
        return (
          <ProfileTransactionListItem
            initial="hidden"
            animate="visible"
            variants={transactionItemVariants}
            custom={index}
            data={el}
            key={el.id}
          />
        );
      })}
    </StyledList>
  );
};

export default ProfileTransactionList;
