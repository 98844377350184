import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import API from '../../../redux/merchant/transactions/operations';
import { StyledWrp, StyledBtn } from './ProfileDownloadArchive.styled';

const ProfileDownloadArchive = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleBtnClick = () => {
    dispatch(API.fetchTransactionCsvFile('transactions.xls'));
  };

  return (
    <StyledWrp>
      <StyledBtn type="button" onClick={handleBtnClick}>
        {t('profileDownloadArchive')}
      </StyledBtn>
    </StyledWrp>
  );
};

export default ProfileDownloadArchive;
