import styled from '@emotion/styled';
import { Form, Field } from 'formik';
import { mq } from 'utils';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 26px 18px 46px;

  background: var(--background-form-color);
  border-radius: 21px;

  ${mq.tablet} {
    width: 514px;
    padding: 28px 28px 26px;
  }
`;

export const StyledFormTopWrp = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;

  color: var(--text-primary-color);
`;

export const StyledCrossBtn = styled.button`
  width: 24px;
  height: 24px;

  background: transparent;
  border: none;
`;

export const StyledFieldWrp = styled.div`
  position: relative;

  margin-bottom: 20px;
`;

export const StyledField = styled(Field)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 20px 28px;

  font-family: Roboto, sans-serif;
  font-weight: 600;

  color: var(--text-primary-color);
  background: var(--background-input-color);
  border: none;
  border-radius: 99px;
  outline: none;

  &::placeholder {
    font-weight: 600;

    color: var(--text-secondary-color);
  }
`;

// export const StyledMaxBtn = styled.button`
//   position: absolute;
//   top: 18%;
//   right: 2%;

//   padding: 10px 24px;

//   font-weight: 600;

//   color: var(--button-primary-text-color);
//   background: var(--button-primary-color);
//   border: none;
//   border-radius: 99px;
// `;

export const StyledFieldSelectWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 8px 16px;

  cursor: pointer;

  background: var(--background-input-color);
  border: none;
  border-radius: 99px;
`;

export const StyledTickBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 12px;

  background: var(--background-primary-color);
  border: none;
  border-radius: 8px;

  transition: transform 250ms;
`;

export const StyledIconWrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-right: 10px;

  background: var(--background-secondary-color);
  border-radius: 50%;
`;

export const StyledSubmitBtn = styled.button`
  padding: 18px 0;

  font-size: 16px;
  font-weight: 600;

  color: var(--button-primary-text-color);
  background: var(--button-primary-color);
  border: none;
  border-radius: 99px;
`;

export const StyledSelectWrp = styled.div`
  width: 390px;
  max-height: 400px;
  padding: 12px;

  background: var(--background-secondary-color);

  border-radius: 21px;
`;

export const StyledSelectListWrp = styled.div`
  max-height: 350px;
  overflow: auto;

  border-radius: 18px;
  border: 1px solid var(--text-primary-color);
`;

export const StyledSelectList = styled.ul``;

export const StyledSelectItem = styled.li`
  display: flex;
  align-items: center;
  padding: 6px 8px;

  cursor: pointer;

  border-radius: 12px;

  transition: background 250ms;

  &:hover {
    background: var(--background-primary-color);
  }
`;

export const StyledText = styled.p`
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};

  font-size: ${({ fz }) => (fz ? `${fz}px` : '')};
  font-weight: ${({ fw }) => (fw ? fw : '')};
  line-height: 120%;
  text-transform: ${({ tr }) => (tr ? tr : '')};

  color: ${({ c }) => {
    if (c === 'secondary') {
      return 'var(--text-secondary-color)';
    }

    return 'var(--text-primary-color)';
  }};
`;
