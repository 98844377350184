import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledWrp = styled.div`
  ${mq.desktop} {
    min-width: 277px;
    padding: 34px 48px;

    background: var(--background-profile-cards-color);
    border-radius: 31px;
  }
`;

export const StyledBtn = styled.button`
  width: 100%;
  padding: 14px 0px;

  font-weight: 500;

  color: var(--button-secondary-text-color);
  background: transparent;
  border: 1px solid var(--button-primary-color);
  border-radius: 99px;
`;
