import styled from '@emotion/styled';
import { mq } from 'utils';
import { Form, Field } from 'formik';

export const StyledForm = styled(Form)`
  ${mq.tablet} {
    margin: 0 auto;
  }

  ${mq.desktop} {
    display: flex;
  }
`;

export const StyledFormElementWrp = styled.div`
  width: 100%;

  ${mq.tablet} {
    width: 520px;
  }

  @media screen and (max-width: 1439px) {
    margin-bottom: ${({ mg_b_m }) => `${mg_b_m}px`};
  }
`;

export const StyledSwitchBtn = styled.div`
  display: none;

  ${mq.desktop} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 25px;
    padding: 0 32px;

    cursor: pointer;

    background: var(--button-merchant-switch-color);
    border: none;
    border-radius: 21px;
  }

  transition: transform 250ms;
`;

export const StyledTopWrp = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 24px 0;

  background: var(--background-secondary-color);
  border-radius: 21px;
`;

export const StyledContentWrp = styled.div`
  padding: 30px 28px 48px 28px;
  height: 74%;

  background: var(--background-secondary-color);
  border-radius: 21px;
  width: 100%;
`;

export const StyledContentTopWrp = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 4px;

  cursor: pointer;
`;

export const StyledIconWrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-right: 10px;

  background: var(--background-primary-color);
  border-radius: 50%;
`;

export const StyledFieldWrp = styled.div`
  padding: 18px 20px;
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};

  border-radius: 21px;
`;

export const StyledField = styled(Field)`
  width: 100%;
  padding: 18px;

  font-family: Roboto, sans-serif;
  font-size: 16px;

  color: var(--text-primary-color);
  background: var(--background-primary-color);
  border: none;
  outline: none;
  border-radius: 12px;

  &::placeholder {
    ${'' /* font-family: Raleway, sans-serif; */}

    color: var(--text-secondary-color);
  }
`;

export const StyledTickBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 12px;

  background: var(--background-primary-color);
  border: none;
  border-radius: 8px;

  transition: transform 250ms;
`;

export const StyledExchangeBtnWrp = styled.div`
  padding: 0px 20px;
`;

export const StyledExchangeBtn = styled.button`
  width: 100%;
  padding: 16px 0;

  font-weight: 700;

  color: var(--button-primary-text-color);
  background: var(--button-primary-color);
  border: none;
  border-radius: 12px;
`;

export const StyledSelectWrp = styled.div`
  width: 390px;
  max-height: 400px;
  padding: 12px;

  background: var(--background-secondary-color);

  border-radius: 21px;
`;

export const StyledSelectListWrp = styled.div`
  max-height: 350px;
  overflow: auto;

  border-radius: 18px;
  border: 1px solid var(--text-primary-color);
`;

export const StyledSelectList = styled.ul``;

export const StyledSelectItem = styled.li`
  display: flex;
  align-items: center;
  padding: 6px 8px;

  cursor: pointer;

  border-radius: 12px;

  transition: background 250ms;

  &:hover {
    background: var(--background-primary-color);
  }
`;

export const StyledText = styled.p`
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};

  font-size: ${({ fz }) => (fz ? `${fz}px` : '')};
  font-weight: ${({ fw }) => (fw ? fw : '')};
  line-height: 120%;
  text-transform: ${({ tr }) => (tr ? tr : '')};

  color: ${({ c }) => {
    if (c === 'secondary') {
      return 'var(--text-secondary-color)';
    }

    return 'var(--text-primary-color)';
  }};
`;
