import { useState, useEffect } from 'react';
import { PiSquaresFourThin } from 'react-icons/pi';
import { IoCloseOutline } from 'react-icons/io5';
import { useAuthMerchant } from 'hooks/merchant';
import { useAuthMarketplace } from 'hooks/marketplace';
import useApp from 'hooks/app/useApp';
import { Container } from 'components/common';
import { CreateTransactionActiveTransaction } from 'components/merchant';
import {
  Logo,
  LanguageSelector,
  Nav,
  ThemeSwitcher,
  Auth,
  Balance,
  Logout,
} from './HeaderParts';
import {
  StyledHeader,
  StyledMobileMenu,
  StyledMenuButton,
} from './Header.styled';

const Header = () => {
  const { screenWidth } = useApp();
  const { isLoggedInMerchant } = useAuthMerchant();
  const { isLoggedInMarketplace } = useAuthMarketplace();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState();

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(currentState => !currentState);
  };

  useEffect(() => {
    if (screenWidth < 1440) {
      setIsMobileMenuOpen(false);
      return;
    }

    setIsMobileMenuOpen(true);
  }, [screenWidth]);

  return (
    <Container>
      <StyledHeader>
        <Logo />

        <StyledMobileMenu
          animate={{
            opacity: isMobileMenuOpen ? 1 : 0,
            zIndex: isMobileMenuOpen ? 3 : -1,
          }}
          transition={{ duration: 0.25 }}
        >
          <LanguageSelector />

          <Nav />

          <ThemeSwitcher />
        </StyledMobileMenu>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isLoggedInMerchant || isLoggedInMarketplace ? (
            <>
              <Balance />

              <Logout />
            </>
          ) : (
            <Auth />
          )}

          <StyledMenuButton onClick={handleMobileMenuToggle}>
            {isMobileMenuOpen ? (
              <IoCloseOutline size={'34'} color="#333333" />
            ) : (
              <PiSquaresFourThin size={'34'} color="#333333" />
            )}
          </StyledMenuButton>
        </div>
      </StyledHeader>

      {isLoggedInMerchant && <CreateTransactionActiveTransaction />}
    </Container>
  );
};

export default Header;
