import range from 'lodash.range';

const getPaginationRange = (totalPages, page, limit, siblings) => {
  let totalPagesNumInArr = 4 + siblings;

  if (totalPagesNumInArr >= totalPages) {
    return range(1, totalPages + 1);
  }

  let leftSiblingsIndex = Math.max(page - siblings, 1);
  let rightSiblingsIndex = Math.min(page + siblings, totalPages);

  let showLeftDots = leftSiblingsIndex > 2;
  let showRightDots = rightSiblingsIndex < totalPages - 2;

  if (!showLeftDots && showRightDots) {
    let leftItemsCount = 3 + 2 * siblings;
    let leftRange = range(1, leftItemsCount + 1);

    return [...leftRange, ' ...', totalPages];
  } else if (showLeftDots && !showRightDots) {
    let rightItemsCount = 3 + 2 * siblings;
    let rightRange = range(totalPages - rightItemsCount + 1, totalPages + 1);

    return [1, '... ', ...rightRange];
  } else {
    let middleRange = range(leftSiblingsIndex, rightSiblingsIndex + 1);
    return [1, '... ', ...middleRange, ' ...', totalPages];
  }
};

export default getPaginationRange;
