import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const hashesSlice = createSlice({
  name: 'hashesMerchant',
  initialState: {
    hashes: [],
    total_pages: null,
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.hashes = [];
      state.total_pages = null;
    });

    builder.addCase(API.fetchHashes.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchHashes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hashes = action.payload.hashes;
      state.total_pages = action.payload.total_pages;
    });

    builder.addCase(API.fetchHashes.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const hashesMerchantReducer = hashesSlice.reducer;

export default hashesMerchantReducer;
