import {
  StyledNav,
  StyledList,
  StyledItem,
  StyledLink,
} from './AdminNav.styled';

const AdminNav = () => {
  return (
    <StyledNav>
      <StyledList>
        <StyledItem>
          <StyledLink to="traders">Трейдеры</StyledLink>
        </StyledItem>

        {/* <StyledItem>
          <StyledLink to="transactions">Транзакции</StyledLink>
        </StyledItem> */}
      </StyledList>
    </StyledNav>
  );
};

export default AdminNav;
