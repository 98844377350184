import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledSvg = styled.svg`
  position: ${({ p }) => {
    if (p === 'a') {
      return 'absolute';
    }

    return p ? p : '';
  }};
  top: ${({ t }) => {
    return t ? `${t}%` : '';
  }};

  left: ${({ l }) => {
    return l ? `${l}%` : '';
  }};

  z-index: ${({ z }) => (z ? z : '')};

  width: ${({ w_m }) => (w_m ? `${w_m}px` : '')};
  height: ${({ h_m }) => (h_m ? `${h_m}px` : '')};
  margin-right: ${({ mg_r_m }) => (mg_r_m ? `${mg_r_m}px` : '')};
  margin-left: ${({ mg_l_m }) => (mg_l_m ? `${mg_l_m}px` : '')};
  margin-bottom: ${({ mg_b_m }) => (mg_b_m ? `${mg_b_m}px` : '')};

  fill: ${({ c }) => {
    if (c === 'primary') {
      return `var(--svg-primary-color)`;
    }

    if (c === 'secondary') {
      return `var(--svg-secondary-color)`;
    }

    if (c === 'reverse') {
      return `var(--svg-reverse-color)`;
    }

    return c;
  }};

  stroke: ${({ s }) => {
    if (s === 'squares') {
      return `#1d1629`;
    }

    if (s === 'secondary') {
      return `var(--svg-secondary-color)`;
    }

    return '';
  }};

  stroke-opacity: ${({ s }) => {
    if (s === 'squares') {
      return `var(--stroke-opacity-merchant-square-color)`;
    }

    return '';
  }};

  ${mq.tablet} {
    width: ${({ w_t }) => {
      return w_t ? `${w_t}px` : '';
    }};

    height: ${({ h_t }) => {
      return h_t ? `${h_t}px` : '';
    }};

    margin-right: ${({ mg_r_t }) => {
      return mg_r_t ? `${mg_r_t}px` : '';
    }};

    margin-left: ${({ mg_l_t }) => {
      return mg_l_t ? `${mg_l_t}px` : '';
    }};
  }

  ${mq.desktop} {
    width: ${({ w_d }) => {
      return w_d ? `${w_d}px` : '';
    }};

    height: ${({ h_d }) => {
      return h_d ? `${h_d}px` : '';
    }};

    margin-right: ${({ mg_r_d }) => {
      return mg_r_d ? `${mg_r_d}px` : '';
    }};

    margin-left: ${({ mg_l_d }) => {
      return mg_l_d ? `${mg_l_d}px` : '';
    }};
  }
`;
