import { Puff } from 'react-loader-spinner';
import { StyledLoaderWrapper } from './Loader.styled';

const Loader = () => {
  return (
    <StyledLoaderWrapper>
      <Puff
        height={'100'}
        width={'100'}
        radius={2}
        color="#fff"
        ariaLabel="puff-loading"
        wrapperStyle={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        visible={true}
      />
    </StyledLoaderWrapper>
  );
};

export default Loader;
