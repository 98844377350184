import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const ordersSlice = createSlice({
  name: 'ordersMarketplace',

  initialState: {
    orders: [],
    pages: null,
    deal: {},
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.orders = [];
      state.pages = null;
      state.deal = {};
    });

    builder.addCase(ACTIONS.clearDeal, state => {
      state.deal = {};
    });

    builder.addCase(ACTIONS.setDealPaid, state => {
      state.deal.was_paid = true;
    });

    builder.addCase(API.fetchOrders.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload.orders;
      state.pages = action.payload.total_pages;
    });

    builder.addCase(API.fetchOrders.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.createOrder.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.createOrder.fulfilled, state => {
      state.isLoading = false;
    });

    builder.addCase(API.createOrder.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.startOrder.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.startOrder.fulfilled, state => {
      state.isLoading = false;
    });

    builder.addCase(API.startOrder.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchDealInfoSeller.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchDealInfoSeller.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deal = action.payload;
    });

    builder.addCase(API.fetchDealInfoSeller.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchDealInfoBuyer.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchDealInfoBuyer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deal = action.payload;
    });

    builder.addCase(API.fetchDealInfoBuyer.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchDealInfoOperator.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchDealInfoOperator.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deal = action.payload;
    });

    builder.addCase(API.fetchDealInfoOperator.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.payOrder.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.payOrder.fulfilled, state => {
      state.isLoading = false;
      state.deal.was_paid = true;
    });

    builder.addCase(API.payOrder.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.finishOrder.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.finishOrder.fulfilled, state => {
      state.isLoading = false;
      state.deal.is_finished = true;
    });

    builder.addCase(API.finishOrder.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.requestOperator.pending, state => {});

    builder.addCase(API.requestOperator.fulfilled, state => {});

    builder.addCase(API.requestOperator.rejected, state => {});

    builder.addCase(API.finishDeal.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.finishDeal.fulfilled, state => {
      state.isLoading = false;
      state.deal.is_finished = true;
    });

    builder.addCase(API.finishDeal.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const ordersMarketplaceReducer = ordersSlice.reducer;

export default ordersMarketplaceReducer;
