import { useState } from 'react';
import { createPortal } from 'react-dom';
import { StyledOutline, StyledDot } from './Cursor.styled';

const modalRoot = document.querySelector('#cursor-root');

const Cursor = () => {
  const [postX, setPostX] = useState(null);
  const [postY, setPostY] = useState(null);

  window.addEventListener('mousemove', e => {
    setPostX(e.clientX);
    setPostY(e.clientY);
  });

  window.addEventListener('mouseout', e => {
    setPostX(null);
    setPostY(null);
  });

  return createPortal(
    <>
      {postX && postY && (
        <>
          <StyledOutline
            animate={{ left: postX, top: postY }}
            transition={{ duration: 0.05 }}
            style={{
              left: `${postX}px`,
              top: `${postY}px`,
            }}
          ></StyledOutline>
          <StyledDot
            style={{
              left: `${postX}px`,
              top: `${postY}px`,
            }}
          ></StyledDot>
        </>
      )}
    </>,
    modalRoot
  );
};

export default Cursor;
