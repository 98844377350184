import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const fetchHashes = createAsyncThunk(
  'userMerchant/fetchHashes',
  async (_, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(document.location.search);
      const page = searchParams.get('page');
      const per_page = searchParams.get('per_page');

      const response = await axios.get(
        `/crypto/hashs?page=${page}&per_page=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = { fetchHashes };

export default API;
