import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const StyledWrp = styled.div`
  @media screen and (max-width: 1439px) {
    display: none;
  }

  display: flex;
  align-items: center;
  padding: 11px 10px 10px 10px;
  min-width: 160px;
  margin-right: 14px;

  background: var(--background-secondary-color);
  border-radius: 99px;
`;

export const StyledIconWrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;

  background: var(--button-primary-text-color);
  border-radius: 50%;
`;

export const StyledText = styled.p`
  margin-bottom: 2px;

  font-size: 12px;
  font-weight: 600;

  color: var(--text-primary-color);
`;

export const StyledValue = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;

  color: var(--text-primary-color);
`;

export const StyledNavLink = styled(NavLink)`
  font-weight: 600;

  color: var(--text-primary-color);

  &:hover {
    opacity: 0.5;
  }

  transition: opacity 250ms;
`;
