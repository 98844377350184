import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import { default as API_AUTH_MERCHANT } from '../../../redux/merchant/auth/operations';
import { default as API_AUTH_MARKETPLACE } from '../../../redux/marketplace/auth/operations';
import { default as API_PROFILE_MARKETPLACE } from '../../../redux/marketplace/profile/operations';
import { default as API_PROFILE_MERCHANT } from '../../../redux/merchant/user/operations';
import useApp from 'hooks/app/useApp';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledForm,
  StyledTopWrp,
  StyledTitle,
  StyledCrossBtn,
  StyledFieldWrp,
  StyledField,
  ShowHidePasswordBtn,
  ShowHideIconStyles,
  StyledSubmitBtn,
} from './AuthForms.styled';

const LoginForm = ({ toggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { product } = useApp();

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const handlePasswordShowToggle = () => {
    setIsPasswordShown(currentState => !currentState);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={({ email, password }, { resetForm }) => {
        if (!email || !password) {
          toast.error(t('notificationFormEmptyFields'));
          return;
        }

        const userCreds = {
          username: email,
          password,
        };

        if (product === 'exchanger') {
          dispatch(API_AUTH_MERCHANT.loginUser(userCreds)).then(() => {
            dispatch(API_PROFILE_MERCHANT.fetchRole()).then(data => {
              if (data.payload === 'user' || data.payload === 'trader') {
                navigate('/exchanger/profile');
              }

              if (data.payload === 'admin') {
                navigate('/exchanger/admin');
              }
            });
          });
        }

        if (product === 'marketplace') {
          dispatch(API_AUTH_MARKETPLACE.loginUser(userCreds)).then(() => {
            dispatch(API_PROFILE_MARKETPLACE.fetchRole()).then(data => {
              if (data.payload === 'user' || data.payload === 'trader') {
                return navigate('/marketplace/user_center');
              }

              if (
                data.payload === 'exchanger_admin' ||
                data.payload === 'admin'
              ) {
                return navigate('/marketplace/admin_center');
              }

              if (data.payload === 'operator') {
                return navigate('/marketplace/operator_center');
              }
            });
          });
        }

        resetForm();
        toggle();
      }}
    >
      {({ handleChange, values }) => {
        return (
          <StyledForm>
            <StyledTopWrp>
              <StyledTitle>{t('loginForm.title')}</StyledTitle>
              <StyledCrossBtn type="button" onClick={toggle}>
                <Svg w_m="15.5" h_m="15.5" c="secondary">
                  <use href={`${sprite}#cross`}></use>
                </Svg>

                <p className="hidden">close</p>
              </StyledCrossBtn>
            </StyledTopWrp>

            <StyledFieldWrp>
              <StyledField
                name="email"
                type="text"
                value={values.email}
                placeholder={t('loginForm.email')}
                onChange={handleChange('email')}
              />

              <Svg p="a" t="30" l="8" w_m="20" h_m="20" c="secondary">
                <use href={`${sprite}#email`}></use>
              </Svg>
            </StyledFieldWrp>

            <StyledFieldWrp>
              <StyledField
                name="password"
                type={isPasswordShown ? 'text' : 'password'}
                value={values.password}
                placeholder={t('loginForm.password')}
                autoComplete="off"
                onChange={handleChange('password')}
              />

              <Svg p="a" t="30" l="8" w_m="20" h_m="20" c="secondary">
                <use href={`${sprite}#password`}></use>
              </Svg>

              <ShowHidePasswordBtn
                type="button"
                onClick={handlePasswordShowToggle}
              >
                {isPasswordShown ? (
                  <IoEyeOffOutline style={ShowHideIconStyles} />
                ) : (
                  <IoEyeOutline style={ShowHideIconStyles} />
                )}

                <p className="hidden">hide</p>
              </ShowHidePasswordBtn>
            </StyledFieldWrp>

            <StyledSubmitBtn type="submit">
              {t('loginForm.button')}
            </StyledSubmitBtn>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
