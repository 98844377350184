import { useEffect, useState } from 'react';
import { StyledValue } from './Timer.styled';

const Timer = ({ minutes = 0, seconds = 0, handleTimerOver, style }) => {
  const min = minutes;
  const sec = seconds;
  const [over, setOver] = useState(false);
  const [[m, s], setTime] = useState([min, sec]);

  const tick = () => {
    if (over) return;
    if (m === 0 && s === 0) {
      setOver(true);
    } else if (m === 0 && s === 0) {
      setTime([59, 59]);
    } else if (s === 0) {
      setTime([m - 1, 59]);
    } else {
      setTime([m, s - 1]);
    }
  };

  useEffect(() => {
    if (over) {
      handleTimerOver();
    }
  }, [over, handleTimerOver]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return () => clearInterval(timerID);
  });

  return (
    <StyledValue style={style}>{`${m.toString().padStart(2, '0')}:${s
      .toString()
      .padStart(2, '0')}`}</StyledValue>
  );
};

export default Timer;
