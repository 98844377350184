import { createAction } from '@reduxjs/toolkit';

const setDefault = createAction('ordersMarketplace/setDefault');

const setDealPaid = createAction('ordersMarketplace/setDealPaid');

const clearDeal = createAction('ordersMarketplace/clearDeal');

const ACTIONS = { setDefault, setDealPaid, clearDeal };

export default ACTIONS;
