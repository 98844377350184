import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledLogoWrp,
  StyledText,
  StyledSpan,
} from './ProfileBalanceListItem.styled';

const ProfileBalanceListItem = ({ data }) => {
  const { network, token, value } = data;

  return (
    <StyledWrp>
      <StyledLogoWrp>
        <Svg w_m="28" h_m="28" mg_r_m="6">
          <use href={`${sprite}#network-${network}-logo-coin`}></use>
        </Svg>

        <div>
          <StyledText mg_b="4" fz="18" fw="500" tr="uppercase">
            {token}
          </StyledText>
          <StyledText fz="12" tr="capitalize" c="secondary">
            {network}
          </StyledText>
        </div>
      </StyledLogoWrp>

      <StyledText fw="500">
        <StyledSpan>{value}</StyledSpan>
      </StyledText>
    </StyledWrp>
  );
};

export default ProfileBalanceListItem;
