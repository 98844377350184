import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { default as API_AUTH_MERCHANT } from '../../../../../redux/merchant/auth/operations';
import { default as ACTIONS_USER_MERCHANT } from '../../../../../redux/merchant/user/actions';
import { default as ACTIONS_TRANSACTIONS_MERCHANT } from '../../../../../redux/merchant/transactions/actions';
import { default as ACTIONS_HASHES_MERCHANT } from '../../../../../redux/merchant/hashes/actions';
import { default as ACTIONS_WITHDRAWS_MERCHANT } from '../../../../../redux/merchant/withdraws/actions';
import { default as ACTIONS_ADMIN_MERCHANT } from '../../../../../redux/merchant/admin/actions';
import { default as API_AUTH_MARKETPLACE } from '../../../../../redux/marketplace/auth/operations';
import { default as ACTIONS_BANK_MARKETPLACE } from '../../../../../redux/marketplace/bank/actions';
import { default as ACTIONS_ORDERS_MARKETPLACE } from '../../../../../redux/marketplace/orders/actions';
import { default as ACTIONS_PROFILE_MARKETPLACE } from '../../../../../redux/marketplace/profile/actions';
import { default as ACTIONS_PUBLIC_PROFILE_MARKETPLACE } from '../../../../../redux/marketplace/publicProfile/actions';
import { default as ACTIONS_EXCHANGER_ADMIN_MARKETPLACE } from '../../../../../redux/marketplace/exchangerAdmin/actions';
import { default as ACTIONS_OPERATOR_MARKETPLACE } from '../../../../../redux/marketplace/operator/actions';
import { default as ACTIONS_ADMIN_MARKETPLACE } from '../../../../../redux/marketplace/admin/actions';
import useApp from 'hooks/app/useApp';
import { useAuthMerchant, useProfileMerchant } from 'hooks/merchant';
import { useAuthMarketplace, useProfileMarketplace } from 'hooks/marketplace';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledNavLink,
  StyledIconWrp,
  StyledText,
  StyledBtn,
} from './Logout.styled';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product } = useApp();
  const { isLoggedInMerchant } = useAuthMerchant();
  const { isLoggedInMarketplace } = useAuthMarketplace();
  const { username: usernameMerchant, role: roleMerchant } =
    useProfileMerchant();
  const { username: usernameMarketplace, role: roleMarketplace } =
    useProfileMarketplace();

  const getLink = () => {
    if (
      product === 'exchanger' &&
      (roleMerchant === 'user' || roleMerchant === 'trader')
    ) {
      return '/exchanger/profile';
    }

    if (product === 'exchanger' && roleMerchant === 'admin') {
      return '/exchanger/admin';
    }

    if (
      product === 'marketplace' &&
      (roleMarketplace === 'user' || roleMarketplace === 'trader')
    ) {
      return '/marketplace/user_center';
    }

    if (
      product === 'marketplace' &&
      (roleMarketplace === 'exchanger_admin' || roleMarketplace === 'admin')
    ) {
      return '/marketplace/admin_center';
    }

    if (product === 'marketplace' && roleMarketplace === 'operator') {
      return '/marketplace/operator_center';
    }
  };

  const getUsername = () => {
    if (
      product === 'exchanger' &&
      (roleMerchant === 'user' || roleMerchant === 'trader')
    ) {
      return usernameMerchant;
    }

    if (product === 'exchanger' && roleMerchant === 'admin') {
      return 'admin';
    }

    if (
      product === 'marketplace' &&
      (roleMarketplace === 'user' || roleMarketplace === 'trader')
    ) {
      return usernameMarketplace;
    }

    if (
      product === 'marketplace' &&
      (roleMarketplace === 'exchanger_admin' || roleMarketplace === 'admin')
    ) {
      return 'admin';
    }

    if (product === 'marketplace' && roleMarketplace === 'operator') {
      return 'operator';
    }
  };

  const link = getLink();
  const username = getUsername();

  const handleLogout = () => {
    if (
      isLoggedInMerchant &&
      (roleMerchant === 'user' || roleMerchant === 'trader')
    ) {
      dispatch(API_AUTH_MERCHANT.logoutUser()).finally(() => {
        dispatch(ACTIONS_USER_MERCHANT.setDefault());
        dispatch(ACTIONS_TRANSACTIONS_MERCHANT.setDefault());
        dispatch(ACTIONS_HASHES_MERCHANT.setDefault());
        dispatch(ACTIONS_WITHDRAWS_MERCHANT.setDefault());
      });
    }

    if (isLoggedInMerchant && roleMerchant === 'admin') {
      dispatch(API_AUTH_MERCHANT.logoutUser()).finally(() => {
        dispatch(ACTIONS_USER_MERCHANT.setDefault());
        dispatch(ACTIONS_ADMIN_MERCHANT.setDefault());
      });
    }

    if (
      isLoggedInMarketplace &&
      (roleMarketplace === 'user' || roleMarketplace === 'trader')
    ) {
      dispatch(API_AUTH_MARKETPLACE.logoutUser()).finally(() => {
        dispatch(ACTIONS_PROFILE_MARKETPLACE.setDefault());
        dispatch(ACTIONS_BANK_MARKETPLACE.setDefault());
        dispatch(ACTIONS_ORDERS_MARKETPLACE.setDefault());
        dispatch(ACTIONS_PUBLIC_PROFILE_MARKETPLACE.setDefault());
      });
    }

    if (isLoggedInMarketplace && roleMarketplace === 'exchanger_admin') {
      dispatch(API_AUTH_MARKETPLACE.logoutUser()).finally(() => {
        dispatch(ACTIONS_PROFILE_MARKETPLACE.setDefault());
        dispatch(ACTIONS_EXCHANGER_ADMIN_MARKETPLACE.setDefault());
      });
    }

    if (isLoggedInMarketplace && roleMarketplace === 'operator') {
      dispatch(API_AUTH_MARKETPLACE.logoutUser()).finally(() => {
        dispatch(ACTIONS_PROFILE_MARKETPLACE.setDefault());
        dispatch(ACTIONS_OPERATOR_MARKETPLACE.setDefault());
      });
    }

    if (isLoggedInMarketplace && roleMarketplace === 'admin') {
      dispatch(API_AUTH_MARKETPLACE.logoutUser()).finally(() => {
        dispatch(ACTIONS_PROFILE_MARKETPLACE.setDefault());
        dispatch(ACTIONS_ADMIN_MARKETPLACE.setDefault());
      });
    }

    navigate('/');
  };

  return (
    <StyledWrp>
      <StyledNavLink to={link}>
        <StyledIconWrp>
          <Svg w_m="21.5" h_m="21.5" c="secondary">
            <use href={`${sprite}#login`}></use>
          </Svg>
        </StyledIconWrp>

        <div>
          <StyledText>{username}</StyledText>

          <StyledText
            t="hidden"
            style={{ fontFamily: 'Roboto, sans-serif' }}
            className="hidden-balance"
          >
            {/* ${balance} */}
          </StyledText>
        </div>
      </StyledNavLink>

      <StyledBtn type="button" onClick={handleLogout}>
        <Svg w_m="19.5" h_m="19.5" c="primary">
          <use href={`${sprite}#logout`}></use>
        </Svg>

        <p className="hidden">Logout</p>
      </StyledBtn>
    </StyledWrp>
  );
};

export default Logout;
