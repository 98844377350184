import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import token from 'utils/setToken';

const registerUser = createAsyncThunk(
  'authMarketplace/register',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/register', data);

      toast.success(i18n.t('notificationRegisterForm.success'));

      return response.data;
    } catch (error) {
      toast.error(i18n.t('notificationRegisterForm.error'));

      return rejectWithValue(error.message);
    }
  }
);

const loginUser = createAsyncThunk(
  'authMarketplace/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/jwt/login', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      token.set(response.data.access_token);

      toast.success(i18n.t('notificationLoginForm.success'));

      return response.data.access_token;
    } catch (error) {
      toast.error(i18n.t('notificationLoginForm.error'));

      return rejectWithValue(error.message);
    }
  }
);

const logoutUser = createAsyncThunk(
  'authMarketplace/logout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/jwt/logout');

      token.unset();

      toast.success(i18n.t('notificationLogout'));

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const refreshUser = createAsyncThunk(
  'authMarketplace/refresh',
  async (_, { rejectWithValue, getState }) => {
    const { token: persistedToken } = getState().authMarketplace;

    if (!persistedToken) {
      return rejectWithValue('No valid token');
    }

    token.set(persistedToken);

    try {
      const response = await axios.get('/auth/jwt/refresh');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = {
  registerUser,
  loginUser,
  logoutUser,
  refreshUser,
};

export default API;
