import { useTranslation } from 'react-i18next';
import { useProfileMerchant } from 'hooks/merchant';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledWrp,
  StyledValue,
  StyledText,
} from './ProfileTransactionCounter.styled';

const ProfileTransactionCounter = () => {
  const { statistics } = useProfileMerchant();
  const { t } = useTranslation();

  return (
    <StyledWrp>
      <Svg
        w_m="28"
        h_m="28"
        w_t="34"
        h_t="34"
        c="secondary"
        mg_r_m="12"
        mg_r_t="13"
      >
        <use href={`${sprite}#percentage`}></use>
      </Svg>

      <div>
        <StyledValue>{statistics?.total_transactions}</StyledValue>

        <StyledText>{t('profileTransactions')}</StyledText>
      </div>
    </StyledWrp>
  );
};

export default ProfileTransactionCounter;
