import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 25px 18px 25px;

  background: var(--background-form-color);
  border-radius: 21px;

  ${mq.tablet} {
    width: 514px;
    padding: 26px 28px 26px;
  }
`;

export const StyledFormTopWrp = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const StyledTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;

  color: var(--text-primary-color);
`;

export const StyledCrossBtn = styled.button`
  width: 24px;
  height: 24px;

  background: transparent;
  border: none;
`;

export const StyledFieldWrp = styled.div`
  position: relative;

  margin-bottom: 20px;

  cursor: pointer;
`;

export const StyledField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 28px;

  border-radius: 99px;
  background: var(--background-input-color);
`;

export const StyledText = styled.p`
  font-weight: 600;

  color: var(--text-secondary-color);
`;

export const StyledTickBtn = styled.button`
  background: transparent;
  border: none;
`;

export const StyledDropdownList = styled.ul`
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 2;

  width: 100%;
  padding: 12px;
  padding-bottom: 14px;

  background: var(--background-bank-card-area-color);
  border-radius: 12px;
`;

export const StyledDropdownListItem = styled.li`
  font-family: Roboto, sans-serif;
  text-align: center;

  color: var(--text-primary-color);

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const StyledSubmitBtn = styled.button`
  padding: 18px 0;

  font-size: 16px;
  font-weight: 600;

  color: var(--button-primary-text-color);
  background: var(--button-primary-color);
  border: none;
  border-radius: 99px;
`;
