import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ACTIONS from '../../../redux/app/actions';
import useApp from 'hooks/app/useApp';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledForm,
  StyledFormTopWrp,
  StyledTitle,
  StyledCrossBtn,
  StyledFieldWrp,
  StyledField,
  StyledText,
  StyledTickBtn,
  StyledDropdownList,
  StyledDropdownListItem,
  StyledSubmitBtn,
} from './AppForm.styled';

const products = [
  {
    id: 1,
    product: 'exchanger',
  },
  {
    id: 2,
    product: 'marketplace',
  },
];

const AppForm = ({
  type,
  toggle,
  handleToggleLoginForm,
  handleToggleRegisterForm,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { product } = useApp();
  const { t } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    if (pathname.includes('marketplace') || pathname.includes('exchanger')) {
      return;
    }

    setIsDropdownOpen(currentState => !currentState);
  };

  const handleSelect = data => {
    dispatch(ACTIONS.setProduct(data));
  };

  const handleBtnClick = () => {
    toggle();

    if (type === 'signup') {
      handleToggleRegisterForm();
    }

    if (type === 'signin') {
      handleToggleLoginForm();
    }
  };

  return (
    <StyledForm>
      <StyledFormTopWrp>
        <StyledTitle>{t('landing.appForm.title')}</StyledTitle>

        <StyledCrossBtn type="button" onClick={toggle}>
          <Svg w_m="15.5" h_m="15.5" c="secondary">
            <use href={`${sprite}#cross`}></use>
          </Svg>

          <p className="hidden">close</p>
        </StyledCrossBtn>
      </StyledFormTopWrp>

      <StyledFieldWrp onClick={handleDropdownToggle}>
        <StyledField>
          <StyledText>
            {/* {product === 'exchanger' ? t('landing.appForm.merchant') : ''} */}

            {product}

            {/* {product === 'marketplace' && t('landing.appForm.marketplace')} */}
          </StyledText>

          {pathname.includes('marketplace') ||
          pathname.includes('exchanger') ? (
            ''
          ) : (
            <StyledTickBtn
              type="button"
              style={{
                transform: isDropdownOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                transition: 'transform 250ms ',
              }}
            >
              <Svg w_m="11.5" h_m="5.5" c="secondary">
                <use href={`${sprite}#tick`}></use>
              </Svg>

              <p className="hidden">tick</p>
            </StyledTickBtn>
          )}
        </StyledField>

        {isDropdownOpen && (
          <StyledDropdownList>
            {products.map(el => (
              <StyledDropdownListItem
                onClick={() => {
                  handleSelect(el.product);
                }}
                key={el.id}
              >
                {el.product}
              </StyledDropdownListItem>
            ))}
          </StyledDropdownList>
        )}
      </StyledFieldWrp>

      <StyledSubmitBtn type="button" onClick={handleBtnClick}>
        {t('landing.appForm.btn')}
      </StyledSubmitBtn>
    </StyledForm>
  );
};

export default AppForm;
