import { useSelector } from 'react-redux';
import select from '../../redux/marketplace/profile/selectors';

const useProfile = () => {
  const role = useSelector(select.role);
  const isLoadingRole = useSelector(select.isLoadingRole);
  const userId = useSelector(select.id);
  const username = useSelector(select.username);
  const balance = useSelector(select.balance);
  const statistic = useSelector(select.statistic);
  const deals_info = useSelector(select.deals_info);
  const orders = useSelector(select.orders);
  const orders_pages = useSelector(select.orders_pages);
  const deals = useSelector(select.deals);
  const deals_pages = useSelector(select.deals_pages);
  const contragents = useSelector(select.contragents);
  const comments = useSelector(select.comments);
  const followers = useSelector(select.followers);
  const following = useSelector(select.following);
  const usdt_wallet_addresses = useSelector(select.usdt_wallet_addresses);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    role,
    isLoadingRole,
    userId,
    username,
    balance,
    deals_info,
    statistic,
    orders,
    orders_pages,
    deals,
    deals_pages,
    contragents,
    comments,
    followers,
    following,
    usdt_wallet_addresses,
    isLoading,
    error,
  };
};

export default useProfile;
