import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledItem = styled.li`
  position: relative;

  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;

  background: var(--background-nt-list-item-color);
  border: 1px solid var(--border-nt-color);
  border-radius: 17px;

  user-select: none;

  ${mq.tablet} {
    width: 720px;
    padding: 13px 16px 13px 28px;
    border-radius: 21px;
  }

  ${mq.desktop} {
    width: 100%;
    min-height: 67px;
  }

  &:not(:last-child) {
    margin-bottom: 7px;

    ${mq.tablet} {
      margin-bottom: 14px;
    }
  }
`;

export const StyledWrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq.tablet} {
    min-width: 90px;
  }
`;

export const StyledAddressWrp = styled.div`
  display: ${({ d }) => {
    if (d === 'hidden') {
      return 'none';
    }

    return 'flex';
  }};

  align-items: center;

  ${mq.tablet} {
    display: flex;
  }
`;

export const StyledCopyBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
`;

export const StyledGroupWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 230px;
`;

export const StyledBtn = styled.button`
  padding: 6px 16px 6px;

  font-weight: 600;

  color: var(--button-primary-text-color);
  background: var(--button-primary-color);
  border: none;
  border-radius: 12px;
`;

export const StyledInfoBtn = styled.button`
  background: transparent;
  border: none;
  color: var(--text-primary-color);
`;

export const StyledDropdownWrp = styled.div`
  position: absolute;
  z-index: 2;

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;

  width: 98%;

  top: 8%;
  left: 1%;

  background: var(--background-secondary-color);
  border-radius: 17px;

  ${mq.tablet} {
    top: 10%;
    left: 1%;

    padding: 12px;
  }

  ${mq.desktop} {
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    border-radius: 21px;
  }
`;

export const StyledText = styled.p`
  display: ${({ t }) => {
    if (t === 'hidden') {
      return 'none';
    }

    return 'inline';
  }};
  min-width: ${({ mw }) => (mw ? `${mw}px` : '')};
  margin-right: ${({ mg_r }) => (mg_r ? `${mg_r}px` : '')};

  font-weight: 500;
  line-height: 150%;
  text-transform: ${({ tr }) => (tr ? tr : '')};

  color: var(--text-primary-color);

  ${mq.tablet} {
    display: block;
    min-width: ${({ mw_m }) => `${mw_m}px`};
  }

  ${mq.desktop} {
    min-width: ${({ mw_d }) => `${mw_d}px`};
  }
`;

export const StyledSpan = styled.span`
  font-family: Roboto, sans-serif;
  font-size: ${({ fz }) => (fz ? `${fz}px` : ' ')};
`;
