import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 12px;

  background: var(--background-secondary-color);
  border-radius: 18px;

  ${mq.tablet} {
    width: 720px;
  }

  ${mq.desktop} {
    width: 100%;
  }
`;

export const StyledLogoWrp = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledText = styled.p`
  margin-bottom: ${({ mg_b }) => (mg_b ? `${mg_b}px` : '')};

  font-size: ${({ fz }) => (fz ? `${fz}px` : '')};
  font-weight: ${({ fw }) => (fw ? fw : '')};
  text-transform: ${({ tr }) => (tr ? tr : '')};

  color: ${({ c }) => {
    if (c === 'secondary') {
      return 'var(--text-secondary-color)';
    }

    return 'var(--text-primary-color)';
  }};
`;

export const StyledSpan = styled.span`
  font-family: Roboto, sans-serif;
`;
