import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const operatorSlice = createSlice({
  name: 'operatorMarketplace',

  initialState: {
    requests: [],
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.requests = [];
    });

    builder.addCase(API.fetchRequests.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchRequests.fulfilled, (state, action) => {
      state.isLoading = false;
      state.requests = action.payload;
    });

    builder.addCase(API.fetchRequests.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const operatorMarketplaceReducer = operatorSlice.reducer;

export default operatorMarketplaceReducer;
