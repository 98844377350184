import styled from '@emotion/styled';
export const StyledWrp = styled.div`
  width: 400px;
  padding: 20px;

  background: var(--background-secondary-color);
  border-radius: 18px;
`;

export const StyledFormTopWrp = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
`;

export const StyledCrossBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
`;

export const StyledList = styled.ul``;

export const StyledItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const StyledIconWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledText = styled.p`
  margin-right: ${({ mg_r_d }) => (mg_r_d ? `${mg_r_d}px` : '')};
  margin-bottom: ${({ mg_b_d }) => (mg_b_d ? `${mg_b_d}px` : '')};

  font-family: ${({ fm }) =>
    fm ? 'Roboto, sans-serif' : 'Raleway, sans-serif'};

  font-size: ${({ fz }) => (fz ? `${fz}px` : '14px')};
  font-weight: ${({ fw }) => (fw ? fw : 400)};
  line-height: 120%;
  text-transform: ${({ tr }) => (tr ? tr : '')};

  color: ${({ c }) => {
    if (c === 'secondary') {
      return 'var(--text-secondary-color)';
    }

    return 'var(--text-primary-color)';
  }};
`;

export const StyledSpan = styled.span`
  font-family: Roboto, sans-serif;
`;
