import { createAction } from '@reduxjs/toolkit';

const setDefault = createAction('transactionsMerchant/setDefault');

const timerFinish = createAction('transactionsMerchant/timerFinish');

const ACTIONS = {
  setDefault,
  timerFinish,
};

export default ACTIONS;
