import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { copyToClipboard } from 'utils';
import { Svg } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledItem,
  StyledText,
  StyledSpan,
  StyledWrp,
  StyledCopyBtn,
} from './ProfileWithdrawListItem.styled';

const ProfileWithdrawListItem = forwardRef(({ data }, ref) => {
  const { id, network, amount, to_address, tx_hash, created_at, to_currency } =
    data;

  const createdLocalTime = new Date(created_at + 'Z');

  const formattedCreatedLocalTime = format(
    createdLocalTime,
    'dd.LL.yyyy HH:mm'
  );

  return (
    <StyledItem ref={ref}>
      <StyledText>
        <StyledSpan>{id}</StyledSpan>
      </StyledText>

      <StyledText mw_d="120" tr="uppercase">
        {network}
      </StyledText>

      <StyledText tr="uppercase" mw_d="150">
        <StyledSpan>{amount}</StyledSpan> {to_currency}
      </StyledText>

      <StyledWrp>
        <StyledText mw_d="110">
          <StyledSpan>{to_address.slice(0, 10)}...</StyledSpan>
        </StyledText>

        <StyledCopyBtn
          type="button"
          onClick={() => copyToClipboard(to_address)}
        >
          <Svg w_m="16" h_m="16">
            <use href={`${sprite}#copy`}></use>
          </Svg>

          <p className="hidden">copy</p>
        </StyledCopyBtn>
      </StyledWrp>

      <StyledWrp>
        <StyledText mw_d="110">
          <StyledSpan>{tx_hash.slice(0, 10)}...</StyledSpan>
        </StyledText>

        <StyledCopyBtn type="button" onClick={() => copyToClipboard(tx_hash)}>
          <Svg w_m="16" h_m="16">
            <use href={`${sprite}#copy`}></use>
          </Svg>

          <p className="hidden">copy</p>
        </StyledCopyBtn>
      </StyledWrp>

      <StyledText>
        <StyledSpan>{formattedCreatedLocalTime}</StyledSpan>
      </StyledText>
    </StyledItem>
  );
});

export default motion(ProfileWithdrawListItem);
