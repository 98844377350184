import { useSelector } from 'react-redux';
import select from '../../redux/merchant/hashes/selectors';

const useHashes = () => {
  const hashes = useSelector(select.hashes);
  const total_pages = useSelector(select.total_pages);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    hashes,
    total_pages,
    isLoading,
    error,
  };
};

export default useHashes;
