import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const transactionsSlice = createSlice({
  name: 'transactionsMerchant',
  initialState: {
    transactions: [],
    total_pages: null,
    transaction: {},
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.transactions = [];
      state.total_pages = null;
      state.transaction = {};
    });

    builder.addCase(API.fetchTransactions.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactions = action.payload.transactions;
      state.total_pages = action.payload.total_pages;
    });

    builder.addCase(API.fetchTransactions.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchTransactionCsvFile.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchTransactionCsvFile.fulfilled, state => {
      state.isLoading = false;
    });

    builder.addCase(API.fetchTransactionCsvFile.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.createTransaction.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.createTransaction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transaction = action.payload;
    });

    builder.addCase(API.createTransaction.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.confirmTransaction.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.confirmTransaction.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.transactions.findIndex(
        el => el.id === action.payload
      );

      state.transactions.splice(index, 1, {
        ...state.transactions[index],
        is_finished: true,
      });
    });

    builder.addCase(API.confirmTransaction.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const transcationsReducer = transactionsSlice.reducer;

export default transcationsReducer;
