import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { mq } from 'utils';

export const StyledWrp = styled.div`
  align-self: start;
  margin-bottom: 25px;

  background: transparent;

  ${mq.tablet} {
    width: 230px;
    margin-right: 15px;
    margin-bottom: 0;
    padding: 30px 10px 25px 10px;

    background: var(--background-profile-nav-color);
    border-radius: 31px;
    box-shadow: 0px 0px 15px 0px var(--box-shadow-profile-nav);
  }

  ${mq.desktop} {
    width: 278px;
    margin-right: 20px;
    padding: 30px 25px 25px 25px;
  }
`;

export const StyledTitle = styled.h3`
  @media screen and (max-width: 759px) {
    display: none;
  }

  margin-bottom: 24px;
  margin-left: 14px;

  font-size: 21px;
  font-weight: 600;

  color: var(--text-primary-color);
`;

export const StyledList = styled.ul`
  display: flex;
  overflow: auto;

  ${mq.tablet} {
    flex-direction: column;
  }
`;

export const StyledItem = styled.li`
  min-width: 200px;

  &:not(:last-child) {
    margin-right: 8px;

    ${mq.tablet} {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  padding: 13px 0;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  user-select: none;

  color: var(--text-primary-color);
  border-radius: 18px;

  transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1);

  ${mq.tablet} {
    justify-content: flex-start;
    padding: 13px 13px 15px 16px;
  }

  ${mq.desktop} {
    padding: 14px 12px 14px 16px;
  }

  &.active {
    background: var(--background-profile-nav-active-color);
  }
`;

export const StyledSelect = styled.div`
  display: flex;
  padding: 13px 0;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  user-select: none;
  cursor: pointer;

  color: var(--text-primary-color);
  border-radius: 18px;

  ${mq.tablet} {
    justify-content: flex-start;
    padding: 13px 13px 15px 16px;
  }

  ${mq.desktop} {
    padding: 14px 12px 14px 16px;
  }
`;

export const StyledTickBtn = styled.button`
  margin-left: 4px;

  background: transparent;
  border: none;

  ${mq.tablet} {
    margin-left: auto;
  }
`;

export const StyledDropdownList = styled.ul`
  display: flex;
  align-items: center;
  margin-left: 14px;

  ${mq.tablet} {
    display: block;
    padding: 0px 36px 14px;
  }
`;

export const StyledDropdownItem = styled.li`
  @media screen and (max-width: 759px) {
    width: 60px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  ${mq.tablet} {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const StyledSubNavLink = styled(NavLink)`
  font-size: 16px;
  font-weight: 600;
  user-select: none;

  color: var(--text-primary-color);

  &.active {
    color: var(--text-secondary-color);
  }
`;
