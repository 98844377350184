import { createAction } from '@reduxjs/toolkit';

const setProduct = createAction('app/setProduct');

const setTheme = createAction('app/setTheme');

const setScreenWidth = createAction('app/setInnerWidth');

const ACTIONS = { setProduct, setTheme, setScreenWidth };

export default ACTIONS;
