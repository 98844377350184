import ProfileBalanceListItem from 'components/merchant/ProfileBalanceListItem';

const ProfileBalanceList = ({ data }) => {
  return (
    <ul>
      {data.map((el, index) => (
        <ProfileBalanceListItem data={el} key={index} />
      ))}
    </ul>
  );
};

export default ProfileBalanceList;
