import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const adminSlice = createSlice({
  name: 'adminMerchant',
  initialState: {
    user_count: null,
    transaction_count: null,
    transaction_turnover: [],
    transaction_currency_count: [],
    traders: [],
    traders_pages: null,
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault, state => {
      state.user_count = null;
      state.transaction_count = null;
      state.transaction_turnover = [];
      state.transaction_currency_count = [];
    });

    builder.addCase(API.fetchUserCount.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchUserCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user_count = action.payload;
    });

    builder.addCase(API.fetchUserCount.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchTransactionCount.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchTransactionCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transaction_count = action.payload;
    });

    builder.addCase(API.fetchTransactionCount.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchTransactionTurnover.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchTransactionTurnover.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transaction_turnover = action.payload;
    });

    builder.addCase(API.fetchTransactionTurnover.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchTransactionCurrencyCount.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(
      API.fetchTransactionCurrencyCount.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.transaction_currency_count = action.payload;
      }
    );

    builder.addCase(API.fetchTransactionCurrencyCount.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchTraders.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchTraders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.traders = action.payload.traders;
      state.traders_pages = action.payload.total_pages;
    });

    builder.addCase(API.fetchTraders.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.createTrader.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.createTrader.fulfilled, (state, action) => {
      state.isLoading = false;
      state.traders.push(action.payload);
    });

    builder.addCase(API.createTrader.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.removeTrader.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.removeTrader.fulfilled, (state, action) => {
      state.isLoading = false;

      const index = state.operators.findIndex(
        el => el.trader_id === action.payload
      );

      state.traders.splice(index, 1);
    });

    builder.addCase(API.removeTrader.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const adminMerchantReducer = adminSlice.reducer;

export default adminMerchantReducer;
