import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const StyledSelectWrp = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 60px;

  background: transparent;
  border: 5px solid var(--background-secondary-color);
  border-radius: 99px;

  cursor: pointer;
`;

export const StyledSelectBtn = styled.button`
  margin-left: 4px;

  background: transparent;

  border: none;
`;

export const StyledSelectList = styled(motion.ul)`
  position: absolute;
  top: 120%;
  left: 0%;
  z-index: 2;

  display: flex;
  flex-direction: column;
  width: 100%;

  background: var(--background-secondary-color);
  border-radius: 12px;
`;

export const StyledSelectItem = styled(motion.li)`
  text-align: center;
  user-select: none;

  margin: 4px 0;
`;

export const StyledSelectText = styled.p`
  font-weight: 800;

  color: var(--text-primary-color);
`;
