import ProfileWithdrawListItem from 'components/merchant/ProfileWithdrawListItem';
import { StyledList } from './ProfileWithdrawList.styled';

const withdrawItemVariants = {
  hidden: {
    x: 110,
    opacity: 0,
  },
  visible: custom => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.1, duration: 0.25 },
  }),
};

const ProfileWithdrawList = ({ data }) => {
  return (
    <StyledList>
      {data.map((el, index) => (
        <ProfileWithdrawListItem
          key={el.id}
          data={el}
          initial="hidden"
          animate="visible"
          variants={withdrawItemVariants}
          custom={index}
        />
      ))}
    </StyledList>
  );
};

export default ProfileWithdrawList;
