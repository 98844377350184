import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Svg } from 'components/common';
import {
  StyledSelectWrp,
  StyledSelectBtn,
  StyledSelectList,
  StyledSelectItem,
  StyledSelectText,
} from './LanguageSelector.styled';
import sprite from 'assets/images/sprite.svg';

const languages = [
  { code: 'en', name: 'EN' },
  { code: 'ua', name: 'UA' },
  { code: 'ru', name: 'RU' },
];

const LanguageSelector = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return JSON.parse(localStorage.getItem('language')) || 'en';
  });
  const languageInUpperCase = selectedLanguage.toUpperCase();

  const handleButtonClick = () => {
    setIsDropdownOpen(currentState => !currentState);
  };

  const handleSelectLanguage = val => {
    i18n.changeLanguage(val);
    setSelectedLanguage(val);
    localStorage.setItem('language', JSON.stringify(val));
  };

  return (
    <>
      <StyledSelectWrp onClick={handleButtonClick}>
        <StyledSelectText>{languageInUpperCase}</StyledSelectText>
        <StyledSelectBtn
          type="button"
          style={{
            transform: isDropdownOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
            transition: 'transform 250ms ',
          }}
        >
          <Svg w_m="11.5" h_m="5.5" c="secondary">
            <use href={`${sprite}#tick`}></use>
          </Svg>
          <p className="hidden">tick</p>
        </StyledSelectBtn>
        <AnimatePresence initial={false} onExitComplete={() => null}>
          {isDropdownOpen && (
            <StyledSelectList
              initial={{ height: 0 }}
              animate={{ height: '80px' }}
              exit={{ height: 0, padding: 0 }}
              transition={{ duration: 0.3 }}
            >
              {languages.map((el, index) => {
                return (
                  <StyledSelectItem
                    initial={{ opacity: 0, y: -30 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -30, transition: { duration: 0.2 } }}
                    transition={{ delay: index * 0.05, duration: 0.25 }}
                    key={el.code}
                    onClick={() => handleSelectLanguage(el.code)}
                  >
                    <StyledSelectText>{el.name}</StyledSelectText>
                  </StyledSelectItem>
                );
              })}
            </StyledSelectList>
          )}
        </AnimatePresence>
      </StyledSelectWrp>
    </>
  );
};

export default LanguageSelector;
