import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const fetchUserCount = createAsyncThunk(
  'adminMerchant/fetchUserCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/admin/users');

      return response.data.total_register_users;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTransactionCount = createAsyncThunk(
  'adminMerchant/fetchTransactionCount',
  async (time, { rejectWithValue }) => {
    try {
      let response;

      if (!time) {
        response = await axios.get(`/admin/transaction`);
      } else {
        response = await axios.get(`/admin/transaction?period=${time}`);
      }

      return response.data.transaction_count_per_period;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTransactionTurnover = createAsyncThunk(
  'adminMerchant/fetchTransactionTurnover',
  async (time, { rejectWithValue }) => {
    try {
      let response;

      if (!time) {
        response = await axios.get(`/admin/transaction_turnover`);
      } else {
        response = await axios.get(
          `/admin/transaction_turnover?period=${time}`
        );
      }

      return response.data.transaction_turnover_period;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTransactionCurrencyCount = createAsyncThunk(
  'adminMerchant/fetchTransactionCountByCurrency',
  async (time, { rejectWithValue }) => {
    try {
      let response;

      if (!time) {
        response = await axios.get(`/admin/transactions_currency`);
      } else {
        response = await axios.get(
          `/admin/transactions_currency?period=${time}`
        );
      }

      return response.data.transactions;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTraders = createAsyncThunk(
  'adminMerchant/fetchTraders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/traders`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const createTrader = createAsyncThunk(
  'adminMerchant/createTrader',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/admin/trader', data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const removeTrader = createAsyncThunk(
  'adminMerchant/removeTrader',
  async (trader_id, { rejectWithValue }) => {
    try {
      const data = { trader_id };

      await axios.delete('/admin/traders/delete', {
        data,
      });

      return trader_id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = {
  fetchUserCount,
  fetchTransactionCount,
  fetchTransactionTurnover,
  fetchTransactionCurrencyCount,
  fetchTraders,
  createTrader,
  removeTrader,
};

export default API;
