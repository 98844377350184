import * as Yup from 'yup';

const bankCardAddEditSchema = Yup.object().shape({
  card_number: Yup.string(),

  limit: Yup.string().min(3).max(7).trim('Remove spaces').strict(),

  title: Yup.string()
    .max(12, 'must be no more than 12 characters')
    .trim('Remove spaces')
    .strict(),

  comment: Yup.string()
    .max(20, 'must be no more than 20 characters')
    .trim('Remove spaces')
    .strict(),
});

export default bankCardAddEditSchema;
