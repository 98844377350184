import { useSelector } from 'react-redux';
import select from '../../redux/merchant/user/selectors';

const useUser = () => {
  const role = useSelector(select.role);
  const isLoadingRole = useSelector(select.isLoadingRole);
  const username = useSelector(select.username);
  const balance = useSelector(select.balance);
  const statistics = useSelector(select.statistics);
  const wallet_addresses = useSelector(select.wallet_addresses);
  const is_trader_online = useSelector(select.is_trader_online);
  const isLoading = useSelector(select.isLoading);
  const error = useSelector(select.error);

  return {
    role,
    isLoadingRole,
    username,
    balance,
    statistics,
    is_trader_online,
    wallet_addresses,
    isLoading,
    error,
  };
};

export default useUser;
