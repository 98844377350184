import * as Yup from 'yup';
import i18n from 'i18n';

const registerSchema = () => {
  return Yup.object().shape({
    login: Yup.string()
      .min(4, `${i18n.t('registerForm.loginMin')}`)
      .max(16, `${i18n.t('registerForm.loginMax')}`)
      .trim(`${i18n.t('trim')}`)
      .strict(),

    email: Yup.string().matches(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, {
      message: `${i18n.t('registerForm.emailErrorMessage')}`,
    }),

    password: Yup.string()
      .min(6, `${i18n.t('registerForm.passwordMin')}`)
      .max(16, `${i18n.t('registerForm.passwordMax')}`)
      .matches(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
        `${i18n.t('registerForm.passwordMatch')}`
      )
      .trim(`${i18n.t('trim')}`)
      .strict(),

    passwordAgain: Yup.string().oneOf(
      [Yup.ref('password'), null],
      `${i18n.t('registerForm.passwordAgainErrorMessage')}`
    ),
  });
};

export default registerSchema;
