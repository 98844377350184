import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { default as API_WITHDRAWS_MERCHANT } from '../../../redux/merchant/withdraws/operations';
import { Svg, Modal } from 'components/common';
import sprite from 'assets/images/sprite.svg';
import {
  StyledForm,
  StyledFormTopWrp,
  StyledTitle,
  StyledCrossBtn,
  StyledFieldWrp,
  StyledField,
  StyledFieldSelectWrp,
  StyledTickBtn,
  StyledIconWrp,
  StyledSubmitBtn,
  StyledSelectWrp,
  StyledSelectListWrp,
  StyledSelectList,
  StyledSelectItem,
  StyledText,
} from './ProfileWithdrawalForm.styled';

const network_list = [
  // {
  //   id: 0,
  //   name: 'bitcoin',
  //   token: 'btc',
  //   value: '',
  // },
  {
    id: 1,
    name: 'ethereum',
    token: 'eth',
    value: 'eth',
  },
  {
    id: 2,
    name: 'ethereum',
    token: 'usdt',
    value: 'usdt_erc',
  },
  {
    id: 3,
    name: 'arbitrum',
    token: 'usdt',
    value: 'usdt_arb',
  },
  {
    id: 4,
    name: 'optimism',
    token: 'usdt',
    value: 'usdt_op',
  },
  {
    id: 5,
    name: 'polygon',
    token: 'matic',
    value: 'matic_polygon',
  },
  {
    id: 6,
    name: 'polygon',
    token: 'usdt',
    value: 'usdt_polygon',
  },
  {
    id: 7,
    name: 'bsc',
    token: 'bnb',
    value: 'bnb_bsc',
  },
  {
    id: 8,
    name: 'bsc',
    token: 'usdt',
    value: 'usdt_bsc',
  },
  {
    id: 9,
    name: 'tron',
    token: 'usdt',
    value: 'usdt_trc',
  },
  {
    id: 10,
    name: 'tron',
    token: 'tron',
    value: 'tron',
  },
];

const ProfileWithdrawalForm = ({ toggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(currentState => !currentState);
  };

  return (
    <Formik
      initialValues={{
        address: '',
        amount: '',
        network: 'ethereum',
        token: 'eth',
        value: '',
      }}
      onSubmit={({ address, amount, network, value, token }, Formik) => {
        const amountNumber = Number(amount);

        if (!address || !amountNumber || !network || !token) {
          toast.error(t('notificationFormEmptyFields'));
          return;
        }

        const data = {
          amount: Number(amount),
          to_address: address,
          network: value,
          to_currency: token,
        };

        dispatch(API_WITHDRAWS_MERCHANT.createWithdraw(data));

        Formik.resetForm();
        toggle();
      }}
    >
      {({ handleChange, values, setFieldValue }) => {
        return (
          <>
            <StyledForm>
              <StyledFormTopWrp>
                <StyledTitle>Withdraw</StyledTitle>
                <StyledCrossBtn type="button" onClick={toggle}>
                  <Svg w_m="15.5" h_m="15.5" c="secondary">
                    <use href={`${sprite}#cross`}></use>
                  </Svg>

                  <p className="hidden">close</p>
                </StyledCrossBtn>
              </StyledFormTopWrp>
              {/* 
              <div
                style={{
                  display: 'flex',
                  marginBottom: '20px',
                  alignItems: 'center',
                }}
              >
                <Svg w_m="30" h_m="30" mg_r_d="15">
                  <use href={`${sprite}#info`}></use>
                </Svg>
                <StyledText w_d="397">
                  {t('withdrawalForm.info1')}{' '}
                  <StyledText>{t('withdrawalForm.highlighted')} </StyledText>
                  {t('withdrawalForm.info2')}.
                </StyledText>
              </div> */}

              <StyledFieldWrp>
                <StyledField
                  name="address"
                  type="text"
                  value={values.address}
                  placeholder={t('withdrawalForm.address')}
                  autoComplete="off"
                  onChange={handleChange('address')}
                />
              </StyledFieldWrp>

              <StyledFieldWrp>
                <StyledField
                  name="amount"
                  type="text"
                  value={values.amount}
                  placeholder={t('withdrawalForm.amount')}
                  autoComplete="off"
                  onChange={e => {
                    if (e.target.value === '') {
                      handleChange(e);
                    }

                    if (isNaN(e.target.value)) {
                      return;
                    }

                    handleChange(e);
                  }}
                />

                {/* <StyledMaxBtn
                  type="button"
                  onClick={() => setFieldValue('amount', balance)}
                >
                  {t('withdrawalForm.buttonMax')}
                </StyledMaxBtn> */}
              </StyledFieldWrp>

              {/* <div
                style={{
                  display: 'flex',
                  paddingLeft: '30px',
                  marginBottom: '28px',
                }}
              >
                <div style={{ marginRight: '64px' }}>
                  <StyledText>{t('withdrawalForm.info3')}</StyledText>
                  <StyledText>100 USDT</StyledText>
                </div>

                <div>
                  <StyledText>{t('withdrawalForm.info4')}</StyledText>
                  <StyledText> ~ 0.16 USDT</StyledText>
                </div>
              </div> */}

              <StyledFieldSelectWrp
                onClick={() => {
                  handleModalToggle();
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledIconWrp>
                    <Svg w_m="20" h_m="20">
                      <use
                        href={`${sprite}#network-${values.network}-logo-coin`}
                      ></use>
                    </Svg>
                  </StyledIconWrp>

                  <div>
                    <StyledText mg_b="4" fz="16" tr="capitalize">
                      {values.network}
                    </StyledText>
                    <StyledText fz="12" c="secondary" tr="uppercase">
                      {values.token}
                    </StyledText>
                  </div>
                </div>

                <StyledTickBtn
                  type="button"
                  style={{
                    transform: isModalOpen ? 'rotate(180deg)' : '',
                  }}
                >
                  <Svg w_m="16" h_m="16" c="secondary">
                    <use href={`${sprite}#tick`}></use>
                  </Svg>

                  <p className="hidden">tick</p>
                </StyledTickBtn>
              </StyledFieldSelectWrp>

              <StyledSubmitBtn type="submit">
                {t('withdrawalForm.buttonConfirm')}
              </StyledSubmitBtn>
            </StyledForm>

            <AnimatePresence onExitComplete={() => null} initial={false}>
              {isModalOpen && (
                <Modal toggle={handleModalToggle}>
                  <StyledSelectWrp>
                    <StyledText mg_b="8" c="secondary">
                      Choose Network
                    </StyledText>

                    <StyledSelectListWrp>
                      <StyledSelectList>
                        {network_list.map(el => {
                          return (
                            <StyledSelectItem
                              key={el.id}
                              onClick={() => {
                                setFieldValue('network', el.name);
                                setFieldValue('token', el.token);
                                setFieldValue('value', el.value);
                                handleModalToggle();
                              }}
                            >
                              <Svg w_m="28" h_m="28" mg_r_m="8">
                                <use
                                  href={`${sprite}#network-${el.name}-logo-coin`}
                                ></use>
                              </Svg>

                              <div>
                                <StyledText mg_b="4" fz="18" tr="uppercase">
                                  {el.token}
                                </StyledText>
                                <StyledText
                                  fz="12"
                                  c="secondary"
                                  tr="capitalize"
                                >
                                  {el.name}
                                </StyledText>
                              </div>
                            </StyledSelectItem>
                          );
                        })}
                      </StyledSelectList>
                    </StyledSelectListWrp>
                  </StyledSelectWrp>
                </Modal>
              )}
            </AnimatePresence>
          </>
        );
      }}
    </Formik>
  );
};

export default ProfileWithdrawalForm;
