import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { mq } from 'utils';

const commonStyles = css`
  ${mq.desktop} {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 999;
    pointer-events: none;
  }
`;

export const StyledOutline = styled(motion.div)`
  ${mq.desktop} {
    width: 28px;
    height: 28px;
    border: 2px solid rgba(134, 58, 246, 1);

    ${commonStyles}
  }
`;

export const StyledDot = styled.div`
  ${mq.desktop} {
    width: 12px;
    height: 12px;
    background: linear-gradient(
      to left,
      rgba(48, 124, 244, 1),
      rgba(134, 58, 246, 1),
      rgba(188, 55, 226, 1),
      rgba(239, 97, 92, 1)
    );
    ${commonStyles}
  }
`;
