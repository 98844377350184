const NumberInputValues = (e, handleChange) => {
  if (e.target.value.includes('.')) {
    return;
  }

  if (e.target.value === '') {
    handleChange(e);
  }

  if (/[a-zа-яё]/i.test(e.target.value)) {
    return;
  }

  handleChange(e);
};

export default NumberInputValues;
