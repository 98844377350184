import { toast } from 'react-toastify';
import i18n from 'i18n';
async function copyToClipboard(value) {
  try {
    await navigator.clipboard.writeText(value);
    toast.success(i18n.t('notificationCopyDone'));
  } catch (err) {
    console.error(err);
  }
}

export default copyToClipboard;
