import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import axios from 'axios';
import { toast } from 'react-toastify';

import {
  useCommonRouter,
  useErrorRouter,
  useMerchantRouter,
  useSwapRouter,
  useMarketplaceRouter,
} from 'routes';

import useApp from 'hooks/app/useApp';
import { useAuthMerchant, useProfileMerchant } from 'hooks/merchant';
import { useAuthMarketplace, useProfileMarketplace } from 'hooks/marketplace';

import { default as ACTIONS_APP } from './redux/app/actions';
import { default as API_AUTH_MERCHANT } from './redux/merchant/auth/operations';
import { default as API_PROFILE_MERCHANT } from './redux/merchant/user/operations';
import { default as API_ADMIN_MERCHANT } from './redux/merchant/admin/operations';
import { default as API_AUTH_MARKETPLACE } from './redux/marketplace/auth/operations';
import { default as API_PROFILE_MARKETPLACE } from './redux/marketplace/profile/operations';
import { default as API_BANK_MARKETPLACE } from './redux/marketplace/bank/operations';
import { default as ACTIONS_ORDERS_MARKETPLACE } from './redux/marketplace/orders/actions';
import { default as API_EXCHANGER_ADMIN_MARKETPLACE } from './redux/marketplace/exchangerAdmin/operations';
import { default as API_OPERATOR_MARKETPLACE } from './redux/marketplace/operator/operations';
import { default as API_ADMIN_MARKETPLACE } from './redux/marketplace/admin/operations';

import { SharedLayout, Loader } from 'components/common';

export const App = () => {
  const dispatch = useDispatch();
  // const { i18n } = useTranslation();

  const { product } = useApp();
  const { isLoadingMerchant, isLoggedInMerchant } = useAuthMerchant();
  const { role: roleMerchant, isLoadingRole: isLoadingRoleMerchant } =
    useProfileMerchant();
  const { isLoggedInMarketplace, isLoadingMarketplace } = useAuthMarketplace();
  const { role: roleMarketplace, isLoadingRole: isLoadingRoleMarketplace } =
    useProfileMarketplace();

  const commonRouter = useCommonRouter();
  const errorRouter = useErrorRouter();
  const MerchantRouter = useMerchantRouter();
  const SwapRouter = useSwapRouter();
  const MarketplaceRouter = useMarketplaceRouter();

  const lang = JSON.parse(localStorage.getItem('language'));

  useEffect(() => {
    dispatch(ACTIONS_APP.setScreenWidth(window.innerWidth));

    const handleWidthSet = e => {
      dispatch(ACTIONS_APP.setScreenWidth(e.target.innerWidth));
    };

    window.addEventListener('resize', handleWidthSet);

    return () => {
      window.removeEventListener('resize', handleWidthSet);
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      isLoggedInMarketplace &&
      (roleMarketplace === 'user' || roleMarketplace === 'trader')
    ) {
      let dealCreatedWs;

      const createChannel = () => {
        const token = JSON.parse(
          JSON.parse(localStorage.getItem('persist:marketplace')).token
        );

        dealCreatedWs = new WebSocket(
          `wss://market-backend.neutronx.com/ws_deal/deal_created?token=${token}`
        );
        // `ws://localhost:8000/ws_deal/deal_created?token=${token}`
      };

      const handleChannelOpening = () => {
        // toast.success('deal created socket connected');
        console.log('deal created socket connected');
      };

      const handleChannelMessage = e => {
        toast.success('You have a new Deal');
      };

      const handleChannelClosing = () => {
        // toast.success('deal created socket disconnected');
        console.log('deal created socket disconnected');
      };

      createChannel();

      dealCreatedWs.addEventListener('open', handleChannelOpening);

      dealCreatedWs.addEventListener('message', handleChannelMessage);

      dealCreatedWs.addEventListener('close', handleChannelClosing);

      return () => {
        if (dealCreatedWs.readyState === 1) {
          dealCreatedWs.close();
          return;
        }

        dealCreatedWs.removeEventListener('open', handleChannelOpening);
        dealCreatedWs.removeEventListener('message', handleChannelMessage);
        dealCreatedWs.removeEventListener('close', handleChannelClosing);
      };
    }
  }, [isLoggedInMarketplace, roleMarketplace]);

  useEffect(() => {
    if (
      isLoggedInMarketplace &&
      (roleMarketplace === 'user' || roleMarketplace === 'trader')
    ) {
      let dealPaidWs;

      const createChannel = () => {
        const token = JSON.parse(
          JSON.parse(localStorage.getItem('persist:marketplace')).token
        );

        dealPaidWs = new WebSocket(
          `wss://market-backend.neutronx.com/ws_deal/deal_paid?token=${token}`
          // `ws://localhost:8000/ws_deal/deal_paid?token=${token}`
        );
      };

      const handleChannelOpening = () => {
        // toast.success('deal paid socket connected');
        console.log('deal paid socket connected');
      };

      const handleChannelMessage = e => {
        toast.success('One of your deal was paid');

        dispatch(ACTIONS_ORDERS_MARKETPLACE.setDealPaid());
      };

      const handleChannelClosing = () => {
        // toast.success('deal paid socket disconnected');
        console.log('deal paid socket disconnected');
      };

      createChannel();

      dealPaidWs.addEventListener('open', handleChannelOpening);

      dealPaidWs.addEventListener('message', handleChannelMessage);

      dealPaidWs.addEventListener('close', handleChannelClosing);

      return () => {
        if (dealPaidWs.readyState === 1) {
          dealPaidWs.close();
          return;
        }

        dealPaidWs.removeEventListener('open', handleChannelOpening);
        dealPaidWs.removeEventListener('message', handleChannelMessage);
        dealPaidWs.removeEventListener('close', handleChannelClosing);
      };
    }
  }, [isLoggedInMarketplace, roleMarketplace, dispatch]);

  useEffect(() => {
    if (product === 'exchanger') {
      axios.defaults.baseURL = 'https://merchant-backend.neutronx.com';
      // axios.defaults.baseURL = 'http://localhost:8000/';
    }

    if (product === 'marketplace') {
      axios.defaults.baseURL = 'https://market-backend.neutronx.com';
      // axios.defaults.baseURL = 'http://localhost:8000/';
    }
  }, [product]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    dispatch(API_AUTH_MERCHANT.refreshUser()).then(data => {
      if (data.payload !== true) {
        return;
      }

      dispatch(API_PROFILE_MERCHANT.fetchRole());
    });

    dispatch(API_AUTH_MARKETPLACE.refreshUser()).then(data => {
      if (data.payload !== true) {
        return;
      }
      dispatch(API_PROFILE_MARKETPLACE.fetchRole());
    });
  }, [dispatch]);

  useEffect(() => {
    if (
      isLoggedInMerchant &&
      (roleMerchant === 'user' || roleMerchant === 'trader')
    ) {
      dispatch(API_PROFILE_MERCHANT.fetchUsername());
      dispatch(API_PROFILE_MERCHANT.fetchStatistics());
      dispatch(API_PROFILE_MERCHANT.fetchBalance());
      dispatch(API_PROFILE_MERCHANT.fetchWalletAddresses());

      if (roleMerchant === 'trader') {
        dispatch(API_PROFILE_MERCHANT.fetchTraderOnlineOfflineStatus());
      }
    }

    if (isLoggedInMerchant && roleMerchant === 'admin') {
      dispatch(API_ADMIN_MERCHANT.fetchTraders());
      dispatch(API_ADMIN_MERCHANT.fetchUserCount());
      dispatch(API_ADMIN_MERCHANT.fetchTransactionCount('30d'));
      dispatch(API_ADMIN_MERCHANT.fetchTransactionTurnover('30d'));
      dispatch(API_ADMIN_MERCHANT.fetchTransactionCurrencyCount('30d'));
    }
  }, [dispatch, isLoggedInMerchant, roleMerchant]);

  useEffect(() => {
    if (
      isLoggedInMarketplace &&
      (roleMarketplace === 'user' || roleMarketplace === 'trader')
    ) {
      dispatch(API_PROFILE_MARKETPLACE.fetchUserId());
      dispatch(API_PROFILE_MARKETPLACE.fetchUsername());
      dispatch(API_PROFILE_MARKETPLACE.fetchBalance());
      dispatch(API_PROFILE_MARKETPLACE.fetchStatistic());
      dispatch(API_PROFILE_MARKETPLACE.fetchDealsInfo());
      dispatch(API_BANK_MARKETPLACE.fetchBanks());
      dispatch(API_BANK_MARKETPLACE.fetchBankCards());
      dispatch(API_PROFILE_MARKETPLACE.fetchComments());
      dispatch(API_PROFILE_MARKETPLACE.fetchContragents());
      dispatch(API_PROFILE_MARKETPLACE.fetchFollowing());
      dispatch(API_PROFILE_MARKETPLACE.fetchFollowers());
      dispatch(API_PROFILE_MARKETPLACE.fetchWalletAddresses());
    }

    if (isLoggedInMarketplace && roleMarketplace === 'exchanger_admin') {
      dispatch(API_EXCHANGER_ADMIN_MARKETPLACE.fetchStatistic());
      dispatch(API_EXCHANGER_ADMIN_MARKETPLACE.fetchDeals());
      dispatch(API_EXCHANGER_ADMIN_MARKETPLACE.fetchTraders());
      dispatch(API_EXCHANGER_ADMIN_MARKETPLACE.fetchExchangerRate());
    }

    if (isLoggedInMarketplace && roleMarketplace === 'operator') {
      dispatch(API_PROFILE_MARKETPLACE.fetchUserId());
      dispatch(API_OPERATOR_MARKETPLACE.fetchRequests());
    }

    if (isLoggedInMarketplace && roleMarketplace === 'admin') {
      dispatch(API_ADMIN_MARKETPLACE.fetchTotalUsers());
      dispatch(API_ADMIN_MARKETPLACE.fetchUsersCountPerTime(9999));
      dispatch(API_ADMIN_MARKETPLACE.fetchDepositsAmountPerTime(9999));
      dispatch(API_ADMIN_MARKETPLACE.fetchWithdrawalsAmountPerTime(9999));
      dispatch(API_ADMIN_MARKETPLACE.fetchOrderCountAndAmoutPerTime(9999));
      dispatch(API_ADMIN_MARKETPLACE.fetchDealCountAndAmoutPerTime(9999));
    }
  }, [dispatch, isLoggedInMarketplace, roleMarketplace]);

  return isLoadingMerchant ||
    isLoadingRoleMerchant ||
    isLoadingMarketplace ||
    isLoadingRoleMarketplace ? (
    <Loader />
  ) : (
    <Routes>
      <Route path="/" element={<SharedLayout />}>
        {commonRouter}

        {MerchantRouter}

        {SwapRouter}

        {MarketplaceRouter}
      </Route>

      {errorRouter}
    </Routes>
  );
};
