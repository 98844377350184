import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const fetchTotalUsers = createAsyncThunk(
  'adminMarketplace/fetchTotalUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/admin/users');

      return response.data.total_user_count;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchUsersCountPerTime = createAsyncThunk(
  'adminMarketplace/fetchUsersPerTime',
  async (time, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/admin/users_register?given_time=${time}`
      );

      return response.data.total_user_register;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDepositsAmountPerTime = createAsyncThunk(
  'adminMarketplace/fetchDepositsPerTime',
  async (time, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/deposit?given_time=${time}`);

      return response.data.deposit_amount;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchWithdrawalsAmountPerTime = createAsyncThunk(
  'adminMarketplace/fetchWithdrawalsPerTime',
  async (time, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/admin/withdraw_funds?given_time=${time}`
      );

      return response.data.total_withdraw_amount;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchOrderCountAndAmoutPerTime = createAsyncThunk(
  'adminMarketplace/fetchOrderCountAndAmoutPerTime',
  async (time, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/admin/orders_created?given_time=${time}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDealCountAndAmoutPerTime = createAsyncThunk(
  'adminMarketplace/fetchDealCountAndAmoutPerTime',
  async (time, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/admin/deals_created?given_time=${time}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchOperators = createAsyncThunk(
  'adminMarketplace/fetchOperators',
  async (_, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(document.location.search);
      const page = searchParams.get('page');
      const per_page = searchParams.get('per_page');

      const response = await axios.get(
        `/admin/operators?page=${page}&per_page=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const createOperator = createAsyncThunk(
  'adminMarketplace/createOperator',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/admin/operators', data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const removeOperator = createAsyncThunk(
  'adminMarketplace/removeOperator',
  async (id, { rejectWithValue }) => {
    try {
      const data = { operator_id: id };

      await axios.delete('/admin/operators', { data });

      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchExchangers = createAsyncThunk(
  'adminMarketplace/fetchExchangers',
  async (_, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(document.location.search);
      const page = searchParams.get('page');
      const per_page = searchParams.get('per_page');

      const response = await axios.get(
        `/admin/market_exchangers?page=${page}&per_page=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const createExchanger = createAsyncThunk(
  'adminMarketplace/createExchanger',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/admin/market_exchangers', data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const removeExchanger = createAsyncThunk(
  'adminMarketplace/removeExchanger',
  async (id, { rejectWithValue }) => {
    try {
      const data = { exchanger_id: id };

      await axios.delete('/admin/market_exchangers', { data });

      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTransactions = createAsyncThunk(
  'adminMarketplace/fetchTransactions',
  async (_, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(document.location.search);
      const page = searchParams.get('page');
      const per_page = searchParams.get('per_page');

      const response = await axios.get(
        `/admin/withdraw_history?page=${page}&per_page=${per_page}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = {
  fetchTotalUsers,
  fetchUsersCountPerTime,
  fetchDepositsAmountPerTime,
  fetchWithdrawalsAmountPerTime,
  fetchOrderCountAndAmoutPerTime,
  fetchDealCountAndAmoutPerTime,
  fetchOperators,
  createOperator,
  removeOperator,
  fetchExchangers,
  createExchanger,
  removeExchanger,
  fetchTransactions,
};

export default API;
