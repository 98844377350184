import { createSlice } from '@reduxjs/toolkit';
import API from './operations';
import ACTIONS from './actions';

const publicProfileSlice = createSlice({
  name: 'publicProfileMarketplace',

  initialState: {
    statistic: {},
    followers: [],
    comments: [],
    isLoading: false,
    error: null,
  },

  extraReducers: builder => {
    builder.addCase(ACTIONS.setDefault(), state => {
      state.statistic = {};
      state.followers = [];
      state.comments = [];
    });

    builder.addCase(API.fetchStatistic.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchStatistic.fulfilled, (state, action) => {
      state.isLoading = false;
      state.statistic = action.payload;
    });

    builder.addCase(API.fetchStatistic.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchFollowers.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchFollowers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.followers = action.payload;
    });

    builder.addCase(API.fetchFollowers.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.fetchComments.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.fetchComments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.comments = action.payload;
    });

    builder.addCase(API.fetchComments.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.createComment.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.createComment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.comments.push(action.payload);
    });

    builder.addCase(API.createComment.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.blockWithdraw.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.blockWithdraw.fulfilled, state => {
      state.isLoading = false;
      state.statistic.withdraw_available = false;
    });

    builder.addCase(API.blockWithdraw.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(API.unblockWithdraw.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(API.unblockWithdraw.fulfilled, state => {
      state.isLoading = false;
      state.statistic.withdraw_available = true;
    });

    builder.addCase(API.unblockWithdraw.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

const publicProfileMarketplaceReducer = publicProfileSlice.reducer;

export default publicProfileMarketplaceReducer;
