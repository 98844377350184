import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useAuthMerchant, useProfileMerchant } from 'hooks/merchant';

const CreateTransaction = lazy(() =>
  import('pages/merchant/CreateTransaction')
);
const Profile = lazy(() => import('pages/merchant/Profile'));
const ProfileAccount = lazy(() => import('pages/merchant/ProfileAccount'));
const ProfileUSDTWallets = lazy(() =>
  import('pages/merchant/ProfileUSDTWallets')
);
const ProfileTransactions = lazy(() =>
  import('pages/merchant/ProfileTransactions')
);
const ProfileHashes = lazy(() => import('pages/merchant/ProfileHashes'));
const ProfileWithdraws = lazy(() => import('pages/merchant/ProfileWithdraws'));
const Admin = lazy(() => import('pages/merchant/Admin'));
const AdminTraders = lazy(() => import('pages/merchant/AdminTraders'));
// const AdminTransactions = lazy(() =>
//   import('pages/merchant/AdminTransactions')
// );

const useMerchantRouter = () => {
  const { isLoggedInMerchant } = useAuthMerchant();
  const { role, isLoadingRole } = useProfileMerchant();

  return (
    <>
      <Route
        path="exchanger/create_transaction"
        element={<CreateTransaction />}
      />

      {isLoggedInMerchant &&
        !isLoadingRole &&
        (role === 'user' || role === 'trader') && (
          <Route path="exchanger/profile" element={<Profile />}>
            <Route path="account" element={<ProfileAccount />} />
            <Route path="usdt_wallets" element={<ProfileUSDTWallets />} />
            <Route path="transactions" element={<ProfileTransactions />} />
            <Route path="hashes" element={<ProfileHashes />} />
            <Route path="withdraws" element={<ProfileWithdraws />} />
          </Route>
        )}

      {isLoggedInMerchant && !isLoadingRole && role === 'admin' && (
        <Route path="exchanger/admin" element={<Admin />}>
          <Route path="traders" element={<AdminTraders />} />
          {/* <Route path="transactions" element={<AdminTransactions />} /> */}
        </Route>
      )}
    </>
  );
};

export default useMerchantRouter;
