const role = state => state.profileMarketplace.role;
const isLoadingRole = state => state.profileMarketplace.isLoadingRole;
const id = state => state.profileMarketplace.id;
const username = state => state.profileMarketplace.username;
const balance = state => state.profileMarketplace.balance;
const statistic = state => state.profileMarketplace.statistic;
const deals_info = state => state.profileMarketplace.deals_info;
const orders = state => state.profileMarketplace.orders;
const orders_pages = state => state.profileMarketplace.orders_pages;
const deals = state => state.profileMarketplace.deals;
const deals_pages = state => state.profileMarketplace.deals_pages;
const contragents = state => state.profileMarketplace.contragents;
const comments = state => state.profileMarketplace.comments;
const followers = state => state.profileMarketplace.followers;
const following = state => state.profileMarketplace.following;
const usdt_wallet_addresses = state =>
  state.profileMarketplace.usdt_wallet_addresses;
const isLoading = state => state.profileMarketplace.isLoading;
const error = state => state.profileMarketplace.error;

const select = {
  role,
  isLoadingRole,
  id,
  username,
  balance,
  statistic,
  deals_info,
  orders,
  orders_pages,
  deals,
  deals_pages,
  contragents,
  comments,
  followers,
  following,
  usdt_wallet_addresses,
  isLoading,
  error,
};

export default select;
