import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const fetchBanks = createAsyncThunk(
  'bankMarketplace/fetchBanks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/banks/info');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchBankCards = createAsyncThunk(
  'bankMarketplace/fetchBankCards',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/banks');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const addBankCard = createAsyncThunk(
  'bankMarketplace/addBankCard',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/banks', data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const removeBankCard = createAsyncThunk(
  'bankMarketplace/removeBankCard',
  async (card_id, { rejectWithValue }) => {
    try {
      const data = { card_id };

      await axios.delete('/banks', {
        data,
      });

      return card_id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const replaceBankCard = createAsyncThunk(
  'bankMarketplace/replaceBankCard',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put('/banks', data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const API = {
  fetchBanks,
  fetchBankCards,
  addBankCard,
  replaceBankCard,
  removeBankCard,
};

export default API;
