import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import {
  StyledItem,
  StyledText,
  StyledSpan,
} from './ProfileHashListItem.styled';

const ProfileHashListItem = forwardRef(({ data }, ref) => {
  const { transaction_id, hash, currency, created_at } = data;

  const createdLocalTime = new Date(created_at + 'Z');

  const formattedCreatedLocalTime = format(
    createdLocalTime,
    'dd.LL.yyyy HH:mm'
  );

  return (
    <StyledItem ref={ref}>
      <StyledText>
        <StyledSpan>{transaction_id}</StyledSpan>
      </StyledText>

      <StyledText tr="uppercase">{currency}</StyledText>

      <StyledText>
        <StyledSpan>{hash}</StyledSpan>
      </StyledText>

      <StyledText>
        <StyledSpan>{formattedCreatedLocalTime}</StyledSpan>
      </StyledText>
    </StyledItem>
  );
});

export default motion(ProfileHashListItem);
