import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

let provider;
let signer;
let walletAddress;

const networks = {
  ethereum: {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    rpcUrls: ['https://mainnet.infura.io/v3/'], // Заміни на свій Infura Project ID
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://etherscan.io/'],
  },
  arbitrum: {
    chainId: '0xa4b1',
    chainName: 'Arbitrum One',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://arbiscan.io/'],
  },
  optimism: {
    chainId: '0xa',
    chainName: 'Optimism',
    rpcUrls: ['https://mainnet.optimism.io/'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://optimistic.etherscan.io/'],
  },
  bsc: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://bscscan.com/'],
  },
};

const usdtABI = [
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { name: '_owner', type: 'address' },
      { name: '_spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', type: 'uint256' }],
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_spender', type: 'address' },
      { name: '_value', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', type: 'bool' }],
    type: 'function',
  },
];

async function switchNetwork(networkConfig) {
  if (typeof window.ethereum !== 'undefined') {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: networkConfig.chainId }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networkConfig],
          });
        } catch (addError) {
          console.error(`Failed to add ${networkConfig.chainName}:`, addError);
        }
      } else {
        console.error(
          `Failed to switch to ${networkConfig.chainName}:`,
          switchError
        );
      }
    }
  } else {
    console.error('Metamask не встановлений');
  }
}

async function getUSDTBalance(address, network) {
  let provider, usdtContract;

  const networkConfig = {
    ERC20: {
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      rpcUrl: 'https://eth.llamarpc.com',
      decimals: 6,
    },
    ARB: {
      address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
      rpcUrl: 'https://arb1.arbitrum.io/rpc',
      decimals: 6,
    },
    OP: {
      address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
      rpcUrl: 'https://mainnet.optimism.io',
      decimals: 6,
    },
    BSC: {
      address: '0x55d398326f99059fF775485246999027B3197955',
      rpcUrl: 'https://bsc-dataseed.binance.org/',
      decimals: 18,
    },
    AVAX: {
      address: '0xc7198437980c041c805a1edcba50c1ce5db95118',
      rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
      decimals: 6,
    },
  };

  const networkDetails = networkConfig[network];

  if (!networkDetails) {
    console.error('Invalid network');
    return;
  }

  provider = new ethers.providers.JsonRpcProvider(networkDetails.rpcUrl);

  usdtContract = new ethers.Contract(networkDetails.address, usdtABI, provider);

  try {
    const balance = await usdtContract.balanceOf(address);
    const formattedBalance = ethers.utils.formatUnits(
      balance,
      networkDetails.decimals
    );

    return formattedBalance;
  } catch (error) {
    console.error('Error fetching balance:', error);
  }
}

async function fetchCoinUsdtPrice(coin) {
  const response = await fetch(
    `https://api.binance.com/api/v3/avgPrice?symbol=${coin}USDT`
  );

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  const responseJson = await response.json();
  return responseJson;
}

if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
}

const onConnectMetaMask = createAsyncThunk(
  'swap/onConnectMetaMask',
  async (_, { rejectWithValue }) => {
    try {
      provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();
      const networkChainId = await signer.getChainId();
      const accountResponse = await provider.send('eth_requestAccounts', []);
      walletAddress = await signer.getAddress();

      const data = {
        account: accountResponse[0],
        networkChainId: networkChainId,
      };

      return data;
    } catch (error) {
      toast.error(error.message);

      return rejectWithValue(error);
    }
  }
);

const getEthereumMainnetBalance = createAsyncThunk(
  'swap/getEthereumMainnetBalance',
  async (_, { rejectWithValue }) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://mainnet.infura.io/v3/c70ecb5825a545fdbe755a811e1d93f2'
      );

      const balance = await provider.getBalance(walletAddress);
      const formattedBalance = ethers.utils.formatEther(balance, 18);

      const usdtPrice = await fetchCoinUsdtPrice('ETH');
      const gas = await provider.getFeeData();
      const formattedGasEther = ethers.utils.formatUnits(gas.gasPrice, 'ether');
      const formattedGasWei = ethers.utils.formatUnits(gas.gasPrice, 'wei');

      const data = {
        balance: Number(formattedBalance),
        usdtPrice: Number(usdtPrice.price),
        gasEther: formattedGasEther,
        gasWei: formattedGasWei,
      };

      return data;
    } catch (error) {
      console.log(error);

      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getEthereumUsdtBalance = createAsyncThunk(
  'swap/getEthereumUsdtBalance',
  async (_, { rejectWithValue }) => {
    try {
      const balance = await getUSDTBalance(walletAddress, 'ERC20');

      const data = {
        balance: Number(balance),
        usdtPrice: 1,
      };

      return data;
    } catch (error) {
      console.log(error);

      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getPolygonMainnetBalance = createAsyncThunk(
  'swap/getPolygonMainnetBalance',
  async (_, { rejectWithValue }) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://polygon-mainnet.infura.io/v3/c70ecb5825a545fdbe755a811e1d93f2'
      );

      const balance = await provider.getBalance(walletAddress);
      const formattedBalance = ethers.utils.formatEther(balance, 18);
      const usdtPrice = await fetchCoinUsdtPrice('MATIC');
      const gas = await provider.getFeeData();
      const formattedGasEther = ethers.utils.formatUnits(gas.gasPrice, 'ether');
      const formattedGasWei = ethers.utils.formatUnits(gas.gasPrice, 'wei');

      const data = {
        balance: Number(formattedBalance),
        usdtPrice: Number(usdtPrice.price),
        gasEther: formattedGasEther,
        gasWei: formattedGasWei,
      };

      return data;
    } catch (error) {
      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getOptimismMainnetBalance = createAsyncThunk(
  'swap/getOptimismMainnetBalance',
  async (_, { rejectWithValue }) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://optimism-mainnet.infura.io/v3/c70ecb5825a545fdbe755a811e1d93f2'
      );
      const balance = await provider.getBalance(walletAddress);
      const formattedBalanceEther = ethers.utils.formatEther(balance, 18);
      const usdtPrice = await fetchCoinUsdtPrice('ETH');
      const gas = await provider.getFeeData();
      const formattedGasEther = ethers.utils.formatUnits(gas.gasPrice, 'ether');
      const formattedGasWei = ethers.utils.formatUnits(gas.gasPrice, 'wei');

      const data = {
        balance: Number(formattedBalanceEther),
        usdtPrice: Number(usdtPrice.price),
        gasEther: formattedGasEther,
        gasWei: formattedGasWei,
      };

      return data;
    } catch (error) {
      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getOptimismUsdtBalance = createAsyncThunk(
  'swap/getOptimismUsdtBalance',
  async (_, { rejectWithValue }) => {
    try {
      const balance = await getUSDTBalance(walletAddress, 'OP');

      const data = {
        balance: Number(balance),
        usdtPrice: 1,
      };

      return data;
    } catch (error) {
      console.log(error);

      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getArbitrumMainnetBalance = createAsyncThunk(
  'swap/getArbitrumMainnetBalance',
  async (_, { rejectWithValue }) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://arbitrum-mainnet.infura.io/v3/c70ecb5825a545fdbe755a811e1d93f2'
      );

      const balance = await provider.getBalance(walletAddress);
      const formattedBalance = ethers.utils.formatEther(balance, 18);
      const usdtPrice = await fetchCoinUsdtPrice('ETH');
      const gas = await provider.getFeeData();
      const formattedGasEther = ethers.utils.formatUnits(gas.gasPrice, 'ether');
      const formattedGasWei = ethers.utils.formatUnits(gas.gasPrice, 'wei');

      const data = {
        balance: Number(formattedBalance),
        usdtPrice: Number(usdtPrice.price),
        gasEther: formattedGasEther,
        gasWei: formattedGasWei,
      };

      return data;
    } catch (error) {
      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getArbitrumUsdtBalance = createAsyncThunk(
  'swap/getArbitrumUsdtBalance',
  async (_, { rejectWithValue }) => {
    try {
      const balance = await getUSDTBalance(walletAddress, 'ARB');

      const data = {
        balance: Number(balance),
        usdtPrice: 1,
      };

      return data;
    } catch (error) {
      console.log(error);

      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getAvalancheMainnetBalance = createAsyncThunk(
  'swap/getAvalancheMainnetBalance',
  async (_, { rejectWithValue }) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://avalanche-mainnet.infura.io/v3/c70ecb5825a545fdbe755a811e1d93f2'
      );

      const balance = await provider.getBalance(walletAddress);
      const formattedBalance = ethers.utils.formatEther(balance, 18);
      const usdtPrice = await fetchCoinUsdtPrice('AVAX');
      const gas = await provider.getFeeData();
      const formattedGasEther = ethers.utils.formatUnits(gas.gasPrice, 'ether');
      const formattedGasWei = ethers.utils.formatUnits(gas.gasPrice, 'wei');

      const data = {
        balance: Number(formattedBalance),
        usdtPrice: Number(usdtPrice.price),
        gasEther: formattedGasEther,
        gasWei: formattedGasWei,
      };

      return data;
    } catch (error) {
      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getBscMainnetBalance = createAsyncThunk(
  'swap/getBnbMainnetBalance',
  async (_, { rejectWithValue }) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://bsc-mainnet.infura.io/v3/c70ecb5825a545fdbe755a811e1d93f2'
      );

      const balance = await provider.getBalance(walletAddress);
      const formattedBalance = ethers.utils.formatEther(balance, 18);
      const usdtPrice = await fetchCoinUsdtPrice('BNB');
      const gas = await provider.getFeeData();
      const formattedGasEther = ethers.utils.formatUnits(gas.gasPrice, 'ether');
      const formattedGasWei = ethers.utils.formatUnits(gas.gasPrice, 'wei');

      const data = {
        balance: Number(formattedBalance),
        usdtPrice: Number(usdtPrice.price),
        gasEther: formattedGasEther,
        gasWei: formattedGasWei,
      };

      return data;
    } catch (error) {
      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getBscUsdtBalance = createAsyncThunk(
  'swap/getBscUsdtBalance',
  async (_, { rejectWithValue }) => {
    try {
      const balance = await getUSDTBalance(walletAddress, 'BSC');

      const data = {
        balance: Number(balance),
        usdtPrice: 1,
      };

      return data;
    } catch (error) {
      console.log(error);

      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const getMantleMainnetBalance = createAsyncThunk(
  'swap/getMantleMainnetBalance',
  async (_, { rejectWithValue }) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://mantle-mainnet.infura.io/v3/c70ecb5825a545fdbe755a811e1d93f2'
      );
      const balance = await provider.getBalance(walletAddress);
      const formattedBalance = ethers.utils.formatEther(balance, 18);

      const usdtPrice = await fetchCoinUsdtPrice('ETH');
      const gas = await provider.getFeeData();
      const formattedGasEther = ethers.utils.formatUnits(gas.gasPrice, 'ether');
      const formattedGasWei = ethers.utils.formatUnits(gas.gasPrice, 'wei');

      const data = {
        balance: Number(formattedBalance),
        usdtPrice: Number(usdtPrice.price),
        gasEther: formattedGasEther,
        gasWei: formattedGasWei,
      };

      return data;
    } catch (error) {
      toast.error(error.message);

      return rejectWithValue(error.message);
    }
  }
);

const swapOptimismEth = createAsyncThunk(
  'swap/optimismEth',
  async (data, { rejectWithValue }) => {
    const { dstChainId, amount } = data;
    const ABI = [
      'function performSwap(uint16 dstChainId, address toAddress, uint256 amountLD, uint256 minAmountLD) external payable',
    ];

    try {
      // await switchNetwork(networks.optimism);

      const contractAddress = '0xe79cBFaa6649fc9D06127d4b80173B437Fe4f786';
      const contract = new ethers.Contract(contractAddress, ABI, signer);
      const toAddress = walletAddress;
      const amountLD = ethers.utils.parseEther(amount);
      const minAmountLD = ethers.utils.parseEther((amount * 0.95).toString());
      const msgValue = amountLD.add(ethers.utils.parseEther('0.004'));

      const currentChainId = await signer.getChainId();
      const optimismChainId = 10;

      if (currentChainId !== optimismChainId) {
        throw new Error('Signer is not connected to the Optimism network');
      }

      const tx = await contract.performSwap(
        dstChainId,
        toAddress,
        amountLD,
        minAmountLD,
        { value: msgValue, gasLimit: 1000000 }
      );

      await tx.wait();

      return tx.hash;
    } catch (error) {
      console.log(error);

      toast.error('Insufficient balance');

      return rejectWithValue(error.message);
    }
  }
);

const swapArbitrumEth = createAsyncThunk(
  'swap/swapArbitrumEth',
  async (data, { rejectWithValue }) => {
    const { dstChainId, amount } = data;
    // await switchNetwork(networks.arbitrum);

    try {
      const contractAddress = '0xf5559B6B2715f369Bc9B856469c4AA545907Ec8c';
      const ABI = [
        'function performSwap(uint16 dstChainId, address toAddress, uint256 amountLD, uint256 minAmountLD) external payable',
      ];
      const contract = new ethers.Contract(contractAddress, ABI, signer);
      const toAddress = walletAddress;
      const amountLD = ethers.utils.parseEther(amount);
      const minAmountLD = ethers.utils.parseEther((amount * 0.95).toString());
      const msgValue = amountLD.add(ethers.utils.parseEther('0.004'));
      const currentChainId = await signer.getChainId();
      const arbitrumChainId = 42161;

      if (currentChainId !== arbitrumChainId) {
        throw new Error('Signer is not connected to the Optimism network');
      }

      const tx = await contract.performSwap(
        dstChainId,
        toAddress,
        amountLD,
        minAmountLD,
        { value: msgValue, gasLimit: 1000000 }
      );

      await tx.wait();

      return tx.hash;
    } catch (error) {
      console.log(error);

      toast.error('Insufficient balance');

      return rejectWithValue(error.message);
    }
  }
);

const swapEthereumEth = createAsyncThunk(
  'swap/ethereumEth',
  async (data, { rejectWithValue }) => {
    const { dstChainId, amount } = data;
    // await switchNetwork(networks.ethereum);
    try {
      const contractAddress = '0x6851Bbc6f53c51A2708F8874c8936CF3911A63A2';
      const contractABI = [
        'function performSwap(uint16 dstChainId, address toAddress, uint256 amountLD, uint256 minAmountLD) external payable',
      ];
      const contract = new ethers.Contract(
        contractAddress,
        contractABI,
        signer
      );

      const toAddress = walletAddress;
      const amountLD = ethers.utils.parseEther(amount);
      const minAmountLD = ethers.utils.parseEther((amount * 0.95).toString());
      const msgValue = amountLD.add(ethers.utils.parseEther('0.004'));

      const currentChainId = await signer.getChainId();
      const ercChainId = 1;

      if (currentChainId !== ercChainId) {
        throw new Error('Signer is not connected to the Optimism network');
      }
      const tx = await contract.performSwap(
        dstChainId,
        toAddress,
        amountLD,
        minAmountLD,
        { value: msgValue, gasLimit: 1000000 }
      );

      await tx.wait();

      return tx.hash;
    } catch (error) {
      console.log(error);

      toast.error('Insufficient balance');

      return rejectWithValue(error.message);
    }
  }
);

const swapArbitrumUsdt = createAsyncThunk(
  'swap/swapArbitrumUsdt',
  async (data, { rejectWithValue }) => {
    const { dstChainId, amount } = data;

    try {
      // await switchNetwork(networks.arbitrum);
      const contractAddress = '0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614';
      const usdtAddress = '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9';
      const usdtContract = new ethers.Contract(usdtAddress, usdtABI, signer);
      const allowance = await usdtContract.allowance(
        walletAddress,
        contractAddress
      );

      const amountLD = ethers.utils.parseUnits(amount, 6);

      if (allowance.lt(amountLD)) {
        const maxUint = ethers.constants.MaxUint256;
        const approveTx = await usdtContract.approve(contractAddress, maxUint);
        await approveTx.wait();
      }
      const stargateRouterABI = [
        {
          inputs: [
            { internalType: 'uint16', name: '_dstChainId', type: 'uint16' },
            { internalType: 'uint256', name: '_srcPoolId', type: 'uint256' },
            { internalType: 'uint256', name: '_dstPoolId', type: 'uint256' },
            {
              internalType: 'address payable',
              name: '_refundAddress',
              type: 'address',
            },
            { internalType: 'uint256', name: '_amountLD', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountLD', type: 'uint256' },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'dstGasForCall',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'dstNativeAmount',
                  type: 'uint256',
                },
                { internalType: 'bytes', name: 'dstNativeAddr', type: 'bytes' },
              ],
              internalType: 'struct IStargateRouter.lzTxObj',
              name: '_lzTxParams',
              type: 'tuple',
            },
            { internalType: 'bytes', name: '_to', type: 'bytes' },
            { internalType: 'bytes', name: '_payload', type: 'bytes' },
          ],
          name: 'swap',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ];

      const contract = new ethers.Contract(
        contractAddress,
        stargateRouterABI,
        signer
      );

      const minAmountLD = ethers.utils.parseUnits('0.9', 6);

      const fee = ethers.utils.parseEther('0.04');

      const txParams = {
        dstGasForCall: 0,
        dstNativeAmount: 0,
        dstNativeAddr: '0x',
      };

      const currentChainId = await signer.getChainId();
      const arbitrumChainId = 42161;

      if (currentChainId !== arbitrumChainId) {
        throw new Error('Signer is not connected to the Optimism network');
      }

      const tx = await contract.swap(
        dstChainId,
        2,
        2, // менять в зависимости от токена
        walletAddress,
        amountLD,
        minAmountLD,
        txParams,
        walletAddress,
        '0x',
        { value: fee }
      );

      return tx.hash;
    } catch (error) {
      console.log(error);

      toast.error('Insufficient balance');

      return rejectWithValue(error);
    }
  }
);

const swapErcUsdt = createAsyncThunk(
  'swap/swapErcUsdt',
  async (data, { rejectWithValue }) => {
    const { dstChainId, amount } = data;

    try {
      // await switchNetwork(networks.ethereum);
      const contractAddress = '0x8731d54E9D02c286767d56ac03e8037C07e01e98';
      const usdtAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
      const usdtContract = new ethers.Contract(usdtAddress, usdtABI, signer);
      const allowance = await usdtContract.allowance(
        walletAddress,
        contractAddress
      );
      const amountLD = ethers.utils.parseUnits(amount, 6);

      if (allowance.lt(amountLD)) {
        const maxUint = ethers.constants.MaxUint256;
        const approveTx = await usdtContract.approve(contractAddress, maxUint);
        await approveTx.wait();
      }

      const stargateRouterABI = [
        {
          inputs: [
            { internalType: 'uint16', name: '_dstChainId', type: 'uint16' },
            { internalType: 'uint256', name: '_srcPoolId', type: 'uint256' },
            { internalType: 'uint256', name: '_dstPoolId', type: 'uint256' },
            {
              internalType: 'address payable',
              name: '_refundAddress',
              type: 'address',
            },
            { internalType: 'uint256', name: '_amountLD', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountLD', type: 'uint256' },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'dstGasForCall',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'dstNativeAmount',
                  type: 'uint256',
                },
                {
                  internalType: 'bytes',
                  name: 'dstNativeAddr',
                  type: 'bytes',
                },
              ],
              internalType: 'struct IStargateRouter.lzTxObj',
              name: '_lzTxParams',
              type: 'tuple',
            },
            { internalType: 'bytes', name: '_to', type: 'bytes' },
            { internalType: 'bytes', name: '_payload', type: 'bytes' },
          ],
          name: 'swap',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ];

      const contract = new ethers.Contract(
        contractAddress,
        stargateRouterABI,
        signer
      );

      const minAmountLD = ethers.utils.parseUnits('0.2', 6);
      const fee = ethers.utils.parseEther('0.004');

      const txParams = {
        dstGasForCall: 0,
        dstNativeAmount: 0,
        dstNativeAddr: ethers.constants.AddressZero,
      };

      const currentChainId = await signer.getChainId();
      const ercChainId = 1;

      if (currentChainId !== ercChainId) {
        throw new Error('Signer is not connected to the Optimism network');
      }

      const tx = await contract.swap(
        dstChainId,
        2,
        2,
        walletAddress,
        amountLD,
        minAmountLD,
        txParams,
        walletAddress,
        '0x',
        { value: fee }
      );

      return tx.hash;
    } catch (error) {
      console.log(error);

      toast.error('Insufficient balance');

      return rejectWithValue(error);
    }
  }
);

const swapAvaxUsdt = createAsyncThunk(
  'swap/swapAvaxUsdt',
  async (data, { rejectWithValue }) => {
    const { dstChainId, amount } = data;

    try {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://avalanche-mainnet.infura.io/v3/409a781dd75d4015ac0aa6588f6d5b7e'
      );
      const wallet = new ethers.Wallet(
        'af21f029c653e4858e812fb13686612d27e6b1793650cb3ddabbedf582d91343',
        provider
      );

      const contractAddress = '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd'; // Stargate Router Contract Address
      const usdtAddress = '0x29e38769f23701A2e4A8Ef0492e19dA4604Be62c'; // USDT Contract Address on Avalanche
      const stargateRouterABI = [
        {
          inputs: [
            { internalType: 'uint16', name: '_dstChainId', type: 'uint16' },
            { internalType: 'uint256', name: '_srcPoolId', type: 'uint256' },
            { internalType: 'uint256', name: '_dstPoolId', type: 'uint256' },
            {
              internalType: 'address payable',
              name: '_refundAddress',
              type: 'address',
            },
            { internalType: 'uint256', name: '_amountLD', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountLD', type: 'uint256' },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'dstGasForCall',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'dstNativeAmount',
                  type: 'uint256',
                },
                { internalType: 'bytes', name: 'dstNativeAddr', type: 'bytes' },
              ],
              internalType: 'struct IStargateRouter.lzTxObj',
              name: '_lzTxParams',
              type: 'tuple',
            },
            { internalType: 'bytes', name: '_to', type: 'bytes' },
            { internalType: 'bytes', name: '_payload', type: 'bytes' },
          ],
          name: 'swap',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ];

      const erc20ABI = [
        'function allowance(address owner, address spender) view returns (uint256)',
        'function approve(address spender, uint256 amount) returns (bool)',
      ];

      const usdtContract = new ethers.Contract(usdtAddress, erc20ABI, wallet);
      const contract = new ethers.Contract(
        contractAddress,
        stargateRouterABI,
        wallet
      );

      const amountLD = ethers.utils.parseUnits(amount, 6);
      const minAmountLD = ethers.utils.parseUnits('1', 6);
      const fee = ethers.utils.parseEther('0.3');

      // Перевіряємо дозволи
      const allowance = await usdtContract.allowance(
        wallet.address,
        contractAddress
      );

      if (allowance.lt(amountLD)) {
        // console.log(
        //   'Немає достатнього дозволу. Встановлюємо максимально можливий дозвол...'
        // );
        const maxApproval = ethers.constants.MaxUint256; // Встановлюємо максимально можливий дозвол
        const approveTx = await usdtContract.approve(
          contractAddress,
          maxApproval
        );
        await approveTx.wait();
        // console.log('Дозвіл надано:', approveTx.hash);
        return approveTx.hash;
      } else {
        // console.log('Достатній дозвіл уже надано.');
      }

      const txParams = {
        dstGasForCall: 0,
        dstNativeAmount: 0,
        dstNativeAddr: wallet.address,
      };

      const gasLimit = ethers.BigNumber.from('300000'); // Наприклад, 300,000 газу
      const gasPrice = ethers.utils.parseUnits('30', 'gwei'); // Наприклад, 30 Gwei

      const tx = await contract.swap(
        dstChainId,
        2, // Pool ID на вихідному ланцюзі
        2, // Pool ID на цільовому ланцюзі
        wallet.address,
        amountLD,
        minAmountLD,
        txParams,
        wallet.address,
        '0x',
        {
          value: fee,
          gasLimit: gasLimit, // Встановлюємо ліміт газу
          gasPrice: gasPrice, // Встановлюємо ціну газу
        }
      );

      return tx.hash;
    } catch (error) {
      console.log(error);

      toast.error('Insufficient balance');

      return rejectWithValue(error);
    }
  }
);

const swapPolygonUsdt = createAsyncThunk(
  'swap/swapPolygonUsdt',
  async (data, { rejectWithValue }) => {
    const { dstChainId, amount } = data;

    try {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://polygon-mainnet.infura.io/v3/409a781dd75d4015ac0aa6588f6d5b7e'
      );
      const wallet = new ethers.Wallet(
        'af21f029c653e4858e812fb13686612d27e6b1793650cb3ddabbedf582d91343',
        provider
      );

      const contractAddress = '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd';
      const usdtAddress = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
      const stargateRouterABI = [
        {
          inputs: [
            { internalType: 'uint16', name: '_dstChainId', type: 'uint16' },
            { internalType: 'uint256', name: '_srcPoolId', type: 'uint256' },
            { internalType: 'uint256', name: '_dstPoolId', type: 'uint256' },
            {
              internalType: 'address payable',
              name: '_refundAddress',
              type: 'address',
            },
            { internalType: 'uint256', name: '_amountLD', type: 'uint256' },
            { internalType: 'uint256', name: '_minAmountLD', type: 'uint256' },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'dstGasForCall',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'dstNativeAmount',
                  type: 'uint256',
                },
                { internalType: 'bytes', name: 'dstNativeAddr', type: 'bytes' },
              ],
              internalType: 'struct IStargateRouter.lzTxObj',
              name: '_lzTxParams',
              type: 'tuple',
            },
            { internalType: 'bytes', name: '_to', type: 'bytes' },
            { internalType: 'bytes', name: '_payload', type: 'bytes' },
          ],
          name: 'swap',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ];

      const erc20ABI = [
        'function allowance(address owner, address spender) view returns (uint256)',
        'function approve(address spender, uint256 amount) returns (bool)',
      ];

      const usdtContract = new ethers.Contract(usdtAddress, erc20ABI, wallet);
      const contract = new ethers.Contract(
        contractAddress,
        stargateRouterABI,
        wallet
      );

      const amountLD = ethers.utils.parseUnits(amount, 6);
      const minAmountLD = ethers.utils.parseUnits('1', 6);
      const fee = ethers.utils.parseEther('2');

      // // Перевіряємо дозволи
      // const allowance = await usdtContract.allowance(wallet.address, contractAddress);
      // console.log("amountLD as plain number:", ethers.utils.formatUnits(allowance, 6));
      // if (allowance.lt(amountLD)) {
      //     console.log("Немає достатнього дозволу. Встановлюємо максимально можливий дозвол...");
      //     const maxApproval = ethers.constants.MaxUint256;
      //     const approveTx = await usdtContract.approve(contractAddress, maxApproval);
      //     await approveTx.wait();
      //     console.log("Дозвіл надано:", approveTx.hash);
      // } else {
      //     console.log("Достатній дозвіл уже надано.");
      // }

      const txParams = {
        dstGasForCall: 0,
        dstNativeAmount: 0,
        dstNativeAddr: ethers.constants.AddressZero,
      };

      const gasLimit = ethers.BigNumber.from('300000'); // Наприклад, 300,000 газу
      const gasPrice = ethers.utils.parseUnits('30', 'gwei'); // Наприклад, 30 Gwei

      const tx = await contract.swap(
        dstChainId,
        2, // Pool ID на вихідному ланцюзі
        2, // Pool ID на цільовому ланцюзі
        wallet.address,
        amountLD,
        minAmountLD,
        txParams,
        wallet.address,
        '0x',
        {
          value: fee,
          gasLimit: gasLimit, // Встановлюємо ліміт газу
          gasPrice: gasPrice, // Встановлюємо ціну газу
        }
      );

      return tx.hash;
    } catch (error) {
      console.log(error);

      toast.error('Insufficient balance');

      return rejectWithValue(error);
    }
  }
);

const swapBscUsdt = createAsyncThunk(
  'swap/swapBscUsdt',
  async (data, { rejectWithValue }) => {
    const { dstChainId, amount } = data;
    // await switchNetwork(networks.bsc);
    const contractAddress = '0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8';

    try {
      const usdtAddress = '0x55d398326f99059fF775485246999027B3197955';
      const usdtContract = new ethers.Contract(usdtAddress, usdtABI, signer);
      const allowance = await usdtContract.allowance(
        walletAddress,
        contractAddress
      );
      const amountLD = ethers.utils.parseUnits(amount, 6);

      if (allowance.lt(amountLD)) {
        const maxUint = ethers.constants.MaxUint256;
        const approveTx = await usdtContract.approve(contractAddress, maxUint);
        await approveTx.wait();
      }

      const stargateRouterABI = [
        {
          inputs: [
            { internalType: 'uint16', name: '_dstChainId', type: 'uint16' },
            {
              internalType: 'uint256',
              name: '_srcPoolId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: '_dstPoolId',
              type: 'uint256',
            },
            {
              internalType: 'address payable',
              name: '_refundAddress',
              type: 'address',
            },
            { internalType: 'uint256', name: '_amountLD', type: 'uint256' },
            {
              internalType: 'uint256',
              name: '_minAmountLD',
              type: 'uint256',
            },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'dstGasForCall',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'dstNativeAmount',
                  type: 'uint256',
                },
                {
                  internalType: 'bytes',
                  name: 'dstNativeAddr',
                  type: 'bytes',
                },
              ],
              internalType: 'struct IStargateRouter.lzTxObj',
              name: '_lzTxParams',
              type: 'tuple',
            },
            { internalType: 'bytes', name: '_to', type: 'bytes' },
            { internalType: 'bytes', name: '_payload', type: 'bytes' },
          ],
          name: 'swap',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ];

      const contract = new ethers.Contract(
        contractAddress,
        stargateRouterABI,
        signer
      );
      const minAmountLD = ethers.utils.parseUnits('1', 6);
      const fee = ethers.utils.parseEther('0.004');

      // Правильне налаштування txParams
      const txParams = {
        dstGasForCall: 0,
        dstNativeAmount: 0,
        dstNativeAddr: ethers.constants.AddressZero, // Використовуємо правильну нульову адресу
      };

      const currentChainId = await signer.getChainId();
      const bepChainId = 56;

      if (currentChainId !== bepChainId) {
        throw new Error('Signer is not connected to the Optimism network');
      }

      const tx = await contract.swap(
        dstChainId,
        2,
        2, // Змінюйте в залежності від токена
        walletAddress,
        amountLD,
        minAmountLD,
        txParams,
        walletAddress, // Використовуйте закодовану адресу у форматі bytes
        '0x',
        { value: fee }
      );

      return tx.hash;
    } catch (error) {
      console.log(error);

      toast.error('Insufficient balance');

      return rejectWithValue(error);
    }
  }
);

const ACTIONS = {
  onConnectMetaMask,
  getEthereumMainnetBalance,
  getEthereumUsdtBalance,
  getPolygonMainnetBalance,
  getOptimismMainnetBalance,
  getOptimismUsdtBalance,
  getArbitrumMainnetBalance,
  getArbitrumUsdtBalance,
  getAvalancheMainnetBalance,
  getBscMainnetBalance,
  getBscUsdtBalance,
  getMantleMainnetBalance,
  swapOptimismEth,
  swapEthereumEth,
  swapArbitrumEth,
  swapArbitrumUsdt,
  swapErcUsdt,
  swapAvaxUsdt,
  swapPolygonUsdt,
  swapBscUsdt,
};

export default ACTIONS;
