import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const StyledWrp = styled.div`
  display: flex;
  align-items: center;
  padding: 11px 25px 10px 15px;

  background: var(--background-secondary-color);
  border-radius: 99px;
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
`;

export const StyledIconWrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  margin-right: 11px;

  background: var(--button-primary-text-color);
  border-radius: 50%;
`;

export const StyledText = styled.p`
  margin-right: 5px;

  font-weight: 600;

  color: var(--text-primary-color);

  @media screen and (min-width: 1440px) {
    display: ${({ t }) => {
      if (t === 'hidden') {
        return 'none';
      }
    }};
  }
`;

export const StyledBtn = styled.button`
  width: 19.5px;
  height: 19.5px;

  background: transparent;
  border: none;
`;
