import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Modal } from 'components/common';
import AdminTimeRangeSelector from 'components/merchant/AdminTimeRangeSelector';
import AdminStatisticCardModal from 'components/merchant/AdminStatisticCardModal';
import {
  StyledText,
  StyledSpan,
  StyledWrp,
  StyledTypeListWrp,
  StyledTypeList,
  StyledTypeItem,
  StyledAdminStatisticList,
  StyledAdminStatisticItem,
  StyledAdminStatisticBtn,
} from './AdminStatisticCard.styled';

const TIME_RANGE = [
  {
    id: 1,
    name: '1 день',
    value: '1d',
  },
  {
    id: 2,
    name: '7 дней',
    value: '1w',
  },
  {
    id: 3,
    name: '30 дней',
    value: '30d',
  },
  {
    id: 4,
    name: 'Все время',
    value: '',
  },
];

const AdminStatisticCard = ({ statistic = {} }) => {
  const {
    user_count = 1,
    transaction_count,
    transaction_turnover,
    transaction_currency_count,
  } = statistic;

  const [isTimeRangeModalOpen, setIsTimeRangeModalOpen] = useState(false);
  const [isTransactionTurnoverModalOpen, setIsTransactionTurnoverModalOpen] =
    useState(false);
  const [isTransactionCurrencyModalOpen, setIsTransactionCurrencyModalOpen] =
    useState(false);

  const [adminTypeTimeRangeModal, setAdminTypeTimeRangeModal] = useState(null);
  const [selectedUserCountTimeRange, setSelectedUserCountTimeRange] = useState(
    TIME_RANGE[2]
  );
  const [
    selectedTransactionCountTimeRange,
    setSelectedTransactionCountTimeRange,
  ] = useState(TIME_RANGE[2]);
  const [
    selectedTransactionTurnoverTimeRange,
    setSelectedTransactionTurnoverTimeRange,
  ] = useState(TIME_RANGE[2]);
  const [
    selectedTransactionCurrencyCountTimeRange,
    setSelectedTransactionCurrencyCountTimeRange,
  ] = useState(TIME_RANGE[2]);

  const handleTransactionTurnoverModalToggle = () => {
    setIsTransactionTurnoverModalOpen(currentState => !currentState);
  };

  const handleTransactionCurrencyModalToggle = () => {
    setIsTransactionCurrencyModalOpen(currentState => !currentState);
  };

  const handleTimeRangeModalToggle = type => {
    setIsTimeRangeModalOpen(currentStatre => !currentStatre);
    setAdminTypeTimeRangeModal(type);

    if (isTimeRangeModalOpen) {
      setAdminTypeTimeRangeModal(null);
    }
  };

  return (
    <>
      <StyledWrp>
        <StyledTypeListWrp>
          <StyledTypeList>
            <StyledTypeItem>
              <StyledText fz="14" fw="400" c="secondary">
                NeutronX
              </StyledText>
            </StyledTypeItem>

            <StyledTypeItem type="selected">
              <StyledText fz="14" fw="700">
                Admin
              </StyledText>
            </StyledTypeItem>
          </StyledTypeList>
        </StyledTypeListWrp>

        <StyledAdminStatisticList>
          <StyledAdminStatisticItem>
            <StyledText>
              Количество пользователей <br /> за{' '}
              <StyledAdminStatisticBtn
                type="button"
                onClick={() => handleTimeRangeModalToggle('user_count')}
              >
                {selectedUserCountTimeRange.name}
              </StyledAdminStatisticBtn>
            </StyledText>

            <StyledText fz="20" fw="400">
              <StyledSpan>{user_count}</StyledSpan>
            </StyledText>
          </StyledAdminStatisticItem>

          <StyledAdminStatisticItem>
            <StyledText>
              Количество транзакций <br /> за{' '}
              <StyledAdminStatisticBtn
                type="button"
                onClick={() => handleTimeRangeModalToggle('transaction_count')}
              >
                {selectedTransactionCountTimeRange.name}
              </StyledAdminStatisticBtn>
            </StyledText>

            <StyledText fz="20" fw="400">
              <StyledSpan>{transaction_count}</StyledSpan>
            </StyledText>
          </StyledAdminStatisticItem>

          <StyledAdminStatisticItem>
            <StyledText>
              Оборот по транзакциям <br /> за{' '}
              <StyledAdminStatisticBtn
                type="button"
                onClick={() =>
                  handleTimeRangeModalToggle('transaction_turnover')
                }
              >
                {selectedTransactionTurnoverTimeRange.name}
              </StyledAdminStatisticBtn>
            </StyledText>

            <StyledAdminStatisticBtn
              type="button"
              onClick={handleTransactionTurnoverModalToggle}
              fz="20"
              c="primary"
            >
              посмотреть
            </StyledAdminStatisticBtn>
          </StyledAdminStatisticItem>

          <StyledAdminStatisticItem>
            <StyledText>
              Количество транзакций в определенной валюте <br /> за{' '}
              <StyledAdminStatisticBtn
                type="button"
                onClick={() =>
                  handleTimeRangeModalToggle('transaction_currency_count')
                }
              >
                {selectedTransactionCurrencyCountTimeRange.name}
              </StyledAdminStatisticBtn>
            </StyledText>

            <StyledAdminStatisticBtn
              type="button"
              onClick={handleTransactionCurrencyModalToggle}
              fz="20"
              c="primary"
            >
              посмотреть
            </StyledAdminStatisticBtn>
          </StyledAdminStatisticItem>
        </StyledAdminStatisticList>
      </StyledWrp>

      <AnimatePresence initial={false} onExitComplete={() => null}>
        {isTimeRangeModalOpen && (
          <Modal toggle={handleTimeRangeModalToggle}>
            <AdminTimeRangeSelector
              toggle={handleTimeRangeModalToggle}
              type={adminTypeTimeRangeModal}
              timeRange={TIME_RANGE}
              setSelectedUserCountTimeRange={setSelectedUserCountTimeRange}
              setSelectedTransactionCountTimeRange={
                setSelectedTransactionCountTimeRange
              }
              setSelectedTransactionTurnoverTimeRange={
                setSelectedTransactionTurnoverTimeRange
              }
              setSelectedTransactionCurrencyCountTimeRange={
                setSelectedTransactionCurrencyCountTimeRange
              }
            />
          </Modal>
        )}

        {isTransactionTurnoverModalOpen && (
          <Modal toggle={handleTransactionTurnoverModalToggle}>
            <AdminStatisticCardModal
              toggle={handleTransactionTurnoverModalToggle}
              data={transaction_turnover}
            />
          </Modal>
        )}

        {isTransactionCurrencyModalOpen && (
          <Modal toggle={handleTransactionCurrencyModalToggle}>
            <AdminStatisticCardModal
              type=""
              toggle={handleTransactionCurrencyModalToggle}
              data={transaction_currency_count}
            />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default AdminStatisticCard;
