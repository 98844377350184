import { createPortal } from 'react-dom';
import { ToastContainer, Slide } from 'react-toastify';

const notificationRoot = document.querySelector('#notification-root');

const ToastNotification = () => {
  return createPortal(
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={true}
      draggable={false}
      rtl={false}
      closeOnClick
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      transition={Slide}
      theme="light"
    />,
    notificationRoot
  );
};

export default ToastNotification;
