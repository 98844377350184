import { useSelector } from 'react-redux';
import select from '../../redux/app/selectors';

const useApp = () => {
  const product = useSelector(select.product);
  const theme = useSelector(select.theme);
  const screenWidth = useSelector(select.screenWidth);

  return { product, theme, screenWidth };
};

export default useApp;
