import styled from '@emotion/styled';

export const StyledWrp = styled.div`
  width: 300px;
  padding: 20px;

  cursor: pointer;

  background: var(--background-secondary-color);
  border-radius: 18px;
`;

export const StyledSelectField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  padding: 8px 10px;

  border-radius: 10px;
  border: 1px solid var(--border-marketplace-admin-time-range-color);
`;

export const StyledList = styled.ul`
  padding: 0px 10px;

  border-radius: 10px;
  border: 1px solid var(--border-marketplace-admin-time-range-color);
`;

export const StyledItem = styled.li`
  padding: 4px 0;

  &:hover {
    opacity: 0.5;
  }
`;

export const StyledText = styled.p`
  font-size: 16px;
  line-height: 120%;

  color: var(--text-primary-color);
`;
