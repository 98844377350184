import styled from '@emotion/styled';

export const StyledWrp = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 60px;

  background: var(--background-secondary-color);
  border-radius: 99px;

  cursor: pointer;
`;

export const StyledSpan = styled.span`
  position: absolute;

  width: 50px;
  height: 50px;

  background: #ffffff;
  border-radius: 50%;
`;
