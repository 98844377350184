import styled from '@emotion/styled';
import { mq } from 'utils';

export const StyledBtn = styled.button`
  position: absolute;
  top: 112%;
  right: 4%;

  padding: 4px;

  color: var(--text-primary-color);
  background: var(--background-secondary-color);
  border: none;
  border-radius: 18px;

  ${mq.tablet} {
    top: 106%;
    right: 2%;

    padding: 8px;
  }

  ${mq.desktop} {
    top: 80%;
    right: 9%;

    padding: 12px;
  }
`;
